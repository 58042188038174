import React, { useState, useEffect, useRef, useReducer } from 'react'
import { makeStyles, Grid, Typography, Button, TextField, Divider, CircularProgress, Zoom} from '@material-ui/core';
import { rarityColors } from "../../helpers/GlobalStore"
import CurrencySymbolProvider from '../../components/CurrencySymbolLoader';
import StyledCardContainer from '../StyledCardContainer';
import {useStore} from '../../helpers/GlobalStore'
import { Demodal } from 'demodal'
import WhatsInsidePopover from './WhatsInsidePopover';
import SvgButton from '../SvgButton'
import { useContractReducer } from '../../helpers/ContractReducer';
import BuyAndMintPopover from './BuyAndMintPopover';
import { useHowApiClient } from '../../helpers/HowApiClient';
import { useAuthReducer } from '../../helpers/AuthReducer';
import styled, {keyframes, css} from 'styled-components';
import CardArtProvider from '../../assets/CardArtProvider';

const isNullUndefinedOrEmpty = (obj) => {
    return( obj === undefined || obj === null || obj === ''  )
} 

const radiate = keyframes`

    from {
        filter: brightness(1.3) drop-shadow(0px 0px 4px #30cfff);

    }
    to {
        filter: brightness(1)  drop-shadow(0px 0px 1px #008fbf);

    }
`

const radiateAnimation = props => 
css`
    ${radiate} infinite alternate-reverse 2000ms;

`

const StyledShopCard = styled(Grid)`
    height:300px;
    width:215px;
    background-image: ${props => `url(${props.theme.imgName})` || `none`};
    background-size: 100% 100%;
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(0px 5px 4px black);
    cursor: ${(props) => props.theme.hover? 'pointer': 'default'};
  
    &:hover{
        animation: ${(props) => props.theme.hover? radiateAnimation : 'none'};
        transform: ${(props) => props.theme.hover? 'scale(1.2)': 'none'};
    }
    &::after{
        
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        opacity: 0;
    }
    
`

const SaleCard = ({cardPack, chainId}) => {

    const { getCurrencySymbol } = CurrencySymbolProvider();
    const [cardPackState, dispatch] = useReducer(cardPackReducer, cardPack)
    const {paymentManagerState} = useContractReducer()
    const {getCardPackDetails} = useHowApiClient()
    const {authState} = useAuthReducer()
    const {getArt} = CardArtProvider()

    const {packId, raritySlots, containedRarities, displayName, packName, rarity, basePrice, salePrice, maxAllowedPurchases, userPurchaseCount, capacity, purchaseLimitReached, disabled} = cardPackState;


    function cardPackReducer(cardPackState, action) {
        switch (action.type) {
            case 'updatePurchaseCount': {
                return {
                    ...cardPackState,
                    userPurchaseCount: action.payload.purchaseCount,
                    purchaseLimitReached: cardPackState.maxAllowedPurchases !== 0 && action.payload.purchaseCount >= cardPackState.maxAllowedPurchases,
                    disabled: false
                };
            }
            case 'clearField': {
                return {
                    ...cardPackState,
                    userPurchaseCount: undefined,
                    disabled: true
                };
            }
            case 'disableInteraction': {
                return {
                    ...cardPackState,
                    disabled: true
                };
            }
            case 'openWhatsInsideModal': {
                
                return {
                    ...cardPackState,
                };
            }
            case 'updatePackState':
                return{
                    ...cardPackState,
                  //  ...action.payload.updatedPackDetails,
                    basePrice: action.payload.updatedPackDetails.basePrice,
                    salePrice: action.payload.updatedPackDetails.salePrice,
                    amtSold: action.payload.updatedPackDetails.amtSold,
                    displayName: action.payload.updatedPackDetails.displayName,
                    rarity: action.payload.updatedPackDetails.rarity,
                    season: action.payload.updatedPackDetails.season,
                    type: action.payload.updatedPackDetails.type,
                    containedRarities: action.payload.updatedPackDetails.containedRarities,
                    raritySlots: action.payload.updatedPackDetails.raritySlots,
                    disabled: false
                }
            default:
                return
        }
    }

    // const loadUserPackPurchaseCountsAsync = async () => {   

    //     if( (!isNullUndefinedOrEmpty(paymentManagerState) && 
    //          !isNullUndefinedOrEmpty(paymentManagerState.paymentManagerState)) &&  
    //         (authState && !isNullUndefinedOrEmpty(authState.userAddress)))
    //     {    
    //         const packPurchaseCount = await paymentManagerState.paymentManagerState.getUserPurchaseCount(authState.userAddress, packName);
    //         await dispatch({ 
    //             type: 'updatePurchaseCount', 
    //             payload: {
    //                 purchaseCount: packPurchaseCount.toNumber(),
    //             }
    //         });
    //     }
    //     else{
    //         dispatch({ type: 'clearField', payload: ''});
    //     }
    // }


    const loadUpdatedPackDetails = async () => {   

        const updatedPackDetails = await getCardPackDetails(packId)
        await dispatch({ 
            type: 'updatePackState', 
            payload: {
                updatedPackDetails: updatedPackDetails
            }
        });

    }

    const handlePackUpdate = async() => {
            await loadUpdatedPackDetails()
            // await loadUserPackPurchaseCountsAsync()

    }
  
    useEffect(()=>{
        if(
            !isNullUndefinedOrEmpty(paymentManagerState) && 
            !isNullUndefinedOrEmpty(paymentManagerState.paymentManagerState) &&
            !isNullUndefinedOrEmpty(authState)
        ){
            handlePackUpdate()
        }
        else{
            dispatch({type:'clearField'})
        }
    },[paymentManagerState])


    useEffect(()=>{
        if(isNullUndefinedOrEmpty(paymentManagerState) || isNullUndefinedOrEmpty(paymentManagerState)){
            loadUpdatedPackDetails()
        }
        
    },[])
    
    const handleWhatsInsideModalOpen = async () => {
        await Demodal.open(WhatsInsidePopover, {pack: !isNullUndefinedOrEmpty(cardPackState)? cardPackState: cardPack})
        
    }

    const handleCardClick = async  () => {
        let currencySymbol = getCurrencySymbol(chainId)
        let chowSymbol = <img  style={{height: '50px', width:'50px'}} src={getArt('ChowSymbol')}/> 
        await Demodal.open(
            BuyAndMintPopover, 
            {
                pack: cardPackState, 
                currencySymbol: currencySymbol, 
                chowSymbol: chowSymbol, 
                cardUpdateDispatch: dispatch
            }
        )
    }


    return(
        <Grid container style={{display:'flex', columnGap:'100%', flexShrink:10, width: 215}} >
            <Grid 
                item 
                style={{
                    cursor: "pointer",
                    width: 215,
                    height: 300,
                    marginBottom:15
                }}
            >
                <StyledShopCard
                    theme={{
                        hover: (authState && authState.userAuthorized && !disabled),
                        imgName: getArt(packName)
                    }}
                    onClick = {() => authState && authState.userAuthorized && !disabled ? handleCardClick(cardPackState):{}}
                />
            </Grid>



{/*

            <Grid item style={{width: 215}}>
                <Typography variant = "h5" style = {{color: `rgb(${rarityColors[rarity]})`, textAlign: "center", fontWeight: "bold"}}>
                    {displayName}
                </Typography>
            </Grid>
             <Grid item style={{display: 'flex', width:230, height:60, justifyContent:'flex-end', alignItems:'flex-end'}} key = {`lootButton-cardPack-${cardPack.packId}-container`}>
                
                <SvgButton 
                    displayText='Whats Inside?'
                    onClick = { () => handleWhatsInsideModalOpen()}
                    disabled={false}
                    fontSize='12pt'
                    buttonWidth={230} 
                    buttonHeight={60}/>
            </Grid>  
*/}       
            <Grid item >

                <Grid container style={{justifyContent:'center', alignItems:'center', marginBottom:'20px', width:215}}>
                    <Grid item >
                        { getCurrencySymbol(chainId) }
                    </Grid>
                    {salePrice > 0 &&
                    <Grid item style = {{marginLeft: 5}}>
                        <Typography 
                            variant = "h6" 
                            style = {{
                                color: "white",
                                fontSize:'20pt',
                                fontWeight:'bold',
                                fontStyle:'line-through'
                            }}
                        >
                            { basePrice }
                        </Typography>
                     </Grid>
                    }

                    <Grid item style = {{marginLeft: 5}}>
                        <Typography 
                            variant = "h6" 
                            style = {{
                                color: "white",
                                fontSize:'20pt',
                                fontWeight:'bold'
                            }}
                        >
                            {salePrice > 0? 
                                salePrice:
                                basePrice
                            }
                        </Typography>
                    </Grid>
            {/* 
                    {!isNullUndefinedOrEmpty(maxAllowedPurchases) && maxAllowedPurchases > 0 &&
                        <Grid item xs = {12}>
                            <Typography variant = "body2" style = {{
                                textAlign: "center", 
                                color: "white",
                                fontSize:'12pt'}}
                            >
                            {(!isNullUndefinedOrEmpty(userPurchaseCount)) &&
                            ` User Purchase Limit: ` +
                            
                                    userPurchaseCount + ' / ' + maxAllowedPurchases
                                }
                            </Typography>
                        </Grid>
                    
                    } 
           
                    {!isNullUndefinedOrEmpty(capacity) && capacity > 0 &&
                    <Grid item xs = {12} style = {{margin: 5}}>
                        <Typography 
                        variant = "body2" 
                        style = {{
                            textAlign: "center", 
                            color: "white",
                            fontSize:'12pt' 
                        }}
                        >
                            { `${capacity} Sale Limit`}
                        </Typography>
                    </Grid>
                    }
        */}
                </Grid>
            </Grid>       
           
        </Grid>
    )
}

export default SaleCard;



