import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import { useHowApiClient } from '../helpers/HowApiClient';
import { useStore } from '../helpers/GlobalStore'
import CreatureToolbar from '../components/CreatureToolbar';
import nextId from "react-id-generator";
import CompendiumUtil from "../pages/compendium/CompendiumUtil"
import StyledCardContainer from "./StyledCardContainer"; 
import CardPopover from "./CardPopover";
import { rarityColors } from "../helpers/GlobalStore"
import { useWindowDimensions } from "../helpers/windowDimentionProvider";

const InventorySpecialComponent = () => {

    const styles = useStyles();
    var compendiumUtil = CompendiumUtil();

    const { getCardCounts } = useHowApiClient();
    const { width, height } = useWindowDimensions();
    const { userRoles, compendium, setCompendium, setChildUpdateNeeded } = useStore();

    const [nameSearch, setNameSearch] = useState("")
    const [seasonSearch, setSeasonSearch] = useState(0)
    const [editionSearch, setEditionSearch] = useState(0)
    const [filter, setFilter] = useState("all")
    const [sortBy, setSortBy] = useState("name ascending")

    const [popover, setPopover] = useState(false)
    const [selectedCard, setSelectedCard] = useState()
    const [cardOverlayVisible, setCardOverlayVisible] = useState(false)
    const [descriptionVisible, setDescriptionVisible] = useState(false)

    const loadCounts = async () => {
         await getCardCounts()
    }

    useEffect(() => {
        if (compendium.size === 0){         
            setCompendium(compendiumUtil.getCompendiumDictionary())
        }
    }, [compendium, setCompendium, compendiumUtil])

    useEffect(() => {
        if(userRoles === '') {
        }
        else{
            loadCounts() 
            setChildUpdateNeeded(false);
        }
    }, [userRoles])


    const ordinal_suffix_of = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    const handleCardClick = (card) => {
        setSelectedCard(card)
        setPopover(true)
    }

    const handleCardClose = () => {
        setSelectedCard(undefined)
        setPopover(false)
        setDescriptionVisible(false)
    }

    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter);
    };

    const toggleCardSide = () => {
        setDescriptionVisible(~descriptionVisible)
    }

    const popoverContents = () => {

        return (

            <Grid 
            container
            id="ov-data-grid"
            style={{
                justifyContent:'center', 
                alignItems:"center", 
                maxWidth:width>height?'75vw':'75vw', 
                height:width>height?'75vh':'50vh', 
                aspectRatio:width>=height?'1/1':'2.5/3.5'}}>

                <div
                id="og-card-div"  
                style={{
                    display:descriptionVisible?'none':'flex',
                    flexDirection:'column', 
                    height:width>=height?'85%':'auto', 
                    width: width>=height?"auto":'85%'}} 
                className = {styles.imageOriginal}>

                    <StyledCardContainer
                    imgName = {selectedCard.cardId}
                    rarity = {selectedCard.rarityId}
                    count = {1}
                    zIndex={800}
                    />
                </div>

                <div
                id="ov-backdrop-img"
                style={{
                    position:'absolute',
                    backgroundImage: width >= height?`url(card-description-backdrop-widescreen.png)`:`url(card-description-backdrop-mobile.png)`,
                    backgroundSize:'100% 100%',
                    height:width>=height?'100%':'auto',
                    width:width>=height?'inherit':'100%',
                    aspectRatio: "2.5/3.5",                    
                    }}
                />

            </Grid>

        )
    }



    const getCardBackByRarityId = (rarityId) => {
        switch(rarityId) {
            case 1: // LEGENDARY
                return "legendary-card-back";
            case 2: // EPIC
                return "epic-card-back";
            case 4: // RARE
                return "rare-card-back";
            case 8: // UNCOMMON
                return "uncommon-card-back";
            case 16: // COMMON
                return "common-card-back";
            default: 
                return "common-card-back";
        }
    }

    const renderCardItems = () => {

        let elements = []
        let data = []



        compendium.values().forEach(card => {
            if ( card.rarityId === 64 )
            {
                // Show the actual card
                if (card.count > 0) {
                    elements.push(
                        <Grid item  key={nextId('CardContainer-')} className = {styles.cardContainer}>
                            <StyledCardContainer
                            imgName = {card.cardId}
                            rarity = {card.rarityId}
                            count = {card.count}
                            onClick = {() => handleCardClick(card)}
                            hover
                            zIndex={800}
                            />
    
    
                            {userRoles !== "" && userRoles !== undefined &&
                            <Grid item>
                                <Typography variant = "h6" style = {{textAlign: "center", color:'white'}}>
                                    {`Owned:  x${card.count}`}
                                </Typography>
                            </Grid>
                            }
                        </Grid>
                    )
                }
                // Show the card back until we want to reveal the actual card
                else {
                    elements.push(
                        <Grid item  key={nextId('CardContainer-')} className = {styles.cardContainer}>
                            <StyledCardContainer
                            imgName = {getCardBackByRarityId(card.rarityId)}
                            rarity = {card.rarityId}
                            count = {0}
                            hover
                            zIndex={800}
                            />
                        </Grid>
                    )
                }
            }
        })

        return elements
    }

    return (
        <Grid container spacing={2} style={{justifyContent:'center', alignItems:'flex-end'}} >

            {compendium && compendium.length !== 0 &&
            <Grid item xs={12} >
                <Grid container style={{justifyContent:'center', alignItems:'center'}}>
                    {renderCardItems()}
                </Grid>
            </Grid>
            }

            {selectedCard !== undefined && selectedCard !== null &&
            <CardPopover
            contents = {popoverContents()}
            rarity = {selectedCard.rarityId}
            popover = {popover}
            closePopover = {handleCardClose}
            dropShadow = {(popover)}
            hideBackground
            />
            }

        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    imageOriginal: {
        aspectRatio: "2.5/3.5",
        margin: 20
    },

    cardCounterIcon: {
        width: 20,
        height: 30
    },

    cardContainer: {
        width: 200,
        height: 280,
        margin: 25
    },

    popContainer: {
        backgroundColor:'rgba(0,0,0,0)',
        color:'white',
    },

    textCentered: {
        textAlign: "center"
    },

    textLeft: {
        textAlign: "left"
    },

    textWhite: {
        color: "white"
    },

    popDescription: {
        marginBottom: 10,
    },

    checked: {
    },

    filterFieldProp: {
        height: '42px',
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    textFieldLabel: {
        fill: "white",
        color: "white"
    },
    typographyText:{
        fontSize:'14pt'
    }

}))

export default InventorySpecialComponent;