import { useEffect, useState } from "react";
import styled, {keyframes} from "styled-components";
import { Star } from "../../assets/images/star"

const MAX_NUM_STARS = 200;
const minSize = 1;
const maxSize = 30;
const colorValueMax = 255;
const colorValueMin = 50;
const minAnimationDelay = 50;
const maxAnimationDelay = 1000;
const minAnimationDuration = 1500;
const maxAnimationDuration = 4000;
const minRotation = 0;
const maxRotation = 355;
const alphaMin = .3;
const alphaMax = 1;

const glitter = keyframes`

    from { 
        opacity: 0;
    }
    to { 
        opacity: 1;
    }
`


const StyledStar = styled.svg`
        animation-name: ${glitter};

        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        will-change: auto;
`


function clamp(num, min, max) {
    return num <= min 
      ? min 
      : num >= max 
        ? max 
        : num
  }


function debounce(fn, ms) {

  let timer

  return _ => {
    clearTimeout(timer)

    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

var generatedStars = []

const StarField = () => {

    const [windowDims, setWindowDims] = useState({ width: window.innerWidth, height: window.innerHeight})

    if(generatedStars.length === 0){
        generatedStars = Array(MAX_NUM_STARS).fill().map((star, index)=>
            <StyledStar 
                key={`star-${index}`} 

                style={{
                    transform: `scale(${Math.random()})`,
                    height:'30px',
                    width:'30px',
                    position: 'absolute', 
                    top: Math.round( clamp(window.innerHeight *  Math.random(), 0, window.innerHeight)),
                    left: Math.round( clamp(window.innerWidth *  Math.random(), 0, window.innerWidth)),
                    animationDuration:  `${ clamp( maxAnimationDuration * (Math.random()), minAnimationDuration, maxAnimationDuration )}ms`,
                    animationDelay:   `${ clamp(maxAnimationDelay * Math.random(), minAnimationDelay, maxAnimationDelay )}ms`
                }}
            >
                <Star 
                    key={`star-key-${index}`} 
                    starColor={
                        'rgba(' + (Math.floor(Math.random() * 256)) + ',' + 
                        (Math.floor(Math.random() * 256)) + ',' + 
                        (Math.floor(Math.random() * 256)) + ',' + Math.random()+')'
                    }
                    id={index}
                />
            </StyledStar>
        )
    }


    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
    
            
            generatedStars = generatedStars && generatedStars.map((star, index)=>
                <StyledStar 
                    key={`star-${index}`} 
                    duration={`${ star.props.duration }`}
                    delay={`${ star.props.delay }`}
                    style={{
                        transform: `scale(${Math.random()})`,
                        height:'30px',
                        width:'30px',
                        position: 'absolute', 
                        top: Math.round( clamp(window.innerHeight *  Math.random(), 0, window.innerHeight)),
                        left: Math.round( clamp(window.innerWidth *  Math.random(), 0, window.innerWidth))
                    }}
                >
                    <Star 
                        key={`star-key-${index}`}
                        starColor={
                            'rgba(' + (Math.floor(Math.random() * 256)) + ',' + 
                            (Math.floor(Math.random() * 256)) + ',' + 
                            (Math.floor(Math.random() * 256)) + ',' + Math.random()+')'
                        }
                        id={index}
                    />
                </StyledStar>
            )
            
            setWindowDims({width: window.innerWidth, height: window.innerHeight})
        }, 150)
    
    
        window.addEventListener('resize', debouncedHandleResize)
        return _ => {
          window.removeEventListener('resize', debouncedHandleResize)
        }
    },[]);

    

    

    return(
        <div style={{width:'100%', height: '100%', position: 'fixed', justifyContent: 'center', alignItems: 'center'}}>
            {generatedStars.map((star, index)=>

                        star
        
                )
            }
        </div>
    )
}

export default StarField;