import HashMap from "hashmap";

const CONFIG = {
    //The endpoint used for the calls to the asp net api.
    API_ENDPOINT : "https://api.houndsofwar.app/api",

    //The base URL for our frontend.
    BASE_URL : "https://houndsofwar.app/",

    //The ethereum node used for web3.
    ETH_NODE : "https://mainnet.infura.io/v3/1870be68d90148fdb919ea7a1a115973",

    //ETH MAINNET chain id
    CHAIN_ID : "1",

    //Chain Name
    CHAIN_NAME: "Ethereum",

    SUPPORTED_CHAINS: 
        new HashMap(
            [
                '1', {
                    name: 'Ethereum Mainnet', 
                    chainId: '1',
                    hexChainId: '1', 
                    node: ["https://mainnet.infura.io/v3/1870be68d90148fdb919ea7a1a115973"], 
                    browser: 'https://etherscan.io/',
                    nativeCurrency: {
                        name: 'Ethereum',
                        symbol: 'ETH',
                        decimals: 18
                    }
                },
            ]
        ),

    //Game Server URL
    GAME_SERVER_ENDPOINT : 'wss://gameserver.houndsofwar.app/',

    CORS_ORIGIN : 'https://houndsofwar.app',
    
    //Enables client-side features only meant for debugging and development
    DEBUG_MODE : false,

    //Switch Price between Actual and TBD
    SHOW_PRICE: true
}

export default CONFIG;