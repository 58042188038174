import HashMap from 'hashmap';

//Cards
import hound_3 from './images/BaseCards/Keep/KWEJ30AU.png';
import hound_5 from './images/BaseCards/Keep/1KUYULMS.png';
import hound_6 from './images/BaseCards/Keep/N812R2J3.png';
import hound_7 from './images/BaseCards/Keep/9VKOAC1B.png';
import hound_8 from './images/BaseCards/Keep/6SB9HFRM.png';
import hound_10 from './images/BaseCards/Keep/RW5SAA2F.png';
import hound_12 from './images/BaseCards/Keep/86FF41XP.png';
import hound_13 from './images/BaseCards/Keep/3ZE7VFVC.png';
import hound_14 from './images/BaseCards/Keep/NZTOZC3I.png';
import hound_20 from './images/BaseCards/Keep/4RTAFYAY.png';
import hound_21 from './images/BaseCards/Keep/G1CMFM3G.png';
import hound_22 from './images/BaseCards/Keep/V5LTYW81.png';
import hound_23 from './images/BaseCards/Keep/L7I7FSTZ.png';
import hound_26 from './images/BaseCards/Keep/042BLLN2.png';
import hound_29 from './images/BaseCards/Keep/BTS3CWW7.png';
import hound_36 from './images/BaseCards/Keep/5D630E4V.png';
import hound_37 from './images/BaseCards/Keep/VKZHVXQ9.png';
import hound_42 from './images/BaseCards/Keep/Y496ZDSX.png';
import hound_44 from './images/BaseCards/Keep/NCPW96W2.png';
import hound_45 from './images/BaseCards/Keep/7MX9ZCYN.png';
import hound_51 from './images/BaseCards/Keep/NIDDE81K.png';
import hound_52 from './images/BaseCards/Keep/3J05WNR2.png';
import hound_56 from './images/BaseCards/Keep/J0JJPZWP.png';
import hound_77 from './images/BaseCards/Keep/PY6AEXJ3.png';
import hound_78 from './images/BaseCards/Keep/K2RGW909.png';
import hound_82 from './images/BaseCards/Keep/L8BQT0T4.png';
import hound_86 from './images/BaseCards/Keep/1NGYW91Q.png';
import hound_88 from './images/BaseCards/Keep/E7MEPSOG.png';
import hound_90 from './images/BaseCards/Keep/1AN2JDGO.png';
import hound_98 from './images/BaseCards/Keep/J7WWVNXJ.png';
import hound_104 from './images/BaseCards/Keep/G1X1LNXH.png';
import hound_105 from './images/BaseCards/Keep/V9NA5ODI.png';
import hound_109 from './images/BaseCards/Keep/OD6GJTHK.png';
import hound_111 from './images/BaseCards/Keep/KUD3MLSJ.png';
import hound_112 from './images/BaseCards/Keep/W43KUVTL.png';
import hound_118 from './images/BaseCards/Keep/FODM28YE.png';
import hound_124 from './images/BaseCards/Keep/O8VGO4D6.png';
import hound_125 from './images/BaseCards/Keep/EVSW7CK2.png';
import hound_135 from './images/BaseCards/Keep/FHXW5PF4.png';
import hound_137 from './images/BaseCards/Keep/R1ZIM0J7.png';
import hound_141 from './images/BaseCards/Keep/UWRM9WN2.png';
import hound_147 from './images/BaseCards/Keep/669G6VOP.png';
import hound_150 from './images/BaseCards/Keep/0Z9CW3HR.png';
import hound_151 from './images/BaseCards/Keep/Z0O1YN1D.png';
import hound_154 from './images/BaseCards/Keep/DLPISFJ7.png';
import hound_157 from './images/BaseCards/Keep/P5IPWKW2.png';
import hound_159 from './images/BaseCards/Keep/N5NTDZVY.png';
import hound_162 from './images/BaseCards/Keep/82W6PTLF.png';
import hound_171 from './images/BaseCards/Keep/UXI1ML5F.png';
import hound_178 from './images/BaseCards/Keep/HCT47UYC.png';
import hound_188 from './images/BaseCards/Keep/BCGDO3ZR.png';
import hound_195 from './images/BaseCards/Keep/J14HLCDC.png';
import hound_200 from './images/BaseCards/Keep/ANC8F4HI.png';
import hound_201 from './images/BaseCards/Keep/WFL11OY5.png';
import hound_202 from './images/BaseCards/Keep/RN7OWZSC.png';


//Chow Bundles
import chowBundle10 from "./images/CardPackImages/Chow-Bundle-10.webp"
import chowBundle50 from "./images/CardPackImages/Chow-Bundle-50.webp"
import chowBundle100 from "./images/CardPackImages/Chow-Bundle-100.webp"
import chowBundle200 from "./images/CardPackImages/Chow-Bundle-200.webp"

//Backgrounds
import GamePageBackgroundImg from "../assets/images/Backgrounds/game-page-bg.png";
import LorePageBackgroundImg from "../assets/images/Backgrounds/lore-page-bg.png";
import StorePageBackgroundImg from "../assets/images/Backgrounds/store-page-bg.webp";
import InventoryBG from "../assets/images/Backgrounds/inventory-page-bg.webp";
import ProfileBG from "../assets/images/Backgrounds/profile-page-bg.webp";
import DeckPageBG from "../assets/images/Backgrounds/deck-page-bg.webp";
import HowHomeBg from "../assets/images/Backgrounds/how-bg-webp.webp"
import HowHomeBgMobile from "../assets/images/Backgrounds/how-bg-mobile-webp.webp"
import GuidePageBG from "../assets/images/Backgrounds/guide-page-bg.webp"
import Bg1 from '../assets/images/SectionBgs/section-1-bg.png'
import Bg2 from '../assets/images/SectionBgs/section-2-bg.webp'

//Website Art Assets
import SlotBorder from "../assets/images/SlotBorder.png";
import AppbarLogoSmall from "../assets/images/appbar-logo-small.png"

//Homepage art assets
import DeckBack from "../assets/images/deck-back.png"


import BattleToWin from "../assets/images/SectionBgs/win-matches-to-earn-bg.png"

import ChowSymbol from "../assets/images/chow-symbol.png"

const CardArtProvider = () => {
    const artMap = new HashMap()

    //Base cards
    artMap.set("KWEJ30AU", hound_3)
    artMap.set("1KUYULMS", hound_5)
    artMap.set("N812R2J3", hound_6)
    artMap.set("9VKOAC1B", hound_7)
    artMap.set("6SB9HFRM", hound_8)
    artMap.set("RW5SAA2F", hound_10)
    artMap.set("86FF41XP", hound_12)
    artMap.set("3ZE7VFVC", hound_13)
    artMap.set("NZTOZC3I", hound_14)
    artMap.set("4RTAFYAY", hound_20)
    artMap.set("G1CMFM3G", hound_21)
    artMap.set("V5LTYW81", hound_22)
    artMap.set("L7I7FSTZ", hound_23)
    artMap.set("042BLLN2", hound_26)
    artMap.set("BTS3CWW7", hound_29)
    artMap.set("5D630E4V", hound_36)
    artMap.set("VKZHVXQ9", hound_37)
    artMap.set("Y496ZDSX", hound_42)
    artMap.set("NCPW96W2", hound_44)
    artMap.set("7MX9ZCYN", hound_45)
    artMap.set("NIDDE81K", hound_51)
    artMap.set("3J05WNR2", hound_52)
    artMap.set("J0JJPZWP", hound_56)
    artMap.set("PY6AEXJ3", hound_77)
    artMap.set("K2RGW909", hound_78)
    artMap.set("L8BQT0T4", hound_82)
    artMap.set("1NGYW91Q", hound_86)
    artMap.set("E7MEPSOG", hound_88)
    artMap.set("1AN2JDGO", hound_90)
    artMap.set("J7WWVNXJ", hound_98)
    artMap.set("G1X1LNXH", hound_104)
    artMap.set("V9NA5ODI", hound_105)
    artMap.set("OD6GJTHK", hound_109)
    artMap.set("KUD3MLSJ", hound_111)
    artMap.set("W43KUVTL", hound_112)
    artMap.set("FODM28YE", hound_118)
    artMap.set("O8VGO4D6", hound_124)
    artMap.set("EVSW7CK2", hound_125)
    artMap.set("FHXW5PF4", hound_135)
    artMap.set("R1ZIM0J7", hound_137)
    artMap.set("UWRM9WN2", hound_141)
    artMap.set("669G6VOP", hound_147)
    artMap.set("0Z9CW3HR", hound_150)
    artMap.set("Z0O1YN1D", hound_151)
    artMap.set("DLPISFJ7", hound_154)
    artMap.set("P5IPWKW2", hound_157)
    artMap.set("N5NTDZVY", hound_159)
    artMap.set("82W6PTLF", hound_162)
    artMap.set("UXI1ML5F", hound_171)
    artMap.set("HCT47UYC", hound_178)
    artMap.set("BCGDO3ZR", hound_188)
    artMap.set("J14HLCDC", hound_195)
    artMap.set("ANC8F4HI", hound_200)
    artMap.set("WFL11OY5", hound_201)
    artMap.set("RN7OWZSC", hound_202)




    //Chow Bunlde Images
    artMap.set("Chow-Bundle-10", chowBundle10)
    artMap.set("Chow-Bundle-50", chowBundle50)
    artMap.set("Chow-Bundle-100", chowBundle100)
    artMap.set("Chow-Bundle-200", chowBundle200)

    //Backgrounds
    artMap.set("GamePageBG", GamePageBackgroundImg)
    artMap.set("LorePageBG", LorePageBackgroundImg)
    artMap.set("StorePageBG", StorePageBackgroundImg)
    artMap.set("InventoryBG", InventoryBG )
    artMap.set("ProfileBG", ProfileBG)
    artMap.set("DeckPageBG", DeckPageBG)
    artMap.set("HowHomeBg", HowHomeBg)
    artMap.set("HowHomeBgMobile", HowHomeBgMobile)
    artMap.set("GuidePageBG", GuidePageBG)
    artMap.set("Bg1", Bg1)
    artMap.set("Bg2", Bg2)
    

    //Website Art Assets

    artMap.set("SlotBorder", SlotBorder)
    artMap.set("AppbarLogoSmall", AppbarLogoSmall)
    
    //Homepage Art Assets

    artMap.set("DeckBack", DeckBack)
    artMap.set("HowHomeBg", HowHomeBg)
    artMap.set("BattleToWin", BattleToWin)

    artMap.set("ChowSymbol", ChowSymbol)


    //card id to card image mapping
    artMap.set("unanounced1", DeckBack)
    artMap.set("unanounced2", DeckBack)
    artMap.set("unanounced3", DeckBack)
    artMap.set("unanounced4", DeckBack)
    artMap.set("unanounced5", DeckBack)

    
    
    function getArt(artName) {
        return artMap.get(artName)
    }

    return ({
        getArt: getArt
    })
}

export default CardArtProvider
