import React, { useState, useEffect, useReducer } from 'react'
import { useHistory } from "react-router-dom";
import { useHowApiClient } from '../helpers/HowApiClient';
import { useStore } from '../helpers/GlobalStore';
import styled from "styled-components";
import Phaser from "phaser"
import { Grid } from '@material-ui/core'
import Layers from './game/Layers.js';
import GenericButton from './game/GenericButton'
import ToggleButton from './game/ToggleButton'
import Card from './game/Card'
import Slot from './game/Slot'
import VisualDeck from './game/VisualDeck'
import PlayerBanner from './game/PlayerBanner'
import EnemyBanner from './game/EnemyBanner'
import PlayField from './game/PlayField'
import ButtonManager from './game/ButtonManager'
import GameManager from './game/GameManager'
import BackgroundManager from '../components/BackgroundImageManager'
import {Headline} from '../components/Headline'
import useWallet from "../helpers/useWallet";
import { useAuthReducer } from "../helpers/AuthReducer";
import AudioManager from './game/AudioManager'
import {
    MAIN_BG_MUSIC,
    SEARCHING_MUSIC,
    HIGH_CARD_MUSIC,
    VICTORY_MUSIC,
    DEFEAT_MUSIC
} from './game/AudioManager'

import SvgButton from "../components/SvgButton"

import CONFIG from "../config/config.js"
import DeckSelector from '../components/GamePageDeckSelector'
import { useWindowDimensions } from '../helpers/windowDimentionProvider';
import ArtLoader from "../assets/CardArtProvider"
import {Button} from '@material-ui/core';


const { getArt } = ArtLoader();


const ConnectButton = styled(Button)`

    color: black;
    font-family: "helvetica"!important;
    font-weight: bold;
    font-size: 18pt;
    line-height: 24px;
    text-transform: capitalize;
    background: linear-gradient(#d05000 20%, #ffff00 100%);

    border-radius: 10px;
    cursor: pointer;
    opacity: .75;
    text-align: center;
    margin: auto;



    transition: all linear .2s;
    
    &:hover{
        transform: scale(1.1);
        opacity: 1;
        background: linear-gradient(#d05000 0%, #ffff00 90%, #ffffff 100%);
        border: 1px solid white;
        border-left: none;
        border-right: none;
    }
    &:disabled{
        filter: grayscale(1);
    }
`


//Debug only, used to manually tell the game server the name of the user
var providedUserId = "DefaultPlayer " + Math.random() * (100 - 1) + 1;
function handleProvidedUserIdChange(event) {
    providedUserId = event.target.value;
}

//Shared Top Level Objects
var theGame;
var mainScene;
var initScreen;
var loadingText;

//Playmat Elements
var audioManager;
var muteToggleButton;
var visualDeck;
var detailViewSlot;
var enemyDetailViewSlot;
var summonButton;
var useButton;
var attackButton;
var discardButton;
var endTurnButton;
var forfeitButton;
var playerBanner;
var enemyBanner;
var playField;
var forfeitConfirmationButton;
var forfeitCancelButton;
var summonCancelButton;
var replaceButton;
var replaceCancelButton;
var forfeitPopover;
var popover;
var selectedSummonLane = 0;
var buttonManager;
var laneButtons;

//Timer Elements
var timerText;
var timerEvent;
var timerEventCompletionCallback;
var timerValue_s;

//Multiplayer Elements
var gameManager;

//playmat scale/position configs
var playmayLandscapeConfig;
var playmayPortraitConfig;
var selectedScalePositionConfig;

var phaserConfigLandscape;
var phaserConfigPortrait;

const GamePage = () => {

        const { userRoles, selectedGameDeck, webToken, userAddress, isGameLoaded, setIsGameLoaded, gameLoading, setGameLoading } = useStore();
        const { getUserByAddress, downForMaintenanceAsync } = useHowApiClient();
        const { isMatchMaking, setIsMatchMaking } = useStore();
        const { width, height } = useWindowDimensions();
        const {authState} = useAuthReducer();
        const { login, getCurrentChainId, logout, enableWalletListeners, disableWalletListeners } = useWallet();    
        
        const history = useHistory();
        const [isDeckValid, setIsDeckValid] = useState(false);
        const [downForMaintenance, setDownForMaintenance] = useState(true);
        const [reloadDownForMaintenanceFlag, setReloadDownForMaintenanceFlag] = useState(true);

        //TODO: probably should have this automatically determined by the Slot
        const CARD_ART_SCALE = 0.88; //scaling factor for art in the detail views
        const BUTTON_ART_SCALE = 1.0; //scaling factor for button art

        const [connectionState, cbtDispatch] = useReducer( connectionReducer, 
            {
                connectButtonText: 'Connect',
                open: false,
                appbarHovered: false
            }
        );
       

        function connectionReducer(connectionState, action) {
          
            switch (action.type) {
                case 'clearNavState': {
                    return {
                        ...connectionState,
                        connectButtonText: 'Connect',
                        open: false,
                        appbarHovered: false
                    };
                }
                case 'updateButtonText': {
                    return {
                        ...connectionState,
                        connectButtonText: action.payload.displayText,
                    }
                }
            }
        }

        function truncateUsername(){

            const truncatedUsername = authState.userName && authState.userName.length < 14? authState.userName: authState.userName.substring(0,10).concat('...')
            return(truncatedUsername)
            
        }

        useEffect(()=>{
            if(authState && authState.userAddress && authState.truncatedAddress){
                cbtDispatch({ 
                    type: 'updateButtonText', 
                    payload: {
                        displayText: authState.userName? `${truncateUsername()} ${authState.truncatedAddress}`: authState.truncatedAddress 
                    }})
            }
                
        },[authState])

        phaserConfigLandscape = {
            transparent: true,
            type: Phaser.AUTO,
            mode: Phaser.Scale.FIT,
            width: 1200,
            height: 800,

            scale: {
                // Or set parent divId here
                parent: 'phaserParent',
        
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_BOTH,
        
                // Or put game size here
                // width: 1024,
                // height: 768,
        
                // Minimum size
                min: {
                    width: 320,
                    height: 212
                },
                // Maximum size
                max: {
                    width: 3240,
                    height: 2160
                },
                zoom: 1,  // Size of game canvas = game size * zoom
            },
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 200 }
                }
            },
            scene: {
                preload: phaserPreload,
                create: phaserCreate,
                update: phaserUpdate
            },
            audio: {
                disableWebAudio: true
            },
            parent: 'phaserParent',
            seed: [ (Date.now() * Math.random()).toString() ]
        };

        phaserConfigPortrait = {
            transparent: true,
            type: Phaser.AUTO,
            mode: Phaser.Scale.FIT,
            width: 360,
            height: 800,

            scale: {
                // Or set parent divId here
                parent: 'phaserParent',
        
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_BOTH,
        
                // Or put game size here
                // width: 1024,
                // height: 768,
        
                // Minimum size
                min: {
                    width: 320,
                    height: 600
                },
                // Maximum size
                max: {
                    width: 2160,
                    height: 3240
                },
                zoom: 1,  // Size of game canvas = game size * zoom
            },
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 200 }
                }
            },
            scene: {
                preload: phaserPreload,
                create: phaserCreate,
                update: phaserUpdate
            },
            audio: {
                disableWebAudio: true
            },
            parent: 'phaserParent',
            seed: [ (Date.now() * Math.random()).toString() ]
        };
    
        useEffect(()=> {
            if(gameLoading && !isGameLoaded){
                const phaserConfig = width > height? phaserConfigLandscape: phaserConfigPortrait;
                if(theGame !== null && theGame !== undefined){
                    theGame.destroy(true, false);
                }
                phaserConfigLandscape.parent = 'phaserParent';
                phaserConfigLandscape.scale.parent = 'phaserParent';
                phaserConfigPortrait.parent = 'phaserParent';
                phaserConfigPortrait.scale.parent = 'phaserParent';
                theGame = new Phaser.Game(phaserConfig);    
            }
        }, [gameLoading, isGameLoaded]);

        useEffect(() => {
            if (selectedGameDeck > 0 || CONFIG.DEBUG_MODE) {
                setIsDeckValid(true);
            }
            else {
                setIsDeckValid(false);
            }
        }, [selectedGameDeck]);

        useEffect(() => {
            if (reloadDownForMaintenanceFlag) {
                getDownForMaintenanceAsync();
                setReloadDownForMaintenanceFlag(false);
            }
        }, [reloadDownForMaintenanceFlag]);

        async function getDownForMaintenanceAsync() {
            const isDownForMaintenance = await downForMaintenanceAsync();
            console.log(`Game Page called downForMaintenanceAsync.  Result: ${isDownForMaintenance}`);
            setDownForMaintenance(isDownForMaintenance);
        }

        function matchMakingInProgress(inProgress){
            setIsMatchMaking(inProgress);
        }

        function connectToMatchServer(){
            //deckId options: balanced_deck, heavy_hitter_deck, all_edgars
            var deckId = CONFIG.DEBUG_MODE ? 'balanced_deck' : selectedGameDeck;

            console.log(`Connecting to game server as "${providedUserId}" with deckId: "${deckId}"...`);
            gameManager.asyncRegisterPlayer(providedUserId, deckId); //actually connects to the match server
        }

        function cancelMatchMaking(){

            //If the loading text is visible that means we're still in the match making process, so it's fair to perform the match making cancellation request
            if (loadingText && loadingText.visible && isMatchMaking){

                loadingText.setText("Cancelling search...");
                gameManager.asyncCancelMatchMaking(providedUserId);
            }

            //Set flag to call API and update the downForMaintenance inside the client's local store
            setReloadDownForMaintenanceFlag(true);
        }

        function onAttackClick(){
            var cardInDetailView = detailViewSlot.getObject();

            if (!cardInDetailView){
                console.error("Attacked without a card in the detail view");
                return;
            }

            var attackEnergyCost = cardInDetailView.cardMetaData.attackCost;
            var energyStatus = playerBanner.hasEnoughEnergy(attackEnergyCost);
            if (energyStatus === -1){
                console.log("Not enough energy to perform that action.");
            }

            var laneNumber = playField.getPlayerLaneNumberOfUniqueId(cardInDetailView.cardMetaData.uniqueId);

            gameManager.asyncAttack(cardInDetailView, laneNumber);
        }

        function onUseClick(){
            var cardInDetailView = detailViewSlot.getObject();

            if (!cardInDetailView){
                console.error("Used an item without a card in the detail view");
                return;
            }

            var useEnergyCost = cardInDetailView.cardMetaData.useCost;
            var energyStatus = playerBanner.hasEnoughEnergy(useEnergyCost);

            if (energyStatus === -1){
                console.log("Not enough energy to perform that action.");
            }

            gameManager.asyncUseItem(cardInDetailView);
        }

        function buttonStateCallback(button){
            if (gameManager.isGameOver()){
                return 'INVISIBLE';
            }

            const myTurnAndTimeRemaining = gameManager.isItMyTurn() && isThereTimeOnTheClock();

            if (button.buttonId === 'end_turn_button'){
                return myTurnAndTimeRemaining ? 'ENABLED' : 'DISABLED';
            }
            if (button.buttonId === 'forfeit_button'){
                return myTurnAndTimeRemaining ? 'ENABLED' : 'DISABLED';
            }

            //The actions available for this card are dependent on the origin of the card in the detail view
            var cardInDetailView = detailViewSlot.getObject();

            if (!cardInDetailView){
                return 'INVISIBLE'; //Nothing in detail view, so no buttons. Likely a discard action just occurred.
            }

            var originCard = cardInDetailView.originCard;

            if (!originCard.getParentContainerId){
                //This means the card in the detail view slot is likely from the server and not a local Card object
                //In this case we shouldn't show any buttons, if this is not desired behavior it's up to whoever put
                //the object there to begin with to properly construct a Card
                return 'INVISIBLE'; 
            }

            var cardParentId = originCard.getParentContainerId();

            if (!cardParentId){
                return 'INVISIBLE'; //No origin found for this card, so just hide all buttons
            }


            if (cardParentId === playField.fieldId){

                /*------ FIELD CARD -------*/

                if(originCard.cardMetaData.type === 'MONSTER'){
                    switch (button.buttonId) {
                        case 'discard_button':
                            return 'INVISIBLE';
                        case 'attack_button':
                            if (playerBanner.hasEnoughEnergy(cardInDetailView.cardMetaData.attackCost) && myTurnAndTimeRemaining && originCard.hasRemainingAttacksThisTurn())
                                return 'ENABLED';
                            else
                                return 'DISABLED';
                        case 'summon_button':
                            return 'INVISIBLE';
                        case 'use_button':
                            return 'INVISIBLE';
                        default:
                            console.error("wtf? who is button?..");
                            return 'INVISIBLE';
                    }
                } else if(originCard.cardMetaData.type === 'ITEM'){

                    console.error("How in the... How did you summon an Item card?");
                    return 'INVISIBLE';

                } else{
                    console.error("Invalid Card Type!");
                    return 'INVISIBLE';
                }

            } else if (cardParentId === playerBanner.bannerId){

                /*------ PLAYER HAND CARD -------*/

                if(originCard.cardMetaData.type === 'MONSTER'){
                    switch (button.buttonId) {
                        case 'discard_button':
                            if (gameManager.isDiscardAvailableThisTurn() && myTurnAndTimeRemaining && visualDeck.getLastKnownDeckSize() !== 0)
                                return 'ENABLED';
                            else
                                return 'DISABLED';
                        case 'attack_button':
                            return 'INVISIBLE';
                        case 'summon_button':
                            if (playerBanner.hasEnoughEnergy(cardInDetailView.cardMetaData.summonCost) && myTurnAndTimeRemaining)
                                return 'ENABLED';
                            else
                                return 'DISABLED';
                        case 'use_button':
                            return 'INVISIBLE';
                        default:
                            console.error("wtf? who is button?..");
                            return 'INVISIBLE';
                    }
                } else if(originCard.cardMetaData.type === 'ITEM'){
                    switch (button.buttonId) {
                        case 'discard_button':
                            if (gameManager.isDiscardAvailableThisTurn() && myTurnAndTimeRemaining && visualDeck.getLastKnownDeckSize() !== 0)
                                return 'ENABLED';
                            else
                                return 'DISABLED';
                        case 'attack_button':
                            return 'INVISIBLE';
                        case 'summon_button':
                            return 'INVISIBLE';
                        case 'use_button':
                            if (playerBanner.hasEnoughEnergy(cardInDetailView.cardMetaData.useCost) && myTurnAndTimeRemaining)
                                return 'ENABLED';
                            else
                                return 'DISABLED';
                        default:
                            console.error("wtf? who is button?..");
                            return 'INVISIBLE';
                    }
                } else{
                    console.error("Invalid Card Type!");
                    return 'INVISIBLE';
                }
            } else {
                console.error("Where the hell did this card come from?");
                return 'INVISIBLE'; //No origin found for this card, so just hide all buttons
            }
        }

        /*
            places the players desired creature to the selected lane
        */
        function onLaneClick(laneNumber){
            console.log(`lane clicked: ${laneNumber}`);
            if(laneNumber < 0 || laneNumber > 3){
                console.error("YOU GOOF! You nearly set the lane on fire (select 1, 2, or 3)!");
                return;
            }

            var cardInDetailView = detailViewSlot.getObject();
            console.log(cardInDetailView);
            console.log('calling async summon to communicate summon to server');

            gameManager.asyncSummon(cardInDetailView, laneNumber);
        }

        /*
            exits the 'REPLACE' selection screen within the 'SUMMON' popover screen
        */
        function onReplaceCancelClick(card){
            var emptyLanes = playField.getEmptyLanes();
                    
            //user is exiting 'REPLACE' screen, remove the occupied lane buttons
            laneButtons.forEach(occupiedLane => {
                occupiedLane.invisiblize();
                occupiedLane.disableButton();
            });

            //clear the old lane buttons
            laneButtons = [];
            
            //add the empty lane buttons back into the menu
            emptyLanes.forEach(emptyLane => {
                laneButtons.push(GenericButton('card-slot-in-field', mainScene,
                    { artName: 'card-detail-view-slot-selectable', artNameOnHover: 'card-detail-view-slot-hovered', artNameOnDisabled: 'button-summon-disabled' },
                      selectedScalePositionConfig.playField.cardScale * 1.2, {x: emptyLane.position.x, y: emptyLane.position.y, z: Layers.POPOVERS + 2}, onLaneClick.bind(null, emptyLane.lane)));
            });

            //Hide the replaceCancelButton and show the original Summon buttons when the user cancels Replace
            replaceButton.enableButton();
            replaceButton.visiblize();
            summonCancelButton.enableButton();
            summonCancelButton.visiblize();

            replaceCancelButton.invisiblize();
            replaceCancelButton.disableButton();
        }

        /*
            onReplaceClick brings up the UI elements indicating which Lane
            has a replaceable unit
        */
        function onReplaceClick(card){
            var occupiedLanes = playField.getOccupiedLanes();
            
            //hide the empty lane buttons from the user
            laneButtons.forEach(emptyLane => {
                emptyLane.invisiblize();
                emptyLane.disableButton();
            });

            //clear the old lane buttons
            laneButtons = [];

            //add a button for each occupied lane
            occupiedLanes.forEach(occupiedLane => {
                laneButtons.push(
                    GenericButton('card-slot-', mainScene,
                    { artName: 'card-detail-view-slot-replacable', artNameOnHover: 'card-detail-view-slot-replacable-hovered', artNameOnDisabled: 'button-summon-disabled' },
                      selectedScalePositionConfig.playField.cardScale * 1.2, {x: occupiedLane.position.x, y: occupiedLane.position.y, z: Layers.POPOVERS + 2}, onLaneClick.bind(null, occupiedLane.lane)));
            });

            //Only show the replaceCancelButton when the user has selected to Replace
            replaceCancelButton.visiblize();
            replaceCancelButton.enableButton();

            summonCancelButton.disableButton();
            summonCancelButton.invisiblize();
            replaceButton.disableButton();
            replaceButton.invisiblize();
        }

        //Asks user if they are sure they want to forfeit
        function showForfeitPopover(forfeitConfirmationCallback){

            let { width, height } = mainScene.sys.game.canvas;
            const centerY = height/2;

            forfeitConfirmationButton = GenericButton('forfeit-confirmation-button', mainScene,
                {
                    artName: 'button-forfeit',
                    artNameOnHover: 'button-forfeit-hover',
                    artNameOnDisabled: 'button-forfeit-disabled'
                },
                BUTTON_ART_SCALE * selectedScalePositionConfig.forfeitConfirmButton.scale,
                {
                    x: playField.getCenterOfField() - 100, 
                    y: centerY,
                    z: Layers.POPOVERS + 3
                }, 
                forfeitConfirmationCallback
            );

            forfeitCancelButton = GenericButton('forfeit-cancel-button', mainScene,
                {
                    artName: 'button-cancel',
                    artNameOnHover: 'button-cancel-hover',
                    artNameOnDisabled: 'button-cancel-disabled'
                },
                BUTTON_ART_SCALE * selectedScalePositionConfig.forfeitCancelButton.scale,
                {
                    x: playField.getCenterOfField() + 100, 
                    y: centerY,
                    z: Layers.POPOVERS + 3
                }, 
                clearForfeitPopover
            );

            //add the popover to the scene. The popover should appear above all other game elements (except the popover's interactable elements).
            if(width > height){
                forfeitPopover = mainScene.add.image(0, 0, 'overlay-background-forfeit').setOrigin(0).setScale(1).setDepth(Layers.POPOVERS).setInteractive({ useHandCursor: false });
            }
            else{
                forfeitPopover = mainScene.add.image(0, 0, 'overlay-background-forfeit-mobile').setOrigin(0).setScale(1).setDepth(Layers.POPOVERS).setInteractive({ useHandCursor: false });
            }
        }

        function clearForfeitPopover(){

            if (forfeitCancelButton){
                forfeitCancelButton.invisiblize();
                forfeitCancelButton.destroy();
            }

            if(forfeitConfirmationButton){
                forfeitConfirmationButton.invisiblize();
                forfeitConfirmationButton.destroy();
            }

            forfeitPopover && forfeitPopover.destroy();
        }

        function showSummonPopover(card){
            console.log('showing summon popover');
            let { width, height } = mainScene.sys.game.canvas;
            const centerX = height/2;

            laneButtons = [];
            var emptyLanes = playField.getEmptyLanes();

            //the cancel button will be shifted to accommodate the replace button
            var summonCancelButtonXPos = playField.getCenterOfField();

            /*
                the replaceCancelButton is used exit the 'REPLACE' selection screen
                without exiting the 'SUMMON' selection screen
            */
            replaceCancelButton = GenericButton('replace-cancel-button', mainScene,
                {
                    artName: 'button-cancel-replace',
                    artNameOnHover: 'button-cancel-replace-hover',
                    artNameOnDisabled: 'button-cancel-disabled'
                },
                selectedScalePositionConfig.summonPopoverButtons.scale,
                {
                    x: playField.getCenterOfField(), 
                    y: centerX - 140,
                    z: Layers.POPOVERS + 3
                }, 
                onReplaceCancelClick.bind(null, card)
            );

            //hide the replaceCancelButton, it will be enabled and displayed when the user presses 'REPLACE'
            replaceCancelButton.invisiblize();
            replaceCancelButton.disableButton();

            //only display the 'REPLACE' button if the playfield isn't empty
            if(!playField.isEmpty()){
                replaceButton = GenericButton('popover-cancel-button', mainScene,
                    {
                        artName: 'button-replace', 
                        artNameOnHover: 'button-replace-hover', 
                        artNameOnDisabled: 'button-replace-disabled' 
                    },
                    selectedScalePositionConfig.summonPopoverButtons.scale,
                    {
                        x: playField.getCenterOfField() + 100, 
                        y: centerX - 140,
                        z: Layers.POPOVERS + 2
                    },
                    onReplaceClick.bind(null, card)
                );
            }

            //shifts the 'CANCEL' button over to accommodate the 'REPLACE' button
            if(replaceButton){
                summonCancelButtonXPos = playField.getCenterOfField() - 100;
            }

            //the summonCancelButton is used to exit the summoning screen. It is active in the 'SUMMON' and 'REPLACE' screens 
            summonCancelButton = GenericButton('popover-cancel-button', mainScene,
                {
                    artName: 'button-cancel',
                    artNameOnHover: 'button-cancel-hover',
                    artNameOnDisabled: 'button-cancel-disabled'
                },
                selectedScalePositionConfig.summonPopoverButtons.scale,
                {
                    x: summonCancelButtonXPos, 
                    y: centerX - 140,
                    z: Layers.POPOVERS + 2
                },
                clearSummonPopover
            );

            //add the popover to the scene. The popover should appear above all other game elements (except the popover's interactable elements).
            if(width > height){
                popover = mainScene.add.image(0, 0, 'overlay-background-summon').setOrigin(0).setScale(1).setDepth(Layers.POPOVERS).setInteractive({ useHandCursor: false });
            }
            else{
                popover = mainScene.add.image(0, 0, 'overlay-background-summon-mobile').setOrigin(0).setScale(1).setDepth(Layers.POPOVERS).setInteractive({ useHandCursor: false });
            }
            
            //add the lane select buttons to the scene above the popover.
            for(var i = 0; i < emptyLanes.length; i++){
                laneButtons.push(
                    GenericButton('card-slot-'+i.toString(), mainScene,
                        {
                            artName: 'card-detail-view-slot-selectable',
                            artNameOnHover: 'card-detail-view-slot-hovered',
                            artNameOnDisabled: 'button-summon-disabled'
                        },
                        selectedScalePositionConfig.playField.cardScale * 1.2,
                        {
                            x: emptyLanes[i].position.x, 
                            y: emptyLanes[i].position.y,
                            z: Layers.POPOVERS + 2
                        }, 
                        onLaneClick.bind(null, emptyLanes[i].lane)
                    )
                );
            }

            //wait for the user to interact with the options
            while(selectedSummonLane === 0){
                return;
            }
        }

        //clears the popover and related elements from the screen
        function clearSummonPopover(){

            if (summonCancelButton){
                summonCancelButton.invisiblize();
                summonCancelButton.destroy();
            }

            if(replaceButton){
                replaceButton.invisiblize();
                replaceButton.destroy();
            }

            if(replaceCancelButton){
                replaceCancelButton.invisiblize();
                replaceCancelButton.destroy();
            }

            if (laneButtons){
                laneButtons.forEach(laneButton => {
                    laneButton.invisiblize();
                    laneButton.destroy();
                });
            }

            popover && popover.destroy();
        }

        //called when the user clicks the 'SUMMON' button. 
        function onSummonClick(){
            console.log('Summon Clicked...');
            var cardInDetailView = detailViewSlot.getObject();
            console.log(detailViewSlot);

            //in the catastrophic event that there is no card in the detail view
            if (!cardInDetailView) return;

            console.log(cardInDetailView);
            //Determine the origin of the card we're summoning
            var originCard = cardInDetailView.originCard;
            var cardParentId = originCard.getParentContainerId();

            //Check that the card came from the player's hand before attempting the summon action
            if (cardParentId === playerBanner.bannerId){
                var copyOfCard = Card(cardInDetailView.cardId, mainScene, 'cards', cardInDetailView.artName, null, cardInDetailView.cardMetaData, onUnexpectedClick);

                showSummonPopover(copyOfCard);

            } else {
                console.error("Attempted to summon on a card that wasn't in the player's hand.");
            }
        }

        function clearDetailViewIfHasId(uniqueId){
            var cardInDetailView = detailViewSlot.getObject();

            if (!cardInDetailView) return;

            if (cardInDetailView.cardMetaData.uniqueId === uniqueId){
                detailViewSlot.destroyObject();
            }
        }

        function clearEnemyDetailViewIfHasId(uniqueId){
            var cardInDetailView = enemyDetailViewSlot.getObject();

            if (!cardInDetailView) return;

            if (cardInDetailView.cardMetaData.uniqueId === uniqueId){
                enemyDetailViewSlot.destroyObject();
            }
        }

        function showCopyOfCardInEnemyDetailView(card){
            //Clear out what's in the enemy detail view
            if (enemyDetailViewSlot){
                enemyDetailViewSlot.destroyObject();
            }
            //Make a copy of the card
            var copyOfCard = Card(card.cardId, mainScene, 'cards', card.artName, null, card.cardMetaData, onEnemyDetailViewCardClick);
            copyOfCard.draw(CARD_ART_SCALE * selectedScalePositionConfig.enemyDetailView.scale, {x: selectedScalePositionConfig.enemyDetailView.x, y: selectedScalePositionConfig.enemyDetailView.y, z: 10}, false);
            copyOfCard.originCard = card;

            //Show that copy in the enemy detail view
            enemyDetailViewSlot.placeObject(copyOfCard);
        }

        function onEnemyDetailViewCardClick(card, buttonId){
            console.log("Card was clicked in enemy detail view: " + card.cardId + "(id=" + card.cardMetaData.uniqueId + ", buttonId=" + buttonId + ")");
            enemyDetailViewSlot.destroyObject();
        }

        function showCopyOfCardInDetailView(card){
            //Clear out what's in the detail view slot
            if (detailViewSlot){
                detailViewSlot.destroyObject();
            }

            //Make a copy of the card
            var copyOfCard = Card(card.cardId, mainScene, 'cards', card.artName, null, card.cardMetaData, onDetailViewCardClick);
            copyOfCard.draw(CARD_ART_SCALE * selectedScalePositionConfig.detailView.scale, {x: selectedScalePositionConfig.detailView.x, y: selectedScalePositionConfig.detailView.y, z: 10}, false);
            copyOfCard.originCard = card;

            //Show that copy in the detail view
            detailViewSlot.placeObject(copyOfCard);
            buttonManager.redrawButtons();
        }

        function onRemainingDrawCardsUpdated(drawCardsRemaining){
            visualDeck.visuallyUpdateDeckSize(drawCardsRemaining);
        }

        function onFieldPlayerCardClick(cardClicked){
            showCopyOfCardInDetailView(cardClicked);
            console.log("Player field card was clicked: " + cardClicked.cardId + "(id=" + cardClicked.cardMetaData.uniqueId + ")");
        }

        function onFieldEnemyCardClick(cardClicked){
            showCopyOfCardInEnemyDetailView(cardClicked);
            console.log("Enemy field card was clicked: " + cardClicked.cardId + "(id=" + cardClicked.cardMetaData.uniqueId + ")");
        }

        function onBannerCardClick(cardClicked){
            showCopyOfCardInDetailView(cardClicked);
            console.log("Banner card was clicked: " + cardClicked.cardId + "(id=" + cardClicked.cardMetaData.uniqueId + ")");
        }

        function onDetailViewCardClick(card, buttonId){
            console.log("Card was clicked in detail view: " + card.cardId + "(id=" + card.cardMetaData.uniqueId + ", buttonId=" + buttonId + ")");

            detailViewSlot.destroyObject();
            buttonManager.redrawButtons();
        }

        // Performs the Discard Action
        function onDiscardClick(){
            console.log('discard clicked');
            var cardInDetailView = detailViewSlot.getObject();

            if (!cardInDetailView){
                buttonManager.redrawButtons();
                return;
            }

            gameManager.asyncDiscard(cardInDetailView);
        }

        function onMulliganClick(){
            console.log('mulligan clicked');
            gameManager.asyncMulligan();
        }

        function onUnexpectedClick(card){
            console.error("A card was clicked on before it had it's callback action defined:");
            console.log(card);
        }

        function onEndTurnClick(){
            console.log('end turn clicked');
            gameManager.asyncEndTurn();

            endTurnButton.disableButton();
        }

        function onForfeitClick(){
            //Show the forfeit popover, if the user confirms, perform the forfeit request
            showForfeitPopover(() => {
                gameManager.asyncForfeit();
                forfeitButton.disableButton();
            });
        }

        

        function constructPlayMat() {
            let { width, height } = mainScene.sys.game.canvas;

            playmayLandscapeConfig = {
                muteToggleButton:{
                    x: width * 0.976,
                    y: height * 0.967,
                    z: Layers.ABOVE_ALL,
                    scale: 0.4,
                },
                visualDeck:{
                    x: width * 0.87,
                    y: height * 0.83,
                    z: 3,
                    scale: 0.5,
                },
                timerText:{
                    x: width * 0.12,
                    y: height * 0.1,
                    z: 3,
                    scale: 1,
                },
                detailView: 
                {
                    x: width * 0.12,
                    y: height * 0.76, 
                    z: 3,
                    scale: 1,
                },
                enemyDetailView:
                {
                    x: width * 0.88, 
                    y: height * 0.24,
                    z: 3,
                    scale: 1,
                },
                summonButton:
                {
                    x: width * 0.12, 
                    y: height * 0.38, 
                    z: 4,
                    scale: BUTTON_ART_SCALE
                },
                summonPopoverButtons:
                {
                    x: width * 0.6, 
                    y: height * 0.5, 
                    z: 4,
                    scale: BUTTON_ART_SCALE
                },
                useButton:
                {
                    x: width * 0.12, 
                    y: height * 0.38, 
                    z: 3,
                    scale: BUTTON_ART_SCALE
                },
                attackButton:
                {
                    x: width * 0.12, 
                    y: height * 0.44, 
                    z: 3,
                    scale: BUTTON_ART_SCALE
                },
                discardButton:
                {
                    x: width * 0.12, 
                    y: height * 0.50, 
                    z: 3,
                    scale: BUTTON_ART_SCALE
                },
                endTurnButton:
                {
                    x: width * 0.88, 
                    y: height * 0.58, 
                    z: 3,
                    scale: BUTTON_ART_SCALE
                },
                forfeitButton:
                {
                    x: width * 0.88, 
                    y: height * 0.65, 
                    z: 3,
                    scale: BUTTON_ART_SCALE
                },
                playerBanner:
                {
                    x: width * 0.5,
                    y: height  *0.88, 
                    z: 3,
                    scale: 0.75,
                    cardScale: 1
                },
                enemyBanner:
                {
                    x: width * 0.5,
                    y: height  *0.08, 
                    z: 3,
                    scale: 0.75,
                    cardScale: 1
                },
                playField:
                {
                    x: width * 0.5, 
                    y: height * 0.45, 
                    z: 3,
                    scale: 0.75,
                    cardScale: 0.4
                },
                forfeitCancelButton:
                {
                    x: width * 0.3, 
                    y: height * 0.5, 
                    z: 3,
                    scale: BUTTON_ART_SCALE
                },
                forfeitConfirmButton:
                {
                    x: width * 0.7, 
                    y: height * 0.5, 
                    z: 3,
                    scale: BUTTON_ART_SCALE
                },
            };
            

            playmayPortraitConfig = {
                muteToggleButton:{
                    x: width * 0.9,
                    y: height * 0.967,
                    z: Layers.ABOVE_ALL,
                    scale: 0.4,
                },
                visualDeck:{
                    x: width * 0.84,
                    y: height * 0.83,
                    z: 3,
                    scale: 0.22,
                },
                timerText:{
                    x: width * 0.25,
                    y: height * 0.05,
                    z: 3,
                    scale: 1,
                },
                detailView: 
                {
                    x: width * 0.24,
                    y: height * 0.85, 
                    z: 3,
                    scale: .6,
                },
                enemyDetailView:
                {
                    x: width * 0.83, 
                    y: height * 0.1,
                    z: 3,
                    scale: .45,
                },
                summonButton:
                {
                    x: width * 0.6, 
                    y: height * 0.5, 
                    z: 4,
                    scale: BUTTON_ART_SCALE * 0.5
                },
                summonPopoverButtons:
                {
                    x: width * 0.6, 
                    y: height * 0.5, 
                    z: 4,
                    scale: BUTTON_ART_SCALE * 0.8
                },
                useButton:
                {
                    x: width * 0.5, 
                    y: height * 0.7, 
                    z: 3,
                    scale: BUTTON_ART_SCALE * 0.5
                },
                attackButton:
                {
                    x: width * 0.5, 
                    y: height * 0.7, 
                    z: 3,
                    scale: BUTTON_ART_SCALE * 0.5
                },
                discardButton:
                {
                    x: width * 0.6, 
                    y: height * 0.8, 
                    z: 3,
                    scale: BUTTON_ART_SCALE * 0.5
                },
                endTurnButton:
                {
                    x: width * 0.6, 
                    y: height * 0.91, 
                    z: 3,
                    scale: BUTTON_ART_SCALE * 0.5
                },
                forfeitButton:
                {
                    x: width * 0.6, 
                    y: height * 0.97, 
                    z: 3,
                    scale: BUTTON_ART_SCALE * 0.5
                },
                playerBanner:
                {
                    x: width * 0.5,
                    y: height  *0.65, 
                    z: 3,
                    scale: 0.40,
                    cardScale: 0.6
                },
                enemyBanner:
                {
                    x: width * 0.5,
                    y: height * 0.25, 
                    z: 3,
                    scale: 0.40, 
                    cardScale: 0.6
                },
                playField:
                {
                    x: width * 0.5, 
                    y: height * 0.44, 
                    z: 3,
                    scale: 0.40,
                    cardScale: 0.24
                },
                forfeitCancelButton:
                {
                    x: width * 0.35, 
                    y: height * 0.5, 
                    z: 3,
                    scale: BUTTON_ART_SCALE * 0.9
                },
                forfeitConfirmButton:
                {
                    x: width * 0.65, 
                    y: height * 0.5, 
                    z: 3,
                    scale: BUTTON_ART_SCALE * 0.9
                },
            };

            selectedScalePositionConfig = width > height? playmayLandscapeConfig : playmayPortraitConfig;

            //use black screen to hide game components while game has not yet initialized
            initScreen = mainScene.add.image(width/2, height/2, 'init-screen').setDepth(Layers.ABOVE_ALL).setOrigin(0.5);
            loadingText = mainScene.add.bitmapText(width/2, height/2, 'gothic', "Searching...", 60).setOrigin(0.5).setDepth(Layers.ABOVE_ALL+1);

            /** Audio Management */

                audioManager = AudioManager(mainScene);
                audioManager.setVolumeLow(); //default to low, since that's where we're starting the toggle button

                muteToggleButton = ToggleButton('mute_toggle_button', mainScene, selectedScalePositionConfig.muteToggleButton.scale, {x: selectedScalePositionConfig.muteToggleButton.x, y: selectedScalePositionConfig.muteToggleButton.y, z: selectedScalePositionConfig.muteToggleButton.z}, [
                    {
                        artName: "volume-low-icon",
                        callback: audioManager.setVolumeMedium
                    },
                    {
                        artName: "volume-medium-icon",
                        callback: audioManager.setVolumeHigh
                    },
                    {
                        artName: "volume-high-icon",
                        callback: audioManager.setVolumeOff
                    },
                    {
                        artName: "volume-mute-icon",
                        callback: audioManager.setVolumeLow
                    }
                ]);

            // -------- build the playmat --------

            visualDeck = VisualDeck('deck_object', mainScene, 'deck-back', 666, selectedScalePositionConfig.visualDeck.scale, {x: selectedScalePositionConfig.visualDeck.x, y: selectedScalePositionConfig.visualDeck.y, z: selectedScalePositionConfig.visualDeck.z}, null);
            visualDeck.draw();

            timerText = mainScene.add.bitmapText(selectedScalePositionConfig.timerText.x, selectedScalePositionConfig.timerText.y, 'gothic', "00:00", 60).setOrigin(0.5).setDepth(3);
            //timerText.setCharacterTint(0, -1, true, 0x21201e);

            /** Detail View Slots */

                detailViewSlot = Slot('detail_view_slot', mainScene, 'card-detail-view-slot', selectedScalePositionConfig.detailView.scale, {x: selectedScalePositionConfig.detailView.x, y: selectedScalePositionConfig.detailView.y, z: selectedScalePositionConfig.detailView.z});
                enemyDetailViewSlot = Slot('detail_view_slot', mainScene, 'card-detail-view-slot', selectedScalePositionConfig.enemyDetailView.scale, {x: selectedScalePositionConfig.enemyDetailView.x, y: selectedScalePositionConfig.enemyDetailView.y, z: selectedScalePositionConfig.enemyDetailView.z});

            /** Detail View Buttons */

                summonButton = GenericButton('summon_button', mainScene,
                    { artName: 'button-summon', artNameOnHover: 'button-summon-hover', artNameOnDisabled: 'button-summon-disabled' },
                    selectedScalePositionConfig.summonButton.scale, {x: selectedScalePositionConfig.summonButton.x, y: selectedScalePositionConfig.summonButton.y, z: selectedScalePositionConfig.summonButton.z}, onSummonClick );
                
                useButton = GenericButton('use_button', mainScene,
                    { artName: 'button-use', artNameOnHover: 'button-use-hover', artNameOnDisabled: 'button-use-disabled' },
                    selectedScalePositionConfig.useButton.scale, {x: selectedScalePositionConfig.useButton.x, y: selectedScalePositionConfig.useButton.y, z: selectedScalePositionConfig.useButton.z}, onUseClick );

                attackButton = GenericButton('attack_button', mainScene, 
                    { artName: 'button-attack', artNameOnHover: 'button-attack-hover', artNameOnDisabled: 'button-attack-disabled' },
                    selectedScalePositionConfig.attackButton.scale, {x: selectedScalePositionConfig.attackButton.x, y: selectedScalePositionConfig.attackButton.y, z: selectedScalePositionConfig.attackButton.z}, onAttackClick );

                discardButton = GenericButton('discard_button', mainScene, 
                    { artName: 'button-discard' , artNameOnHover: 'button-discard-hover', artNameOnDisabled: 'button-discard-disabled' },
                    selectedScalePositionConfig.discardButton.scale, {x: selectedScalePositionConfig.discardButton.x, y: selectedScalePositionConfig.discardButton.y, z: selectedScalePositionConfig.discardButton.z}, onDiscardClick );

                summonButton.disableButton();
                useButton.disableButton();
                attackButton.disableButton();
                discardButton.disableButton();

                //initialize the ButtonManager and pass the buttons you want it to manage.
                var cardActionButtons = [];
                cardActionButtons.push(discardButton);
                cardActionButtons.push(attackButton);
                cardActionButtons.push(useButton);
                cardActionButtons.push(summonButton);

                var firstButtonPos = discardButton.getPosition();

            /** Right Side Buttons */

                endTurnButton = GenericButton('end_turn_button', mainScene, 
                    { artName: 'button-end-turn' , artNameOnHover: 'button-end-turn-hover', artNameOnDisabled: 'button-end-turn-disabled' },
                    selectedScalePositionConfig.endTurnButton.scale, {x: selectedScalePositionConfig.endTurnButton.x, y: selectedScalePositionConfig.endTurnButton.y, z: selectedScalePositionConfig.endTurnButton.z}, onEndTurnClick );
                endTurnButton.disableButton();

                forfeitButton = GenericButton('forfeit_button', mainScene, 
                    { artName: 'button-forfeit' , artNameOnHover: 'button-forfeit-hover', artNameOnDisabled: 'button-forfeit-disabled' },
                    selectedScalePositionConfig.forfeitButton.scale, {x: selectedScalePositionConfig.forfeitButton.x, y: selectedScalePositionConfig.forfeitButton.y, z: selectedScalePositionConfig.forfeitButton.z}, onForfeitClick );
                forfeitButton.disableButton();

                buttonManager = ButtonManager(cardActionButtons, firstButtonPos, height*0.06, buttonStateCallback, endTurnButton, forfeitButton);

            /** Banners and Field */

                playerBanner = PlayerBanner(mainScene, 'banner-art', selectedScalePositionConfig.playerBanner.scale, {x: selectedScalePositionConfig.playerBanner.x, y: selectedScalePositionConfig.playerBanner.y, z: selectedScalePositionConfig.playerBanner.z}, onBannerCardClick, onMulliganClick, selectedScalePositionConfig.playerBanner.cardScale);
                playField = PlayField(mainScene, 'play-field', selectedScalePositionConfig.playField.scale, {x: selectedScalePositionConfig.playField.x, y: selectedScalePositionConfig.playField.y, z: selectedScalePositionConfig.playField.z}, selectedScalePositionConfig.playField.cardScale);
                enemyBanner = EnemyBanner(mainScene, 'banner-art-enemy', selectedScalePositionConfig.enemyBanner.scale, {x: selectedScalePositionConfig.enemyBanner.x, y: selectedScalePositionConfig.enemyBanner.y, z: selectedScalePositionConfig.enemyBanner.z}, selectedScalePositionConfig.enemyBanner.cardScale);

            /** Game Management */

                gameManager = GameManager({
                    mainScene: mainScene,
                    playField: playField,
                    playerBanner: playerBanner,
                    enemyBanner: enemyBanner,
                    buttonManager: buttonManager,
                    detailViewSlot: detailViewSlot,
                    enemyDetailViewSlot: enemyDetailViewSlot,
                    initScreen: initScreen,
                    loadingText: loadingText
                    }, {
                        matchMakingInProgress: matchMakingInProgress,
                        stopTimer: stopTimer,
                        startTimerInSeconds: startTimerInSeconds,
                        clearDetailViewIfHasId: clearDetailViewIfHasId,
                        clearEnemyDetailViewIfHasId: clearEnemyDetailViewIfHasId,
                        showCopyOfCardInDetailView: showCopyOfCardInDetailView,
                        showCopyOfCardInEnemyDetailView: showCopyOfCardInEnemyDetailView,
                        onFieldPlayerCardClick: onFieldPlayerCardClick,
                        onFieldEnemyCardClick: onFieldEnemyCardClick,
                        onRemainingDrawCardsUpdated: onRemainingDrawCardsUpdated,
                        clearSummonPopover: clearSummonPopover,
                        audioManager: audioManager,
                        disconnectGame: disconnectGame,
                        updateUserProfileMMR: updateUserProfileMMR,
                    }, 
                    authState.webToken, 
                    unauthorizedErrorHandler);

                setIsGameLoaded(true);
                buttonManager.redrawButtons();
                
                gameManager.startMatchMakingAnimation();
                connectToMatchServer();
        }

        function updateUserProfileMMR(){
            getUserByAddress(authState.userAddress)
        }
        
        function disconnectGame() {
            stopTimer(null);
            clearSummonPopover();
            detailViewSlot && detailViewSlot.destroyObject();
            enemyDetailViewSlot && enemyDetailViewSlot.destroyObject();
            buttonManager.redrawButtons();

            //Reset page state so that a new game can connect
            updateUserProfileMMR()
            setIsGameLoaded(false);
        }
        
        async function unauthorizedErrorHandler(error) {
            console.log(error);
            // TODO: Prompt user to log in
        }

        function phaserPreload()
        {
            //I think "this" is the scene (not the scene manager)

            this.canvas = this.sys.game.canvas;
            this.load.atlas('cards', 'card-atlas.png', 'card-atlas.json');
            this.load.atlas('heal-values', 'heal-atlas.png', 'heal-atlas.json');

            //audio
            this.load.audio(MAIN_BG_MUSIC, ['fight-loop.mp3']);
            this.load.audio(SEARCHING_MUSIC, ['searching-music.mp3']);
            this.load.audio(HIGH_CARD_MUSIC, ['high-card-music.mp3']);
            this.load.audio(VICTORY_MUSIC, ['victory-music.mp3']);
            this.load.audio(DEFEAT_MUSIC, ['defeat-music.mp3']);
            this.load.image('volume-low-icon', 'volume-low-icon.png');
            this.load.image('volume-medium-icon', 'volume-medium-icon.png');
            this.load.image('volume-high-icon', 'volume-high-icon.png');
            this.load.image('volume-mute-icon', 'volume-mute-icon.png');

            //attack animations
            this.load.atlas('swipe-attack', 'swipe-attack.png', 'swipe-attack.json');
            this.load.atlas('bite-attack', 'bite-attack.png', 'bite-attack.json');
            // this.load.atlas('fire-attack', 'bite-attack.png', 'bite-attack.json');
            // this.load.atlas('frost-attack', 'swipe-attack.png', 'swipe-attack.json');
            // this.load.atlas('poison-attack', 'bite-attack.png', 'bite-attack.json');
            // this.load.atlas('light-attack', 'swipe-attack.png', 'swipe-attack.json');
            // this.load.atlas('lightning-attack', 'bite-attack.png', 'bite-attack.json');
            
            //other animations
            this.load.atlas('summon-atlas', 'summon-atlas.png', 'summon-atlas.json');

            this.load.bitmapFont('gothic', 'gothic.png', 'gothic.xml');
            this.load.image('deck-back', 'deck-back.png');
            this.load.image('card-detail-view-slot', 'card-detail-view-slot.png');
            this.load.image('playmat01','playmat01.png');
            this.load.image('button-summon','button-summon.png');
            this.load.image('button-summon-hover','button-summon-hover.png');
            this.load.image('button-summon-disabled','button-summon-disabled.png');
            this.load.image('button-use','button-use.png');
            this.load.image('button-use-hover','button-use-hover.png');
            this.load.image('button-use-disabled','button-use-disabled.png');
            this.load.image('button-attack','button-attack.png');
            this.load.image('button-attack-hover','button-attack-hover.png');
            this.load.image('button-attack-disabled','button-attack-disabled.png');
            this.load.image('button-discard','button-discard.png');
            this.load.image('button-discard-hover','button-discard-hover.png');
            this.load.image('button-discard-disabled','button-discard-disabled.png');
            this.load.image('button-end-turn','button-end-turn.png');
            this.load.image('button-end-turn-hover','button-end-turn-hover.png');
            this.load.image('button-end-turn-disabled','button-end-turn-disabled.png');
            this.load.image('button-forfeit','button-forfeit.png');
            this.load.image('button-forfeit-hover','button-forfeit-hover.png');
            this.load.image('button-forfeit-disabled','button-forfeit-disabled.png');
            this.load.image('button-cancel','button-cancel.png');
            this.load.image('button-cancel-hover','button-cancel-hover.png');
            this.load.image('button-cancel-disabled','button-cancel-disabled.png');
            this.load.image('button-replace','button-replace.png');
            this.load.image('button-replace-hover','button-replace-hover.png');
            this.load.image('button-replace-disabled','button-replace-disabled.png');
            this.load.image('button-cancel-replace','button-cancel-replace.png');
            this.load.image('button-cancel-replace-hover','button-cancel-replace-hover.png');
            this.load.image('banner-art','banner-art.png');
            this.load.image('banner-art-enemy','banner-art-enemy.png');
            this.load.image('button-mulligan','button-mulligan.png');
            this.load.image('button-mulligan-hover','button-mulligan-hover.png');
            this.load.image('banner-health-value-frame','banner-health-value-frame.png');
            this.load.image('banner-energy-value-frame','banner-energy-value-frame.png');
            this.load.image('field-sword-icon','field-sword-icon.png');
            this.load.image('play-field','play-field.png');
            this.load.image('card-detail-view-slot-selectable', 'card-detail-view-slot-selectable.png');
            this.load.image('card-detail-view-slot-hovered', 'card-detail-view-slot-hovered.png');
            this.load.image('card-detail-view-slot-replacable', 'card-detail-view-slot-replacable.png');
            this.load.image('card-detail-view-slot-replacable-hovered', 'card-detail-view-slot-replacable-hovered.png');
            this.load.image('overlay-background-summon','overlay-background-summon.png');
            this.load.image('overlay-background-summon-mobile','overlay-background-summon-mobile.png');
            this.load.image('overlay-background-forfeit','overlay-background-forfeit.png');
            this.load.image('overlay-background-forfeit-mobile','overlay-background-forfeit-mobile.png');
            this.load.image('enemy-turn-indicator','turn-enemy.png');
            this.load.image('your-turn-indicator','turn-your.png');
            this.load.video('defeat-screen', 'defeat-screen.webm', false, false);
            this.load.video('defeat-screen-mobile', 'defeat-screen-mobile.webm', false, false);
            this.load.video('victory-screen', 'victory-screen.webm', false, false);
            this.load.video('victory-screen-mobile', 'victory-screen-mobile.webm', false, false);
            this.load.image('init-screen', 'black-initialization-screen.png');
            this.load.image('drawing-cards-notice','drawing-cards-notice.png');
            this.load.image('you-drew-high-card','you-drew-high-card.png');
            this.load.image('enemy-drew-high-card','enemy-drew-high-card.png');
            this.load.video('high-card-winner-runes', 'highcard-winner-runes.webm', false, false);
            // this.load.setBaseURL('http://labs.phaser.io');
            this.load.setBaseURL(CONFIG.BASE_URL);
        }

        function phaserCreate()
        {
            //"this" is the scene (not the scene manager)
            mainScene = this;

             //Add the attack animations to the scene
             const SWIPE_ATTACK_KEY = 'swipe-attack';
             const SWIPE_ATTACK_FRAME_PREFIX = 'swipe-attack'; //used literally as the prefix string of the file names
             mainScene.anims.create({
                 key: SWIPE_ATTACK_KEY,
                 frameRate: 24,
                 startFrame: 0,
                 hideOnComplete: true,
                 frames: mainScene.anims.generateFrameNames(SWIPE_ATTACK_KEY,
                     {
                         prefix: SWIPE_ATTACK_FRAME_PREFIX, 
                         start: 0,
                         end: 23,
                         suffix: '.png',
                         zeroPad: 0,
                     }),
                 repeat: 0
             });

             const BITE_ATTACK_KEY = 'bite-attack';
             const BITE_ATTACK_FRAME_PREFIX = 'bite-attack'; //used literally as the prefix string of the file names
             mainScene.anims.create({
                 key: BITE_ATTACK_KEY,
                 frameRate: 24,
                 startFrame: 0,
                 hideOnComplete: true,
                 frames: mainScene.anims.generateFrameNames(BITE_ATTACK_KEY,
                     {
                         prefix: BITE_ATTACK_FRAME_PREFIX, 
                         start: 0,
                         end: 16,
                         suffix: '.png',
                         zeroPad: 0,
                     }),
                 repeat: 0
             });

            //  const FIRE_ATTACK_KEY = 'fire-attack';
            //  const FIRE_ATTACK_FRAME_PREFIX = 'fire-attack'; //used literally as the prefix string of the file names
            //  mainScene.anims.create({
            //      key: FIRE_ATTACK_KEY,
            //      frameRate: 24,
            //      startFrame: 0,
            //      hideOnComplete: true,
            //      frames: mainScene.anims.generateFrameNames(FIRE_ATTACK_KEY,
            //          {
            //              prefix: FIRE_ATTACK_FRAME_PREFIX, 
            //              start: 0,
            //              end: 23,
            //              suffix: '.png',
            //              zeroPad: 0,
            //          }),
            //      repeat: 0
            //  });

            //  const POISON_ATTACK_KEY = 'poison-attack';
            //  const POISON_ATTACK_FRAME_PREFIX = 'poison-attack'; //used literally as the prefix string of the file names
            //  mainScene.anims.create({
            //      key: POISON_ATTACK_KEY,
            //      frameRate: 24,
            //      startFrame: 0,
            //      hideOnComplete: true,
            //      frames: mainScene.anims.generateFrameNames(POISON_ATTACK_KEY,
            //          {
            //              prefix: POISON_ATTACK_FRAME_PREFIX, 
            //              start: 0,
            //              end: 23,
            //              suffix: '.png',
            //              zeroPad: 0,
            //          }),
            //      repeat: 0
            //  });

            //  const LIGHT_ATTACK_KEY = 'light-attack';
            //  const LIGHT_ATTACK_FRAME_PREFIX = 'light-attack'; //used literally as the prefix string of the file names
            //  mainScene.anims.create({
            //      key: LIGHT_ATTACK_KEY,
            //      frameRate: 24,
            //      startFrame: 0,
            //      hideOnComplete: true,
            //      frames: mainScene.anims.generateFrameNames(LIGHT_ATTACK_KEY,
            //          {
            //              prefix: LIGHT_ATTACK_FRAME_PREFIX, 
            //              start: 0,
            //              end: 23,
            //              suffix: '.png',
            //              zeroPad: 0,
            //          }),
            //      repeat: 0
            //  });

            //  const LIGHTNING_ATTACK_KEY = 'lightning-attack';
            //  const LIGHTNING_ATTACK_FRAME_PREFIX = 'lightning-attack'; //used literally as the prefix string of the file names
            //  mainScene.anims.create({
            //      key: LIGHTNING_ATTACK_KEY,
            //      frameRate: 24,
            //      startFrame: 0,
            //      hideOnComplete: true,
            //      frames: mainScene.anims.generateFrameNames(LIGHTNING_ATTACK_KEY,
            //          {
            //              prefix: LIGHTNING_ATTACK_FRAME_PREFIX, 
            //              start: 0,
            //              end: 23,
            //              suffix: '.png',
            //              zeroPad: 0,
            //          }),
            //      repeat: 0
            //  });

            //  const FROST_ATTACK_KEY = 'frost-attack';
            //  const FROST_ATTACK_FRAME_PREFIX = 'frost-attack'; //used literally as the prefix string of the file names
            //  mainScene.anims.create({
            //      key: FROST_ATTACK_KEY,
            //      frameRate: 24,
            //      startFrame: 0,
            //      hideOnComplete: true,
            //      frames: mainScene.anims.generateFrameNames(FROST_ATTACK_KEY,
            //          {
            //              prefix: FROST_ATTACK_FRAME_PREFIX, 
            //              start: 0,
            //              end: 23,
            //              suffix: '.png',
            //              zeroPad: 0,
            //          }),
            //      repeat: 0
            //  });

            const SUMMON_KEY = 'summon-atlas';
            const SUMMON_FRAME_PREFIX = 'summon'; //used literally as the prefix string of the file names
            mainScene.anims.create({
                key: SUMMON_KEY,
                frameRate: 24,
                startFrame: 0,
                hideOnComplete: true,
                frames: mainScene.anims.generateFrameNames(SUMMON_KEY,
                    {
                        prefix: SUMMON_FRAME_PREFIX, 
                        start: 1,
                        end: 20,
                        suffix: '.png',
                        zeroPad: 0,
                    }),
                repeat: 0
            });

            constructPlayMat();

            setGameLoading(false);
        }

        function stopTimer(secondsToDisplay){
            var timerTextToDisplay = secondsToDisplay ? secondsToDisplay.toString().substr(0,4) + ".0" : "00.00";
            timerText && timerText.setText(timerTextToDisplay);
            timerEvent = null;
            redrawButtonsOnTimerStart = true;
        }

        function startTimerInSeconds(seconds, completionCallback){
            timerValue_s = seconds;
            timerEvent = mainScene.time.addEvent({ delay: seconds*1000, loop: false });
            timerEventCompletionCallback = completionCallback;
        }

        function isThereTimeOnTheClock(){
            return !!timerEvent;
        }

        var redrawButtonsOnTimerStart = true;
        function phaserUpdate()
        {
            if (gameManager && gameManager.isGameOver()){
                timerText && timerText.setText("00.00");
                timerEvent = null;
                buttonManager.redrawButtons();
                theGame.destroy(true, false);
                setIsGameLoaded(false);

                //Set flag to call API and update the downForMaintenance inside the client's local store
                setReloadDownForMaintenanceFlag(true);
                return;
            }

            if (timerEvent){

                if (redrawButtonsOnTimerStart){
                    buttonManager.redrawButtons();
                    redrawButtonsOnTimerStart = false;
                }

                var percentage = 1-timerEvent.getProgress();
                var secondsLeft = timerValue_s*percentage;

                timerText.setText(secondsLeft.toString().substr(0,4));

                //When the timer is done, kill the object so this update method stops re-setting the timer text
                if (percentage === 0){
                    timerEvent = null;
                    timerText.setText("00:00");

                    //Disable/reset a bunch of actions because time is up
                    redrawButtonsOnTimerStart = true;
                    buttonManager.redrawButtons();
                    clearSummonPopover();

                    //If a callback was requested on timer completion, trigger it
                    timerEventCompletionCallback && timerEventCompletionCallback();
                }
            }
            
        }

        const handleGameCreate = () =>{
            if (isDeckValid)
                setGameLoading(true);
        }

        const renderDownForMaintenance = () => {
            return (
                <Grid container style={{  justifyContent:'center', alignItems:'center', height:'100%'}}>
           
                        <Headline fontSize='40pt'>Coming Soon!</Headline>
           
                </Grid>
            )
        }

        const renderComingSoon = () => {
            return (
                <Grid container style={{  justifyContent:'center', alignItems:'center', height:'100%'}}>
           
                        <img alt="Coming Soon" src={getArt("ComingSoon")} width={872} height={385} display={"block"} />
           
                </Grid>
            )
        }

        const renderGameButtons = () => {
            if (isGameLoaded === false && gameLoading === false) {
                return (
                    <Grid container style={{ justifyContent:'center', alignItems:'center', height:'100%'}}> 
                       
                        <Grid id="renderButtonsGrid" item style={{ justifyContent:'center', alignItems:'center', textAlign:'center'}}>
                        {CONFIG.DEBUG_MODE && 
                        <Grid id="renderButtonsGrid" item >
                          <input type="text" defaultValue={providedUserId} onChange={handleProvidedUserIdChange}/>
                        </Grid>
                        }
                            <Grid item  style={{height:'64px', width:'320px', textAlign:'center', alignItems:'center', margin:'auto', marginBottom: 50}}>
                                {authState.truncatedAddress?
                                    
                                    <ConnectButton style={{width: 200, height: 64}}
                                    onClick={()=>logout()}
                                   
                                    onMouseOver = {() => {
                                        cbtDispatch({ type: 'updateButtonText', payload: {displayText:'Disconnect?'}})
                                    }}
                                    onMouseLeave = {() => {
                                        cbtDispatch({ 
                                            type: 'updateButtonText', 
                                            payload: {
                                                displayText: authState.userName? `${truncateUsername()} ${authState.truncatedAddress}`: authState.truncatedAddress 
                                            }})
                                    }}
                                    >
                                    
                                            {connectionState && connectionState.connectButtonText && 
                                                connectionState.connectButtonText
                                            }
                                    
                                    </ConnectButton>
                            
                                    :

                                    <ConnectButton style={{width: 200, height: 50}}
                                    onClick={()=>login()}
                                   
                                    >
                                        Connect
                                    </ConnectButton>
                                }
                                
                            </Grid>
                            
                            <Grid item style={{height:'80px', width:'368px', textAlign:'center', alignItems:'center', margin:'auto'}}>
                                <DeckSelector isDebugMode={CONFIG.DEBUG_MODE} disabled={authState.truncatedAddress?false:true} />
                            </Grid>

                            <div className="flexButtonSeparator" style={{ height: height>600?'20px':'10px' }} />
                            
                            <Grid item style={{margin:'auto', height:'100px', width:'400px', textAlign:'center'}}>
                                <SvgButton 
                                    displayText='Start'
                                    onClick={() => handleGameCreate()}
                                    disabled={!isDeckValid}
                                    fontSize='36pt'
                                    buttonWidth={'400px'} 
                                    buttonHeight={'100px'}/>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            } else {
                return (
                    <div style={{ display:'flex', flexDirection:'column', margin:'auto', padding:'auto', height:'100%'}}>

                        { isMatchMaking &&
                            <div  
                                style={{
                                    height:'100px', width:'400px', 
                                    textAlign:'center', zIndex:790, 
                                    position:'fixed', top:'70%', left:'50%',
                                    marginTop:'-50px', marginLeft:'-200px'
                                }}
                            >

                                <SvgButton 
                                    displayText='Cancel Search'
                                    onClick={() => cancelMatchMaking()}
                                    disabled={false}
                                    fontSize='22pt'
                                    buttonWidth={400} 
                                    buttonHeight={100}/>
                            </div>
                        }

                        <div  style={{  zIndex:760, margin:'auto', padding:'auto', width:width*.95, height:height*.95}} id='phaserParent'></div>
                    </div>
                )
            }
        }

        const renderPage = () => {
            if (downForMaintenance && !CONFIG.DEBUG_MODE){
                return renderDownForMaintenance()
            }

            return renderGameButtons()
        }

        return (
            <div style={{ width:'100vw', height:( (isGameLoaded === false) && (gameLoading === false)) ? 'calc(100vh - 65px)':'100vh'}}>
            <BackgroundManager sourceImage={getArt('GamePageBG')}  blur='4px' opacity='0.4' />
            {
                
                renderPage()

            }
            </div>
        )
}

export default GamePage
