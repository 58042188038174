import React, { } from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import styled from 'styled-components'
import nextId from "react-id-generator";
import { rarityColors } from '../helpers/GlobalStore';
import ArtLoader from "../assets/CardArtProvider"

const StyledCard = styled(Grid) `
    position: relative;
    display: inline-block;
    height:${(props)=> (props.theme.height? props.theme.height: "100%")};
    width: ${(props)=> (props.theme.width? props.theme.width: "100%")};
    transform-style: preserve-3d;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    z-index: ${(props)=> (props.theme.zIndex? props.theme.zIndex: 500)};
    

    &::after{
        content: "";

        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    ${(props)=>props.theme.hover? 
    `&:hover{
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
        z-index:650;
        
    }
    &::after{
        opacity: 1;
        
        
    }`:
    ''
    }
   
    
    
`
/*
  background: ${(props)=>  props.theme.cardBack? props.theme.cardBack:'none'};
  add to above .back if it doesnt work.
 
*/
const StyledImage = styled(Grid) `

    z-index: 650;
    height:inherit;
    width:inherit;
    
    background-size: 100% 100%;
    filter: brightness(.8);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &::after{
        content: "";

        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
   
    &:hover{

        -webkit-filter: grayscale(brightness(1.2));
        filter: brightness(1.2);
    }
    &::after{
        opacity: 1;
    }

`

const StyledCardContainer = ({artName, imgName, rarity, count, onClick, hover, clickReveal, zIndex, width, height}) => {

    const styles = useStyles();
    const { getArt } = ArtLoader();

    return (
        <div  key={nextId('cardP-')} className = {styles.image} >
            <StyledCard 
            key={nextId('cardP-')} 
            theme={{rarity: rarity, owned: count, hover: hover, clickReveal: clickReveal, zIndex:zIndex, width: width, height: height}} 
            className = {(hover) ? styles.cursorHover :styles.cursorNoHover  }
            >
                    <StyledImage
                    key={nextId('cardP-')}
                    style={{
                        backgroundImage: imgName?  `url(${getArt(imgName)})`:'none',
                    }}
                    theme={{owned: count, artName: getArt(imgName), clickReveal: clickReveal, zIndex:zIndex}}
                    onClick={(onClick !== null && onClick !== undefined) ? onClick : ()=>{}}
                    />
            
            </StyledCard>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%', 
        height: '100%',
        backgroundColor:'transparent',
        background:'transparent'
    },

    cursorHover: {
        cursor: "pointer"
    },

    cursorNoHover:{
        cursor: "default"
    }
  }));

export default StyledCardContainer
