import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import { useHowApiClient } from '../helpers/HowApiClient';
import { useStore } from '../helpers/GlobalStore'
import CreatureToolbar from '../components/CreatureToolbar';
import nextId from "react-id-generator";
import CompendiumUtil from "../pages/compendium/CompendiumUtil"
import StyledCardContainer from "./StyledCardContainer"; 
import CardPopover from "./CardPopover";
import { rarityColors } from "../helpers/GlobalStore"
import { useWindowDimensions } from "../helpers/windowDimentionProvider";

const InventoryCardsComponent = () => {

    const styles = useStyles();
    var compendiumUtil = CompendiumUtil();

    const { getCardCounts } = useHowApiClient();
    const { width, height } = useWindowDimensions();
    const { userRoles, compendium, setCompendium, setChildUpdateNeeded } = useStore();

    const [nameSearch, setNameSearch] = useState("")
    const [seasonSearch, setSeasonSearch] = useState(0)
    const [editionSearch, setEditionSearch] = useState(0)
    const [filter, setFilter] = useState("all")
    const [sortBy, setSortBy] = useState("name ascending")

    const [popover, setPopover] = useState(false)
    const [selectedCard, setSelectedCard] = useState()
    const [cardOverlayVisible, setCardOverlayVisible] = useState(false)
    const [descriptionVisible, setDescriptionVisible] = useState(false)


    const loadCounts = async () => {
         await getCardCounts()
    }

    useEffect(() => {
        if (compendium.size === 0){         
            setCompendium(compendiumUtil.getCompendiumDictionary())
        }
    }, [compendium, setCompendium, compendiumUtil])

    useEffect(() => {
        if(userRoles === '') {
        }
        else{
            loadCounts() 
            setChildUpdateNeeded(false);
        }
    }, [userRoles])


    const ordinal_suffix_of = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    const handleCardClick = (card) => {
        setSelectedCard(card)
        setPopover(true)
    }

    const handleCardClose = () => {
        setSelectedCard(undefined)
        setPopover(false)
        setDescriptionVisible(false)
    }

    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter);
    };

    const toggleCardSide = () => {
        setDescriptionVisible(~descriptionVisible)
    }

    const popoverContents = () => {

        let overlayImage = null
        let overlayImageMobile = null

        
        if(selectedCard.cardMetaData.type === "MONSTER"){

            if(selectedCard.cardMetaData.maxAttacksPerTurn > 0){
                //use attacker overlay for attacker champions
                overlayImage = 'card-description-attacker-overlay-widescreen.png'
                overlayImageMobile = 'card-description-attacker-overlay-mobile.png'
            }
            else if(selectedCard.cardMetaData.maxAttacksPerTurn === 0){
                //use defender overlay for defender champions
                overlayImage = 'card-description-defender-overlay-widescreen.png'
                overlayImageMobile = 'card-description-defender-overlay-mobile.png'
            }
            else{
                //unknown monster type, use attacker overlay as default
                overlayImage = 'card-description-attacker-overlay-widescreen.png'
                overlayImageMobile = 'card-description-attacker-overlay-mobile.png'
            }

        }
        else if(selectedCard.cardMetaData.type === "ITEM"){
            //item or utility cards
            overlayImage = 'card-description-item-overlay-widescreen.png'
            overlayImageMobile = 'card-description-item-overlay-mobile.png'
        }

        return (

            <Grid 
            container
            // onClick={()=>toggleCardSide()}
            id="ov-data-grid"
            style={{
                justifyContent:'center', 
                alignItems:"center", 
                maxWidth:width>height?'75vw':'75vw', 
                height:width>height?'75vh':'50vh', 
                flexShrink: 1,
                aspectRatio:'2.5/3.5'
            }}
            >

                <div
                id="og-card-div"  
                style={{
                    display:descriptionVisible?'none':'flex',
                    flexDirection:'column', 
                    height:width>=height?'95%':'auto', 
                    width: width>=height?"auto":'95%'}} 
                className = {styles.imageOriginal}>

                    <StyledCardContainer
                    imgName = {selectedCard.cardId}
                    rarity = {selectedCard.rarityId}
                    count = {1}
                    zIndex={800}
                    />
                </div>
            {/* 
                <div
                id="ov-desc-text"  
                style={{
                    display:descriptionVisible?'flex':'none',
                    flexDirection:'column',
                    zIndex:'805',
                    color:'white',
                    height:width>=height?'85%':'auto', 
                    width: width>=height?"inherit":'85%',
                    alignItems:'center',
                    aspectRatio: "2.5/3.5", 
                    overflowY:'scroll',
                    margin:'5%'
                    }}>
                        <Typography variant='h6' style={{margin:'5%', alignItems:'center', justifyContent:'center'}}>
                            {selectedCard.description}
                        </Typography>
                    
                   
                </div>
           
                <div
                    id="ov-backdrop-img"
                    style={{
                        position:'absolute',
                        backgroundImage: width >= height?`url(card-description-backdrop-widescreen.png)`:`url(card-description-backdrop-mobile.png)`,
                        backgroundSize:'100% 100%',
                        height:width>=height?'100%':'auto',
                        width:width>=height?'inherit':'100%',
                        aspectRatio: "2.5/3.5",
                        opacity: 0.6,
                        filter: 'blur(4px)'                    
                    }}
                />
                <div 
                id="ov-desc-img"
                    style={{
                        position:'absolute',
                        backgroundImage: width >= height?`url(${overlayImage})`:`url(${overlayImageMobile})`,
                        backgroundSize:'100% 100%',
                        height:width>=height?'100%':'auto',
                        width:width>=height?'inherit':'100%',
                        zIndex:'850',
                        display: (cardOverlayVisible && !descriptionVisible)?'inline':'none',
                        aspectRatio: "2.5/3.5",
                        opacity: 0.6,
                        filter: 'blur(4px)'
                    }}
                /> 
            */}
            </Grid>

        )
    }


    const checkFilters = (item) => {
        let nameFlag = false
        let seasonFlag = false
        let editionFlag = false

        const season = parseInt(seasonSearch)
        const edition = parseInt(editionSearch)

        if(nameSearch === "")
            nameFlag = true

        else if(nameSearch !== "" && item.name.toLowerCase().includes(nameSearch.toLowerCase()))
            nameFlag = true

        if(season === 0 || season === NaN || seasonSearch === "" || seasonSearch === undefined)
            seasonFlag = true

        else if(season !== 0 && season === item.season)
            seasonFlag = true

        if(edition === 0 || edition === NaN || editionSearch === "" || editionSearch === undefined)
            editionFlag = true

        else if(edition !== 0 && edition === item.edition)
            editionFlag = true

        if(nameFlag && seasonFlag && editionFlag)
            return true

        else return false
    }

    const getCardBackByRarityId = (rarityId) => {
        switch(rarityId) {
            case 1: // LEGENDARY
                return "legendary-card-back";
            case 2: // EPIC
                return "epic-card-back";
            case 4: // RARE
                return "rare-card-back";
            case 8: // UNCOMMON
                return "uncommon-card-back";
            case 16: // COMMON
                return "common-card-back";
            default: 
                return "common-card-back";
        }
    }

    const renderCardItems = () => {

        let elements = []
        let data = []

        if(filter === "only owned"){
            compendium.values().forEach(card => {
                if ((card.count > 0) && checkFilters(card))
                    data.push(card)
            })
        } else if(filter === "not owned"){
            compendium.values().forEach(card => {
                if ((card.count === 0 || userRoles === "" || userRoles === undefined) && checkFilters(card))
                    data.push(card)
            })
        } else if (filter === "all"){
            compendium.values().forEach(card => {
                if (checkFilters(card))
                    data.push(card)
            })
        }
            
        if(sortBy === "rarity ascending") {
            data = data.sort((a,b) => {
                if(a.rarityId < b.rarityId )
                    return 1

                else if(a.rarityId > b.rarityId )
                    return -1

                else return 0
            })
        }

        else if(sortBy === "rarity descending") {
            data = data.sort((a,b) => {
                if(a.rarityId > b.rarityId )
                    return 1

                else if(a.rarityId < b.rarityId )
                    return -1

                else return 0
            })
        }

        else if(sortBy === "name ascending") {
            data = data.sort((a,b) => {
                if(a.name.toLowerCase() > b.name.toLowerCase() )
                    return 1

                else if(a.name.toLowerCase() < b.name.toLowerCase() )
                    return -1
            })
        }

        else if(sortBy === "name descending") {
            data = data.sort((a,b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase() )
                    return 1

                else if(a.name.toLowerCase() > b.name.toLowerCase() )
                    return -1
            })
        }

        else if(sortBy === "count descending"){
            data = data.sort((a,b) => {
                if(a.count < b.count)
                    return 1

                else if(a.count > b.count)
                    return -1

                else return 0
            })
        }

        else if(sortBy === "count ascending"){
            data = data.sort((a,b) => {
                if(a.count > b.count)
                    return 1

                else if(a.count < b.count)
                    return -1

                else return 0
            })
        }

        data.forEach(card => {
            if (card.gamePlayable && card.rarityId !== 32)
            {
                // Show the actual card
                if (card.reveal) {
                    elements.push(
                        <Grid item  key={nextId('CardContainer-')} className = {styles.cardContainer}>
                            <StyledCardContainer
                            imgName = {card.cardId}
                            rarity = {card.rarityId}
                            count = {card.count}
                            onClick = {() => handleCardClick(card)}
                            hover
                            zIndex={800}
                            />
    
    
                            {userRoles !== "" && userRoles !== undefined &&
                            <Grid item>
                                <Typography variant = "h6" style = {{textAlign: "center", color:'white'}}>
                                    {`Owned:  x${card.count}`}
                                </Typography>
                            </Grid>
                            }
                        </Grid>
                    )
                }
                // Show the card back until we want to reveal the actual card
                else {
                    elements.push(
                        <Grid item  key={nextId('CardContainer-')} className = {styles.cardContainer}>
                            <StyledCardContainer
                            imgName = {'DeckBack'}
                            rarity = {card.rarityId}
                            count = {0}
                            hover
                            zIndex={800}
                            />
                        </Grid>
                    )
                }
            }
        })

        return elements
    }

    return (
        <Grid container spacing={2} style={{justifyContent:'center', alignItems:'flex-end'}} >

            <Grid item>
                <FormControl fullWidth style = {{width: "auto"}}>
                    <div className = {styles.textWhite}>Sort By:</div>
                    <Select
                        value={sortBy}
                        variant = "outlined"
                        label="Sort By"
                        onChange={(e) => setSortBy(e.target.value)}
                        className = {styles.filterFieldProp}
                        inputProps = {{classes: {
                            icon: styles.textFieldLabel,
                        }}}
                    >
                        <MenuItem value={"name ascending"}>Name: Ascending</MenuItem>
                        <MenuItem value={"name descending"}>Name: Descending</MenuItem>
                        <MenuItem value={"rarity ascending"}>Rarity: Ascending</MenuItem>
                        <MenuItem value={"rarity descending"}>Rarity: Descending</MenuItem>
                        <MenuItem value={"count ascending"}>Count: Ascending</MenuItem>
                        <MenuItem value={"count descending"}>Count: Descending</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item>
                <CreatureToolbar
                setNameReturn = {setNameSearch}
                setSeasonReturn = {setSeasonSearch}
                setEditionReturn = {setEditionSearch}
                />
            </Grid>

            {/* <Grid item>
                <FormControl fullWidth style = {{width: "14ch"}}>
                    <div className = {styles.textWhite}>Show:</div>
                    <Select
                        value={filter}
                        variant = "outlined"
                        label="All"
                        onChange={(e) => handleFilterChange(e.target.value)}
                        className = {styles.filterFieldProp}
                        inputProps = {{classes: {
                            icon: styles.textFieldLabel
                        }}}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="only owned">Owned</MenuItem>
                        <MenuItem value="not owned">Not Owned</MenuItem>
                    </Select>
                </FormControl>
            </Grid> */}

        {/* 
            <Grid item>
                <FormControl fullWidth style = {{width: "14ch"}}>
                    <div className = {styles.textWhite}>Card Overlay:</div>
                    <Select
                        value={cardOverlayVisible}
                        variant = "outlined"
                        label={cardOverlayVisible?'On':'Off'}
                        onChange={(e) => setCardOverlayVisible(e.target.value)}
                        className = {styles.filterFieldProp}
                        inputProps = {{classes: {
                            icon: styles.textFieldLabel
                        }}}
                    >
                        <MenuItem value={true}>On</MenuItem>
                        <MenuItem value={false}>Off</MenuItem>
                    </Select>
                </FormControl>
            </Grid> 
        */}
            {compendium && compendium.length !== 0 &&
            <Grid item xs={12} >
                <Grid container style={{justifyContent:'center', alignItems:'center'}}>
                    {renderCardItems()}
                </Grid>
            </Grid>
            }

            {selectedCard !== undefined && selectedCard !== null &&
            <CardPopover
            contents = {popoverContents()}
            rarity = {selectedCard.rarityId}
            popover = {popover}
            closePopover = {handleCardClose}
            dropShadow = {(popover)}
            hideBackground
            />
            }

        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    imageOriginal: {
        aspectRatio: "2.5/3.5",
        margin: 20
    },

    cardCounterIcon: {
        width: 20,
        height: 30
    },

    cardContainer: {
        width: 200,
        height: 280,
        margin: 25
    },

    popContainer: {
        backgroundColor:'rgba(0,0,0,0)',
        color:'white',
    },

    textCentered: {
        textAlign: "center"
    },

    textLeft: {
        textAlign: "left"
    },

    textWhite: {
        color: "white"
    },

    popDescription: {
        marginBottom: 10,
    },

    checked: {
    },

    filterFieldProp: {
        height: '42px',
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    textFieldLabel: {
        fill: "white",
        color: "white"
    },
    typographyText:{
        fontSize:'14pt'
    }

}))

export default InventoryCardsComponent;