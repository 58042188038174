import React from "react";
import timelineEntries from './timeLineEntries.json'
import { Parallax } from "react-scroll-parallax";

const TimelineItem = ({ data, speedMult }) => (


            <div className="timeline-item-content" >
          
                <time>{data.date}</time>
                <p>{data.text}</p>
                {data.link && (
                    <a
                        href={data.link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {data.link.text}
                    </a>
                )}
        
            </div>

   
);

export const Timeline = ({}) =>(

    timelineEntries.length > 0 && (
        <div className="timeline-container">
            {timelineEntries.map((data, idx) => (
         
                <TimelineItem data={data} key={idx} speedMult={idx}/>
     
            ))}
        </div>
    )

)
