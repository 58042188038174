import React, { useState } from 'react';
import { Grid, Typography } from "@material-ui/core"
import { useWindowDimensions } from "../helpers/windowDimentionProvider"
import StyledCardContainer from './StyledCardContainer';

const CardBlowUpWidget = ({card}) => {

    const {width, height} = useWindowDimensions();
    const [descriptionVisible, setDescriptionVisible] = useState(false)
    const [cardOverlayVisible, setCardOverlayVisible] = useState(true)
    let overlayImage = null
    let overlayImageMobile = null

    const toggleCardSide = () => {
        setDescriptionVisible(~descriptionVisible)
    }
    
    if(card.cardMetaData.type === "MONSTER"){

        if(card.cardMetaData.maxAttacksPerTurn > 0){
            //use attacker overlay for attacker champions
            overlayImage = 'card-description-attacker-overlay-widescreen.png'
            overlayImageMobile = 'card-description-attacker-overlay-mobile.png'
        }
        else if(card.cardMetaData.maxAttacksPerTurn === 0){
            //use defender overlay for defender champions
            overlayImage = 'card-description-defender-overlay-widescreen.png'
            overlayImageMobile = 'card-description-defender-overlay-mobile.png'
        }
        else{
            //unknown monster type, use attacker overlay as default
            overlayImage = 'card-description-attacker-overlay-widescreen.png'
            overlayImageMobile = 'card-description-attacker-overlay-mobile.png'
        }

    }
    else if(card.cardMetaData.type === "ITEM"){
        //item or utility cards
        overlayImage = 'card-description-item-overlay-widescreen.png'
        overlayImageMobile = 'card-description-item-overlay-mobile.png'
    }

    return (

        <Grid 
        container
        //onClick={()=>toggleCardSide()}
        id="ov-data-grid"
        style={{
            justifyContent:'center', 
            alignItems:"center", 
            maxWidth:width>height?'75vw':'75vw', 
            height:width>height?'75vh':'50vh', 
            aspectRatio:width>=height?'1/1':'2.5/3.5'}}>

            <div
            id="og-card-div"  
            style={{
                display:descriptionVisible?'none':'flex',
                flexDirection:'column', 
                height:width>=height?'85%':'auto', 
                width: width>=height?"auto":'85%',
                aspectRatio: "2.5/3.5",
                margin: 20}}>

                <StyledCardContainer
                imgName = {card.cardId}
                rarity = {card.rarityId}
                count = {1}
                zIndex={800}
                />
            </div>
            <div
            id="ov-desc-text"  
            style={{
                display:descriptionVisible?'flex':'none',
                flexDirection:'column',
                zIndex:'805',
                color:'white',
                height:width>=height?'85%':'auto', 
                width: width>=height?"inherit":'85%',
                aspectRatio: "2.5/3.5", 
                overflowY:'scroll',
                margin:'5%'}}>
                    <Typography variant='h5'>
                        {card.description}
                    </Typography>
            </div>
            <div
            id="ov-backdrop-img"
            style={{
                position:'absolute',
                backgroundImage: width >= height?`url(card-description-backdrop-widescreen.png)`:`url(card-description-backdrop-mobile.png)`,
                backgroundSize:'100% 100%',
                height:width>=height?'100%':'auto',
                width:width>=height?'inherit':'100%',
                aspectRatio: "2.5/3.5",                    
                }}
            />
            <div 
            id="ov-desc-img"
            style={{
                position:'absolute',
                backgroundImage: width >= height?`url(${overlayImage})`:`url(${overlayImageMobile})`,
                backgroundSize:'100% 100%',
                height:width>=height?'100%':'auto',
                width:width>=height?'inherit':'100%',
                zIndex:'850',
                display: (cardOverlayVisible && !descriptionVisible)?'inline':'none',
                aspectRatio: "2.5/3.5",
                }}
            />
        </Grid>
    )
}

export default CardBlowUpWidget;