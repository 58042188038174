import GenericButton from './GenericButton'
import Slot from './Slot'
import Card from './Card'

/*
    position: {
        x: x_value, 
        y: y_value, 
        z: depth
    }
    //{x: slot.x, y: slot.y, z: 10}
*/
const PlayerBanner = (scene, bannerArt, scale, position, onCardClick, onMulliganClick, cardScale) => {
    
    const bannerId = 'player_banner';

    const CARD_ART_SCALE = 0.26;

    //add the banner background art
    var bannerArtImage = scene.add.image(position.x, position.y, bannerArt)
        .setDepth(position.z)
        .setOrigin(0.5)
        .setScale(scale);

    const bannerWidth = bannerArtImage.width * scale;
    const bannerHeight = bannerArtImage.height * scale;

    const leftEdgeOfBanner = position.x - bannerWidth/2;
    const topEdgeOfBanner = position.y - bannerHeight/2;

    //Put in mulligan button
    var isMulliganDisabled = false;
    const mulliganLocationX = leftEdgeOfBanner + bannerWidth*0.07;
    const mulliganLocationY = topEdgeOfBanner + bannerHeight*0.80;

    var mulliganButton = GenericButton(
        'mulligan_button',
        scene,
        {
            artName: 'button-mulligan',
            artNameOnHover: 'button-mulligan-hover'
        },
        1.0, 
        {
            x: mulliganLocationX,
            y: mulliganLocationY,
            z: bannerArtImage.depth + 1
        },
        onMulliganClick
    );
    mulliganButton.invisiblize();

    //Put in card slots
    var cardSlot1 = Slot('player_hand_card_slot_1', scene, 'card-detail-view-slot', 0.27 * cardScale, {x: leftEdgeOfBanner + bannerWidth*0.20, y: topEdgeOfBanner + bannerHeight*0.5, z: 3});
    var cardSlot2 = Slot('player_hand_card_slot_2', scene, 'card-detail-view-slot', 0.27 * cardScale, {x: leftEdgeOfBanner + bannerWidth*0.35, y: topEdgeOfBanner + bannerHeight*0.5, z: 3});
    var cardSlot3 = Slot('player_hand_card_slot_3', scene, 'card-detail-view-slot', 0.27 * cardScale, {x: leftEdgeOfBanner + bannerWidth*0.50, y: topEdgeOfBanner + bannerHeight*0.5, z: 3});
    var cardSlot4 = Slot('player_hand_card_slot_4', scene, 'card-detail-view-slot', 0.27 * cardScale, {x: leftEdgeOfBanner + bannerWidth*0.65, y: topEdgeOfBanner + bannerHeight*0.5, z: 3});
    var cardSlot5 = Slot('player_hand_card_slot_5', scene, 'card-detail-view-slot', 0.27 * cardScale, {x: leftEdgeOfBanner + bannerWidth*0.80, y: topEdgeOfBanner + bannerHeight*0.5, z: 3});

    //Put in health/energy indicators
    const healthLocationX = leftEdgeOfBanner + bannerWidth*0.96;
    const healthLocationY = topEdgeOfBanner + bannerHeight*0.16;
    const energyLocationX = leftEdgeOfBanner + bannerWidth*0.96;
    const energyLocationY = topEdgeOfBanner + bannerHeight*0.83;

    var healthFrame = scene.add.image(healthLocationX, healthLocationY, 'banner-health-value-frame')
        .setDepth(bannerArtImage.depth + 1).setOrigin(0.5).setScale(0.5 * cardScale);
    var energyFrame = scene.add.image(energyLocationX, energyLocationY, 'banner-energy-value-frame')
        .setDepth(bannerArtImage.depth + 1).setOrigin(0.5).setScale(0.35 * cardScale);

    var healthLabel = scene.add.bitmapText(healthLocationX-2, healthLocationY-6, 'gothic', "XX", 40 * cardScale).setOrigin(0.5).setDepth(healthFrame.depth + 1);
    var energyLabel = scene.add.bitmapText(energyLocationX, energyLocationY-2, 'gothic', "XX", 40 * cardScale).setOrigin(0.5).setDepth(energyFrame.depth + 1);

    //actually store the values in the label object:
    healthLabel.healthValue = 10;
    energyLabel.energyValue = 0;

    //Forfeit capture
    var forfeited = false;

    function hasEnoughEnergy(energySpendRequest){
        return (energySpendRequest <= energyLabel.energyValue);
    }

    function isDead(){
        return (healthLabel.healthValue <= 0 || forfeited);
    }

    function setForfeited(){
        forfeited = true;
    }

    function setHealth(health){
        healthLabel.healthValue = health;

        var healthLabelStr = healthLabel.healthValue;
        healthLabel.setText(healthLabelStr);
    }

    function setEnergy(energy){
        energyLabel.energyValue = energy;

        //Update the energy text
        var energyLabelStr = energyLabel.energyValue;
        energyLabel.setText(energyLabelStr);
    }

    function hasEmptySlot(){
        return cardSlot1.isEmpty() || cardSlot2.isEmpty() || cardSlot3.isEmpty() || cardSlot4.isEmpty() || cardSlot5.isEmpty();
    }

    //Hide the mulligan button forever, the assumption is that the caller has already taken care of removing and adding cards
    function disableMulligan(){
        !isMulliganDisabled && mulliganButton.invisiblize();

        isMulliganDisabled = true;
    }

    //Show the mulligan button the very first time it is our turn and no other time
    function enableMulligan(){
        if (!isMulliganDisabled && !mulliganButton.isVisible()){
            mulliganButton.visiblize();
        }
    }

    function createCard(cardObject){
        var card = Card(cardObject.cardId, scene, 'cards', cardObject.artName, null, cardObject.cardMetaData, null);
        card.setParentContainerId(bannerId);
        card.setCallback(onCardClick.bind(null, card));

        return card;
    }

    function placeCard(card){
        var emptySlot;
        console.log('placing card: ', card);

        if (cardSlot1.isEmpty()){
            emptySlot = cardSlot1;
        } else if (cardSlot2.isEmpty()){
            emptySlot = cardSlot2;
        } else if (cardSlot3.isEmpty()){
            emptySlot = cardSlot3;
        } else if (cardSlot4.isEmpty()){
            emptySlot = cardSlot4;
        } else if (cardSlot5.isEmpty()){
            emptySlot = cardSlot5;
        } else {
            console.error("Attempted to place card into player banner but there's no open spots.");
            return;
        }

        //Draw the card and then place it in the first empty slot
        card.draw(CARD_ART_SCALE * cardScale, {x: emptySlot.position.x, y: emptySlot.position.y, z: 10}, false);
        emptySlot.placeObject(card);
    }

    function removeCardWithId(cardIdToRemove){
        var card1 = cardSlot1.getObject();
        var card2 = cardSlot2.getObject();
        var card3 = cardSlot3.getObject();
        var card4 = cardSlot4.getObject();
        var card5 = cardSlot5.getObject();

        if (card1 && (card1.cardMetaData.uniqueId === cardIdToRemove)){
            cardSlot1.destroyObject();
        } else if (card2 && (card2.cardMetaData.uniqueId === cardIdToRemove)){
            cardSlot2.destroyObject();
        } else if (card3 && (card3.cardMetaData.uniqueId === cardIdToRemove)){
            cardSlot3.destroyObject();
        } else if (card4 && (card4.cardMetaData.uniqueId === cardIdToRemove)){
            cardSlot4.destroyObject();
        } else if (card5 && (card5.cardMetaData.uniqueId === cardIdToRemove)){
            cardSlot5.destroyObject();
        } else {
            console.error("Couldn't find card to remove from player banner with uniqueId: " + cardIdToRemove);
        }
    }

    function getHealthFramePosition(){
        return {x: healthLocationX, y: healthLocationY};
    }

    return {
        bannerId: bannerId,
        disableMulligan: disableMulligan,
        enableMulligan: enableMulligan,
        hasEmptySlot: hasEmptySlot,
        createCard: createCard,
        placeCard: placeCard,
        removeCardWithId: removeCardWithId,
        hasEnoughEnergy: hasEnoughEnergy,
        isDead: isDead,
        setForfeited: setForfeited,
        setHealth: setHealth,
        setEnergy: setEnergy,
        getHealthFramePosition: getHealthFramePosition
    };

}

export default PlayerBanner;
