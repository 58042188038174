import styled from "styled-components"



export const Star = ({starColor, id}) => {
    const fillString = `url(#radial-gradient-${id})`
    const gradientIdString = `radial-gradient-${id}`

    return(

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" >
            <defs>
                <radialGradient id={gradientIdString} cx="50" cy="50" fx="50" fy="50" r="50" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={starColor} stopOpacity='.75'/>
                    <stop offset=".4" stopColor={starColor} stopOpacity=".45"/>
                    <stop offset=".8" stopColor={starColor} stopOpacity="0"/>
                </radialGradient>
            </defs>
            <g id="glow">
                <circle  cx="50" cy="50" r="50" fill={fillString}/>
            </g>
            <g id="star">
                <path id="star-path" fill={starColor} d="m0,49.45c21.29-1.85,46.83-2.78,46.83-2.78,0,0,.61-21.92,2.72-46.67,2.23,24.69,2.72,46.67,2.72,46.67,0,0,25.91.93,47.14,2.78-21.27,1.89-47.14,2.89-47.14,2.89,0,0-.49,22.39-2.72,47-2.1-24.61-2.72-47-2.72-47,0,0-25.45-.99-46.83-2.89Z"/>
            </g>
        </svg>
    )
}
