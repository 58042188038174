import React from "react";
import { SocialIcon } from 'react-social-icons';
import { Grid } from '@material-ui/core';

const SocialMediaFooter = () => {
    return (
        <Grid
            className='footerStyles' 
            container 
            id="bottom-row"
            style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item style={{ marginRight: "15px", justifyContent: 'center', alignItems: 'center' }}>
                <SocialIcon url="" className="socialIcon" network='telegram'/>
            </Grid>
            <Grid item style={{ marginRight: "15px", justifyContent: 'center', alignItems: 'center' }}>
                <SocialIcon url="" className="socialIcon"  network='twitter'/>
            </Grid>
            <Grid item style={{ justifyContent: 'center', alignItems: 'center' }}>
                <SocialIcon url="" className="socialIcon" network='medium'/>
            </Grid>
        </Grid>
    )
}

export default SocialMediaFooter; 
