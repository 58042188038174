import detectEthereumProvider from '@metamask/detect-provider';
import { ethers, Contract } from 'ethers';
import MediaToken from './abis/MediaToken.json';
import PaymentManager from './abis/PaymentManager.json'
import ClaimManager from './abis/ClaimsManager.json'
import WinningsManager from './abis/ClaimsManager.json'
import PromoManager from './abis/PromoManager.json'
import { 
    mediaTokenAddresses, 
    paymentManagerAddresses,
    claimManagerAddresses,
    winningsManagerAddresses,
    promoManagerAddresses
} from './addresses'
import CONFIG from '../config/config'; 

//Connects to the metamask extension.
export const getContract = (contractAddresses, contractAbi) => new Promise(async (resolve, reject) => {
    let provider = await detectEthereumProvider();

    if (provider) {

        const accts = await provider.request({ method: 'eth_requestAccounts' });

        let networkId = await provider.request({ method: 'net_version' });

        if(!CONFIG.SUPPORTED_CHAINS.values().some( chain => chain.chainId === networkId.toString())){
            networkId = 1
        }

        let localProvider = new ethers.providers.Web3Provider(provider);
        const signer = localProvider.getSigner();

        //The json object of the MediaToken contract that is created in the contracts folder when truffle compiling the solidity project.
        //Contains the address of the contract, the abi (holds all functions and members of media token), and the signer.
        const contract = new Contract(
            contractAddresses[networkId],
            contractAbi,
            signer
        );

        resolve({ contract });
        return;
    }

    reject('Install Metamask');
});

//Connects to the metamask extension.
export const getContractByAddress = (contractAddress, contractAbi) => new Promise(async (resolve, reject) => {
    let provider = await detectEthereumProvider();

    if (provider) {

        await provider.request({ method: 'eth_requestAccounts' });

        provider = new ethers.providers.Web3Provider(provider);

        const signer = provider.getSigner();

        //The json object of the MediaToken contract that is created in the contracts folder when truffle compiling the solidity project.
        //Contains the address of the contract, the abi (holds all functions and members of media token), and the signer.
        const contract = new Contract(
            contractAddress,
            contractAbi,
            signer
        );

        resolve({ contract });
        return;
    }

    reject('Install Metamask');
});

export const getUserAddress = () => new Promise( async (resolve, reject) => {
    let provider = await detectEthereumProvider();

    if (provider) {
        const uAddr = await provider.request({ method: 'eth_requestAccounts' });

        provider = new ethers.providers.Web3Provider(provider);
        const signer = provider.getSigner();
        
        resolve({ uAddr, signer });
        return;
    }

    reject('Install Metamask');
})

export const getProvider = () => new Promise( async (resolve, reject) => {
    let provider = await detectEthereumProvider();

    if (provider) {
        provider = new ethers.providers.Web3Provider(provider);        
        resolve(provider);
        return;
    }

    reject('Install Metamask');
})

export const getMediaTokenContract = () => {
    return getContract(mediaTokenAddresses, MediaToken)
};

export const getPaymentManagerContract = () => {
    return getContract(paymentManagerAddresses, PaymentManager)
}

export const getClaimManagerContract = () => {
    return getContract(claimManagerAddresses, ClaimManager)
}
export const getWinningsManagerContract = () => {
    return getContract(winningsManagerAddresses, WinningsManager)
}

export const getPromoManagerContract = () => {
    return getContract(promoManagerAddresses, PromoManager)
}