import React, { createContext, useContext, useState, useReducer, useEffect } from "react";
//import { useHowApiClient } from "./HowApiClient";

export const AuthReducer = createContext();

const isNullUndefinedOrEmpty = (obj) => {
    return( obj === undefined || obj === null || obj === ''  )
} 

export const AuthReducerProvider = ({children}) => {

    const [authState, dispatchAuthUpdate] = useReducer(
        authReducer, 
        {
            webToken: undefined, 
            userAuthorized: false,
            userAddress: undefined,
            chainId: undefined,
            authChecked: false,
            truncatedAddress: undefined,
            userName: undefined,
            userRank: undefined,
            isSigningIn: false,
        }
    );
    //const {isAuthorized} = useHowApiClient();

    function authReducer(authState, action) {
        switch (action.type) {
            case 'clearAuthState': {
                return {
                    ...authState,
                    userAuthorized: false,
                    webToken: undefined,
                    userAddress: undefined,
                    chainId: undefined,
                    authChecked: false,
                    truncatedAddress: undefined,
                    userName: undefined,
                    userRank: undefined,
                    isSigningIn: false
                };
            }
            case 'updateAuth': {
                return {
                    ...authState,
                    userAuthorized: action.payload.userAuthorized,
                    webToken: action.payload.webToken,
                    userAddress: action.payload.userAddress,
                    chainId: action.payload.chainId,
                    authChecked: action.payload.authChecked,
                    truncatedAddress: action.payload.userAddress?
                        action.payload.userAddress.substring(0,6) + "..." + 
                        action.payload.userAddress.substring(
                            action.payload.userAddress.length - 4, 
                            action.payload.userAddress.length
                        ): '',
                        userName: action.payload.userName,
                        userRank: action.payload.userRank,
                        isSigningIn: false,
                }
            }
            case 'updateUsername':{
                return{
                    ...authState,
                    userName: action.payload.userName
                }
            }
            case 'updateUserData':{
                return{
                    ...authState,
                    userName: action.payload.userName,
                    userRank: action.payload.userRank
                }
            }
            case 'signInStarted':{
                return{
                    ...authState,
                    isSigningIn: true
                }
            }
        }
    }

        
        return (
        <AuthReducer.Provider 
            value = {{
                authState, 
                dispatchAuthUpdate
            }}
        >
            {children}
        </AuthReducer.Provider>
        )

}
export const useAuthReducer = () => useContext(AuthReducer);