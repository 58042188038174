import { Grid } from "@material-ui/core"
import { Headline } from "../components/Headline"

export const ComingSoon = () => {
    return (
        <Grid container style={{  justifyContent:'center', alignItems:'center', height:'100vh' }}>
            <Grid item>
                <Headline fontSize='40pt'>Coming Soon!</Headline>
            </Grid>
        </Grid>
    )
}