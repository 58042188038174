import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getLibrary } from './helpers/web3ReactUtil';
import { Web3ReactProvider } from '@web3-react/core';
import { StoreProvider } from "./helpers/GlobalStore";
import { ContractReducerProvider } from './helpers/ContractReducer';
import { AuthReducerProvider } from './helpers/AuthReducer';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ContractAPIProvider } from './helpers/ContractAPI';
import { HowApiProvider } from './helpers/HowApiClient';
import './fonts/Progress.ttf';


ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider
    getLibrary={getLibrary}
    >
        <StoreProvider>
          <AuthReducerProvider>
          <ContractAPIProvider>
            <ContractReducerProvider>
                <HowApiProvider>
                  <ParallaxProvider>
                    <App/>
                  </ParallaxProvider> 
                </HowApiProvider>
              </ContractReducerProvider>
            </ContractAPIProvider>
          </AuthReducerProvider>
        </StoreProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
