import React, { createContext, useContext, useState, useReducer, useEffect } from "react";
import { useStore } from "./GlobalStore";
import { useContractAPI } from "./ContractAPI";

export const ContractReducer = createContext();


export const ContractReducerProvider = ({children}) => {

    

  const { userAuthExists } = useStore()
  const { getPaymentManager, getWinningsManager } = useContractAPI()
  const [ paymentManagerState, dispatchPmUpdate ] = useReducer( paymentManagerReducer, null )
  const [ winningsManager, dispatchWmUpdate ] = useReducer( winningsManagerReducer, null )

  function winningsManagerReducer(winningsManager, action) {
    switch (action.type) {
        case 'invalidate': {
            return {
                ...winningsManager,
                winningsManager: null
            };
        }
        case 'reload': {
            return {
                ...winningsManager,
                winningsManager: action.payload
            }
        }
    }
  }

  const handleUpdateWM = async (action) => {

      if(action === 'reload'){
          const wm = await getWinningsManager()
          dispatchWmUpdate({ type: 'reload', payload: wm})
      }
      else{
          dispatchWmUpdate({ type: 'invalidate', payload: null})
      }
  }

  function paymentManagerReducer(paymentManagerState, action) {
    switch (action.type) {
        case 'invalidate': {
            return {
                ...paymentManagerState,
                paymentManagerState: null
            };
        }
        case 'reload': {
            return {
                ...paymentManagerState,
                paymentManagerState: action.payload
            }
        }
    }
  }

  const handleUpdatePM = async (action) => {
      if(action === 'reload'){
//          const pm = await getPaymentManager()
//          dispatchPmUpdate({ type: 'reload', payload: pm})
      }
      else{
//          dispatchPmUpdate({ type: 'invalidate', payload: null})
      }
  }





  useEffect(() => {
    if(userAuthExists === true){
      handleUpdatePM('reload')
      handleUpdateWM('reload')
    }
    else{
      handleUpdatePM('invalidate')
      handleUpdateWM('invalidate')
    }

  }, [userAuthExists])
    
    return (
      <ContractReducer.Provider 
        value = {{
            paymentManagerState, 
            dispatchPmUpdate,
            winningsManager,
            dispatchWmUpdate
        }}
      >
        {children}
      </ContractReducer.Provider>
    )

}
export const useContractReducer = () => useContext(ContractReducer);