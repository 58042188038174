//This component combines duplicate behavior from the Store and Inventory Packs (whats inside feature) page into one shared component.
//Returns a grid container housing all the elements that should be displayed in the popover.

import React from "react";
import {  Grid, Typography, Zoom, Popover, Backdrop } from '@material-ui/core';
import { rarityColors } from "../../helpers/GlobalStore";
import SlotChance from '../../components/SlotChance';
import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'


const whatsInsideModalRef = React.createRef()

const getRarityNameById = (rarityId) => {
    switch(rarityId){
        case 1:
            return 'Legendary';
        case 2:
            return 'Epic';
        case 4:
            return 'Rare';
        case 8:
            return 'Uncommon';
            break;
        case 16:
            return 'Common';
            break;
        case 32:
            return 'Promo';
        case 64: 
            return 'Beta';
        default:
            return 'Mega'

    }
}

const WhatsInsidePopover = Demodal.create(({pack}) => {
    const whatsInsideModal = useModal()

    const renderSlots = () => {
        return(
            <Grid container spacing={5} style={{ display: 'flex', flexShrink:10, borderRadius: `20px`, justifyContent:'space-evenly', alignItems:'flex-start', margin:'auto'}}>
            {pack && pack.raritySlots && pack.raritySlots.length > 0 &&
                pack.raritySlots.map((slot, index) => (
                    <Zoom 
                        in={true}
                        style = {{ transitionDelay: `${150 + ((index) * 200)}ms` }}
                        key = {`pack-${pack.packId}-slot-${index}-zoom`}
                    >
                        <Grid 
                            item
                            key = {`pack-${pack.packId}-slot-${index}-containerItem`}
                        >
                            <Grid item key = {`pack-${pack.packId}-slot-${index}-text`} style={{textAlign:'center'}}>
                                <Typography variant="h6" style = {{color: "white"}}>{`Card ${index + 1}`}</Typography>
                            </Grid>

                            <Grid item  key = {`pack-${pack.packId}-slot-${index}-chanceItem`}>
                                <SlotChance slot={slot}/>
                            </Grid>
                        </Grid>
                    </Zoom>
                ))
                }
            </Grid>
        )
    }

    const renderLegend = () =>{
        return( 
            <Grid id = "LegendContainer" container style = {{display: "flex", justifyContent: "center",  padding: 0, margin: 5}}>
                {pack.containedRarities && pack.containedRarities.length > 0 &&
                pack.containedRarities.map((rarity, index) => (
                    <Grid item key={index}  style = {{marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10}}>
                        <Grid container style = {{display: "flex", justifyContent: (pack.type === 0) ? "center" : "left", alignItems: "center"}}>
                            <Grid 
                            item
                            style = {{
                                width: 20,
                                height: 20,
                                backgroundColor: `rgb(${rarityColors[rarity]})`,
                                marginRight: 5
                            }}
                            />
                            <Grid style={{color:`rgb(${rarityColors[rarity]})`}}>
                                {getRarityNameById(rarity)}
                            </Grid>
                        </Grid>
                    </Grid>
                ))
                }
            </Grid>
        )
    }
  
    return (
        <Backdrop {...muiDialog(whatsInsideModal)} style={{backgroundColor:'rgba(0,0,0,.8)', zIndex:'700'}}>
            <Popover 
                {...muiDialog(whatsInsideModal)}
                ref={whatsInsideModalRef}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                        },
                }}
                classes = {{
                    paper: "",  
                    root: "",
                    scrollPaper: "",
                    scrollBody: "",
                    container: ""
                }}
                style={{width:'auto',  filter:`drop-shadow( 0px 0px 25px rgba(${rarityColors[pack.rarity]}, 1))`}}
                anchorReference="anchorPosition"
                anchorPosition={{top:window.innerHeight/2, left:window.innerWidth/2}}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <Grid 
                    container 
                    style={{
                        background: 'rgba(0,0,0,0.95)', 
                        color: 'white', 
                        alignItems:'center', 
                        border:'1px solid white',
                        borderRadius:'20px',
                        margin:'auto',
                        padding:'auto',
                        width:window.innerWidth<1100?'75vw':'auto',
                    }}
                >  
                     {renderLegend()}
     
                     { renderSlots()}
                </Grid>
            </Popover>
        </Backdrop>
    )
  })


export default WhatsInsidePopover