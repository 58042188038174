import React, { useState } from 'react'
import { Button } from '@material-ui/core';
import styled from 'styled-components';

const GameButton = styled(Button)`

    color: black;
    font-family: "helvetica"!important;
    font-weight: bold;

    line-height: 24px;
    text-transform: capitalize;
    background: linear-gradient(#d05000 20%, #ffff00 100%);

    border-radius: 10px;
    cursor: pointer;
    opacity: .75;
    text-align: center;
    margin: auto;



    transition: all linear .2s;
    
    &:hover{
        transform: scale(1.1);
        opacity: 1;
        background: linear-gradient(#d05000 0%, #ffff00 90%, #ffffff 100%);
        border: 1px solid white;
        border-left: none;
        border-right: none;
    }
    &:disabled{
        filter: grayscale(1);
    }
`

const SvgButton = ({displayText, fontSize, buttonHeight, buttonWidth, onClick, disabled }) => {
    const [ hovered, setHovered ] = useState(false);
    // how far in to cut the shape on both sides
    const CUTAWAY_DISTANCE = 35;
    // width of the lines
    const MITER_WIDTH = 6;
    // prevents the edges of the SVG from getting cut off
    const CLIPPING_PREVENTION_PAD = 8;
    

    const handleMouseIn = () => {
        if(!hovered)
            setHovered(true);
    }

    const handleMouseOut = () => {
        if(hovered)
            setHovered(false);
    }

    return(
    /*  
        <svg
        onClick={onClick}
        onMouseEnter={handleMouseIn}   onMouseLeave={handleMouseOut}
        version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox={`0 0 ${buttonWidth} ${buttonHeight}`} 
        space="preserve"
        style={{ cursor: disabled ? 'default' : 'pointer', filter: disabled ? "grayscale(1)" : "none" }}>
            <g id="Layer_2">
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1={buttonWidth/2} y1={0.1*buttonHeight} x2={buttonWidth/2} y2={0.9*buttonHeight}>
                    <stop  offset="0.3" style={{stopColor:'#F9E962'}}/>
                    <stop  offset="0.9" style={{stopColor:'#747908'}}/>
                </linearGradient>

                <polygon className={hovered && !disabled ? "st5":"st0"} 
                points={`
                ${CLIPPING_PREVENTION_PAD + MITER_WIDTH}, ${CLIPPING_PREVENTION_PAD + MITER_WIDTH/2}  
                ${buttonWidth - CLIPPING_PREVENTION_PAD - MITER_WIDTH}, ${CLIPPING_PREVENTION_PAD + MITER_WIDTH/2}  
                ${buttonWidth - CLIPPING_PREVENTION_PAD  - CUTAWAY_DISTANCE + 4}, ${buttonHeight/2}  
                ${buttonWidth - CLIPPING_PREVENTION_PAD - MITER_WIDTH}, ${buttonHeight - MITER_WIDTH/2 - CLIPPING_PREVENTION_PAD}  
                ${CLIPPING_PREVENTION_PAD + MITER_WIDTH}, ${buttonHeight - CLIPPING_PREVENTION_PAD - MITER_WIDTH/2}  
                ${CLIPPING_PREVENTION_PAD + CUTAWAY_DISTANCE - 4},${buttonHeight/2}`}/>

                <polygon className="st1" 
                points={`
                ${CLIPPING_PREVENTION_PAD}, ${CLIPPING_PREVENTION_PAD}  
                ${buttonWidth - CLIPPING_PREVENTION_PAD}, ${CLIPPING_PREVENTION_PAD}  
                ${buttonWidth - CUTAWAY_DISTANCE},${buttonHeight/2}  
                ${buttonWidth - CLIPPING_PREVENTION_PAD}, ${buttonHeight - CLIPPING_PREVENTION_PAD}  
                ${CLIPPING_PREVENTION_PAD}, ${buttonHeight - CLIPPING_PREVENTION_PAD}  
                ${CUTAWAY_DISTANCE},${buttonHeight/2}`}/>
                <g>
                    <text  
                    style={{fontSize:fontSize, wordBreak: 'break-all'}} 
                    dominantBaseline="central" 
                    x="50%" y ="50%" 
                    textAnchor='middle' 
                    className={hovered && !disabled ?"st6 st3 svgButtonFont":"st2 st3 svgButtonFont" }>
                        {displayText}
                    </text>
                </g>
            </g>
        </svg>
    */
        <GameButton 
            onClick={onClick} 
            disabled={disabled} 
            style={{
                fontSize: fontSize?  fontSize: '24px',
                height: buttonHeight?  buttonHeight: '60px',
                width: buttonWidth?  buttonWidth: '250px'
            }}
        >
            {displayText}
        </GameButton>
    )
}

export default SvgButton;

/*

            <g id="Play_Game_Button">
                <polygon className="st0" points="27.9,8.1 572.6,8.6 541.9,50.2 572.4,92 28.7,91.9 59.5,50.2 	"/>
                <polygon className="st1" points="20.1,4.1 580.4,4.2 546.8,50 580.5,96.1 20.2,96 54.3,50.2 	"/>
                <g>
                    <text transform="matrix(1 0 0 1 87.1656 68.1593)" className="st2 st3 st4">PLAY THE GAME</text>
                </g>
            </g>

*/