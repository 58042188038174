import React from "react";
import styled from 'styled-components'
import {Grid} from '@material-ui/core';

const StyledWalletButton = styled(Grid)`
font-weight: bold;
height:auto;
font-size: 2vh;
height: 5vh;
color: white;
opacity: 0.7;
border-radius: 16px;
background: linear-gradient( rgba( 208, 80, 0, 0 ) 20%, rgba( 255, 220, 0, 0 )  100%);
cursor: pointer;
justify-content: center;
align-content: center;
align-items: center;
text-align: center;
vertical-align: center;
padding-top: 1vh;
transition: all  ease 0.3s;
&:hover{
    opacity: 1;
    background: linear-gradient( rgba( 208, 80, 0, .5 ) 20%, rgba( 255, 220, 0, .4 )  100%);
}
`



const WalletButton = ({onSelect, walletConfig}) => {
    return(
        <StyledWalletButton
  
        id={`wallet-button-${walletConfig.title}`}
        onClick={()=>onSelect(walletConfig.connectorType)}>
            <Grid container style={{alignItems:'center', justifyContent:'center'}}>
                <Grid item  style={{marginRight:'10px'}}>  
                {walletConfig.icon}
                </Grid>
                <Grid item >
                {walletConfig.title} 
                </Grid>
            </Grid>
        </StyledWalletButton>
    )
}

export default WalletButton