import ServerUtil from './ServerUtil'
import Card from './Card'

import Layers from './Layers.js';
import AttackAppearances from './AttackAppearances';

import AudioManager from './AudioManager'
import {
    MAIN_BG_MUSIC,
    SEARCHING_MUSIC,
    HIGH_CARD_MUSIC,
    VICTORY_MUSIC,
    DEFEAT_MUSIC
} from './AudioManager'

/*
    Handles game play management, mainly related to game server events, requests and responses
*/
const GameManager = (gamePage, gamePageFunctions, token, unathorizedErrorHandler) => {

    //Turn and game state flags
    var gameOver = false;
    var yourTurn = false;
    var discardAvailable = false;
    var mulliganDisabled = false;
    var highCardResultsHaveAnimated = false;
    var matchMakingSearchingTween;
    var matchMakingAnimationUpdaterLoopHandle;

    //The server util which handles requests, response callbacks, and event callbacks
    var serverUtil = ServerUtil({
        highCardEventHandler: asyncHighCardEventHandler,
        yourTurnEventHandler: asyncYourTurnEventHandler,
        enemyTurnEventHandler: asyncEnemyTurnEventHandler,
        enemyUseItemEventHandler: asyncEnemyUseItemEventHandler,
        enemyAttackEventHandler: asyncEnemyAttackEventHandler,
        enemySummonEventHandler: asyncEnemySummonEventHandler,
        enemyForfeitEventHandler: asyncEnemyForfeitEventHandler,
        youWonEventHandler: asyncYouWonEventHandler,
        youLostEventHandler: asyncYouLostEventHandler,
        youTiedEventHandler: asyncYouTiedEventHandler,
        rejoinEventHandler: asyncRejoinEventHandler,
        disconnectEventHandler: asyncDisconnectEventHandler,

        registrationHandler: asyncRegistrationResponseHandler,
        cancelMatchMakingResponseHandler: cancelMatchMakingResponseHandler,
        mulliganResponseHandler: mulliganResponseHandler,
        discardResponseHandler: discardResponseHandler,
        useItemResponseHandler: useItemResponseHandler,
        attackResponseHandler: attackResponseHandler,
        summonResponseHandler: summonResponseHandler,
        endTurnResponseHandler: endTurnResponseHandler,
        forfeitResponseHandler: forfeitResponseHandler
    }, token, unathorizedErrorHandler);

    function isGameOver(){
        return gameOver;
    }

    function isItMyTurn(){
        return yourTurn;
    }

    function isDiscardAvailableThisTurn(){
        return discardAvailable;
    }

    /** 
        COMPLETELY ASYNC METHODS
            These are not responses to any request made by the player
            They can occur randomly at any time (some may only occur once in the game cycle)
    */

    /*
        results = 
        { 
            yourCard: {}, //Card drawn for you
            enemyCard: {}, //Card the enemy drew
            youStart: true | false    //true to indicate you won the high card, or false that the enemy won,
            secondCard = {}, //Second card drawn for you
            thirdCard = {}, //Third card drawn for you
            fourthCard = {}, //Fourth card drawn for you
            fifthCard = {} //Fifth card drawn for you
        }
    */
    async function asyncHighCardEventHandler(results){
        console.log(results);
        gamePageFunctions.matchMakingInProgress(false);

        stopMatchMakingAnimation();

        gamePageFunctions.audioManager.playMusic(HIGH_CARD_MUSIC);

        if (results.youStart){
            yourTurn = true;
            console.log("You won the high card!");
        }
        else{
            yourTurn = false;
            console.log("You lost the high card...");
        }

        //Set the opponent name in the enemy banner if it was provided
        if (results.enemyName){
            gamePage.enemyBanner.setName(results.enemyName);
        }

        //inform player high card draw is going to occur
        await handleHighCardDrawNotice(results.yourCard.artName, results.enemyCard.artName, yourTurn);

        //Put draw results into player hand
        var card1 = gamePage.playerBanner.createCard(results.yourCard);
        var card2 = gamePage.playerBanner.createCard(results.secondCard);
        var card3 = gamePage.playerBanner.createCard(results.thirdCard);
        var card4 = gamePage.playerBanner.createCard(results.fourthCard);
        var card5 = gamePage.playerBanner.createCard(results.fifthCard);

        gamePage.playerBanner.placeCard(card1);
        gamePage.playerBanner.placeCard(card2);
        gamePage.playerBanner.placeCard(card3);
        gamePage.playerBanner.placeCard(card4);
        gamePage.playerBanner.placeCard(card5);

        //Put ours and the enemy's high cards into the detail views
        gamePageFunctions.showCopyOfCardInDetailView(card1);
        gamePageFunctions.showCopyOfCardInEnemyDetailView(results.enemyCard);
    }

    /*
        info = 
        { 
            timeRemaining: 30, //Number of seconds you have in this turn
            energyGained: 3, //Energy server has granted you to start this turn
        }

        gameState =
        {
            userHealth: 8,
            userEnergy: 3,
            enemyHealth: 10,
            drawCardsRemaining: 3
        }
    */
    async function asyncYourTurnEventHandler(info, gameState){
        if (isGameOver()) return;

        yourTurn = true;

        gamePage.playerBanner.setHealth(gameState.userHealth);
        gamePage.playerBanner.setEnergy(gameState.userEnergy);
        gamePage.enemyBanner.setHealth(gameState.enemyHealth);

        discardAvailable = true;
        if (!mulliganDisabled){
            //enable the mulligan button on the players very first turn and never again
            gamePage.playerBanner.enableMulligan();
        }

        //Draw Cards Remaining has potentially changed, update the local deck representation
        gamePageFunctions.onRemainingDrawCardsUpdated(gameState.drawCardsRemaining);

        //It's a new turn, so reset the remaining attacks on all play field cards:
        gamePage.playField.resetAllRemainingAttacks();

        //Stop the timer, set it's new value, but only start the timer once the turn change animation is complete
        gamePageFunctions.stopTimer(info.timeRemaining);

        //Check every 100ms (up to 10 seconds) to see if the high card results have been received and finished animating, then trigger the turn transition animation
        var numChecks = 0;
        const MAX_NUM_CHECKS = 15000/100; //15 seconds max / 100 ms loop interval
        var startAnimationLoopHandle = setInterval(() => {
            //Cap the total time we are going to wait, if we've passed our max number of checks, kill this loop
            numChecks++;
            if (numChecks > MAX_NUM_CHECKS){
                console.error("Waited too long for high card results after receiving turn indicator.")
                clearInterval(startAnimationLoopHandle);
                return;
            }

            //Still waiting on the high card results to arrive and finish animating
            if (!highCardResultsHaveAnimated){
                return;
            }

            //High card results are done animating, so we can animate the turn transition animation and kill this loop
            handleTurnTransitionAnimation(false, () => {
                console.log("It's your turn, you have this many seconds: " + info.timeRemaining);

                gamePage.buttonManager.redrawButtons();  //only redraw the buttons once the animation is done
                gamePageFunctions.startTimerInSeconds(info.timeRemaining, gamePage.playerBanner.disableMulligan);
            });
            clearInterval(startAnimationLoopHandle);

        }, 100);


        //TODO:
        //  animate gainEnergy(3)
    }

    /*
        info = 
        { 
            timeRemaining: 30 //Number of seconds enemy has in this turn
        }

        gameState =
        {
            userHealth: 8,
            userEnergy: 3,
            enemyHealth: 10
        }
    */
    async function asyncEnemyTurnEventHandler(info, gameState){
        if (isGameOver()) return;
        
        yourTurn = false;
        gamePage.buttonManager.redrawButtons(); //redraw the buttons right away, it's not your turn, so all actions will be rejected any way
        
        //Refill hand with given refresh cards from the server
        for (var i = 0; i < info.refreshCards.length; i++) {
            var card = gamePage.playerBanner.createCard(info.refreshCards[i]);
            gamePage.playerBanner.placeCard(card);
        }

        gamePage.playerBanner.setHealth(gameState.userHealth);
        gamePage.playerBanner.setEnergy(gameState.userEnergy);
        gamePage.enemyBanner.setHealth(gameState.enemyHealth);

        //Draw Cards Remaining has potentially changed, update the local deck representation
        gamePageFunctions.onRemainingDrawCardsUpdated(gameState.drawCardsRemaining);

        //Stop the timer, set it's new value, but only start the timer once the turn change animation is complete
        gamePageFunctions.stopTimer(info.timeRemaining);

        //Check every 100ms (up to 10 seconds) to see if the high card results have been received and finished animating, then trigger the turn transition animation
        var numChecks = 0;
        const MAX_NUM_CHECKS = 10000/100; //10 seconds max / 100 ms loop interval
        var startAnimationLoopHandle = setInterval(() => {
            //Cap the total time we are going to wait, if we've passed our max number of checks, kill this loop
            numChecks++;
            if (numChecks > MAX_NUM_CHECKS){
                console.error("Waited too long for high card results after receiving turn indicator.")
                clearInterval(startAnimationLoopHandle);
                return;
            }

            //Still waiting on the high card results to arrive and finish animating
            if (!highCardResultsHaveAnimated){
                return;
            }

            //High card results are done animating, so we can animate the turn transition animation and kill this loop
            handleTurnTransitionAnimation(true, () => {
                console.log("It's your enemys turn, they have this many seconds: " + info.timeRemaining);

                gamePageFunctions.startTimerInSeconds(info.timeRemaining);
            });
            clearInterval(startAnimationLoopHandle);

        }, 100);

    }

    async function asyncEnemyUseItemEventHandler(generalItemCard, gameState){
        console.log("Your enemy used an item to heal");

        gamePageFunctions.showCopyOfCardInEnemyDetailView(generalItemCard);

        handleHealAnimation(true, generalItemCard.cardMetaData.healthGain, () => {
            gamePage.enemyBanner.setHealth(gameState.enemyHealth);
        });
    }

    async function asyncEnemyAttackEventHandler(generalFieldCard, response){
        console.log("Your enemy attacked you");
    
        var attackAppearance = generalFieldCard.cardMetaData.attackAppearance
        //Look through everything the server allowed the enemy to do
        for (let i = 0; i < response.actions.length; i++) {
            const action = response.actions[i];
            
            switch (action.name) {
                case "ENEMY_MONSTER_HEALTH_DECREASE":
                    if (action.died)
                    {
                        handleFieldAttackAnimation(action.laneNumber, true, attackAppearance, () => {
                            gamePage.playField.removeCardWithId(action.uniqueId);
                            gamePageFunctions.clearDetailViewIfHasId(action.uniqueId);
                        });
                    }
                    else
                    {
                        handleFieldAttackAnimation(action.laneNumber, true, attackAppearance, () => {
                            gamePage.playField.damagePlayerCard(action.laneNumber, action.uniqueId, action.value);
                        });
                    }
                    break;
                case "ENEMY_HEALTH_DECREASE":
                    handleBannerAttackAnimation(true, action.attackAppearance, () => {
                        gamePage.playerBanner.setHealth(action.newHealth);
                    });
                    break;
                default:
                    break;
            }
        }

        gamePageFunctions.showCopyOfCardInEnemyDetailView(generalFieldCard);
    }

    async function asyncEnemySummonEventHandler(response) {
        console.log('Your enemy summoned a card');
        
        //Look through everything the server allowed the enemy to do
        for (let i = 0; i < response.actions.length; i++) {
            const action = response.actions[i];
            
            switch (action.name) {
                case "YOUR_MONSTER_HEALTH_DECREASE":
                    if (action.died)
                    {
                        handleEdgarSpecialAnimation(action.laneNumber, false, () => {
                            gamePage.playField.removeCardWithId(action.uniqueId);
                            gamePageFunctions.clearDetailViewIfHasId(action.uniqueId);
                            gamePage.buttonManager.redrawButtons();
                        });
                    }
                    else
                    {
                        handleFieldAttackAnimation(action.laneNumber, false, AttackAppearances.DEFAULT, () => {
                            gamePage.playField.damagePlayerCard(action.laneNumber, action.uniqueId, action.value);
                        });
                    }

                    break;
                case "CARD_SUMMONED":
                    var cardToSummon = Card(action.cardObject.cardId, gamePage.mainScene, 'cards', action.cardObject.artName, null, action.cardObject.cardMetaData, null);
                    cardToSummon.setParentContainerId(gamePage.playField.fieldId);
                    cardToSummon.setCallback(gamePageFunctions.onFieldEnemyCardClick.bind(null, cardToSummon));

                    //Perform the actual summon/placement of the card on the field
                    handleFieldSummonAnimation(action.laneNumber, true)
                    gamePage.playField.placeEnemyCard(cardToSummon, action.laneNumber)

                    gamePageFunctions.showCopyOfCardInEnemyDetailView(cardToSummon);
                    break;
                default:
                    break;
            }
        }
    }

    async function asyncYouWonEventHandler() {
        //Check every 100ms (up to 5 seconds) to see if enemy health has reached zero (meaning any attack animations are completed), then trigger game ending animation
        var numChecks = 0;
        const MAX_NUM_CHECKS = 5000/100; //5 seconds max / 100 ms loop interval
        
        let { width, height } = gamePage.mainScene.sys.game.canvas;
        var portraitScaling = 1.0;
        console.log(`current resolution: ${width} x ${height}`)
        if(width < height){
            portraitScaling = 1.0;
        }

        var startAnimationLoopHandle = setInterval(() => {
            //Cap the total time we are going to wait, if we've passed our max number of checks, kill this loop
            numChecks++;
            if (numChecks > MAX_NUM_CHECKS){
                console.error("Got the signal that the game ended (you won) but the enemy health never dropped to zero.");
                clearInterval(startAnimationLoopHandle);
                return;
            }

            //Still waiting on enemy health to drop to zero (or forfeit to register) to perform the game ending animation
            if (!gamePage.enemyBanner.isDead()){
                return;
            }

            //Update The Users MMR
            gamePageFunctions.updateUserProfileMMR();

            //Perform game ending animation
            var victoryScreen; 
            
            if(width > height){
                victoryScreen = gamePage.mainScene.add.video(width * 0.5, height * 0.5, 'victory-screen').setDepth(Layers.ABOVE_ALL + 50).setScale(portraitScaling);
            }
            else{
                victoryScreen = gamePage.mainScene.add.video(width * 0.5, height * 0.5, 'victory-screen-mobile').setDepth(Layers.ABOVE_ALL + 50).setScale(portraitScaling);
            }
            
            
            victoryScreen.on('complete', function(){
                console.log("YOU WON!");
                gameOver = true;
            }, gamePage.mainScene);

            gamePageFunctions.audioManager.playMusic(VICTORY_MUSIC);
            victoryScreen.play();
      
            clearInterval(startAnimationLoopHandle);
        }, 100);
    }

    async function asyncYouLostEventHandler() {
        //Check every 100ms (up to 5 seconds) to see if our health has reached zero (meaning any attack animations are completed), then trigger game ending animation
        var numChecks = 0;
        const MAX_NUM_CHECKS = 5000/100; //5 seconds max / 100 ms loop interval

        let { width, height } = gamePage.mainScene.sys.game.canvas;
        var portraitScaling = 1.0;
        console.log(`current resolution: ${width} x ${height}`)
        if(width < height){
            
            portraitScaling = 1.0;
        }

        var startAnimationLoopHandle = setInterval(() => {
            //Cap the total time we are going to wait, if we've passed our max number of checks, kill this loop
            numChecks++;
            if (numChecks > MAX_NUM_CHECKS){
                console.error("Got the signal that the game ended (you lost) but your health never dropped to zero.");
                clearInterval(startAnimationLoopHandle);
                return;
            }

            //Still waiting on our health to drop to zero (or forfeit to register) to perform the game ending animation
            if (!gamePage.playerBanner.isDead()){
                return;
            }

            //Update The Users MMR
            gamePageFunctions.updateUserProfileMMR();

            //Perform game ending animation
            var defeatScreen; 
            
            if(width > height){
                defeatScreen = gamePage.mainScene.add.video(width * 0.5, height * 0.5, 'defeat-screen').setDepth(Layers.ABOVE_ALL + 50).setScale(portraitScaling);
            }
            else{
                defeatScreen = gamePage.mainScene.add.video(width * 0.5, height * 0.5, 'defeat-screen-mobile').setDepth(Layers.ABOVE_ALL + 50).setScale(portraitScaling);
            }
            defeatScreen.on('complete', function(){
                console.log("YOU LOSE! GOOD DAY SIR!");
                gameOver = true;
            }, gamePage.mainScene);

            gamePageFunctions.audioManager.playMusic(DEFEAT_MUSIC);
            defeatScreen.play();

            clearInterval(startAnimationLoopHandle);
        }, 100);
    }

    async function asyncYouTiedEventHandler() {
        gamePageFunctions.audioManager.stopMusic();

        gameOver = true;
        console.log("YOU TIED, PROBABLY BOTH OF YOU STEPPED AWAY FROM THE GAME COMPLETELY");
    }

    /*        
        gameState = {
            userHealth: player.getHealth(),
            userEnergy: player.getEnergy(),
            enemyHealth: opposingPlayer.getHealth(),
            drawCardsRemaining: player.getDeckSize(),
            userHand: player.getCopyOfEntireHand(),
            userField: player.getCopyOfField(),
            enemyField: opposingPlayer.getCopyOfField(),
            yourTurn: isItThisPlayersTurn(player),
            timeRemaining: getRemainingTimeForClient()
        };
    */

    async function asyncRejoinEventHandler(gameState) {
        console.log("You've successfully rejoined your game!");

        //Set the stats in the enemy banner area
        gamePage.playerBanner.setHealth(gameState.userHealth);
        gamePage.playerBanner.setEnergy(gameState.userEnergy);
        gamePage.enemyBanner.setHealth(gameState.enemyHealth);
        gamePageFunctions.onRemainingDrawCardsUpdated(gameState.drawCardsRemaining);

        if (gameState.enemyName){
            gamePage.enemyBanner.setName(gameState.enemyName);
        }

        //Set the turn/action states
        discardAvailable = gameState.discardAvailable;
        mulliganDisabled = !gameState.mulliganAvailable; //TODO: mulligan still appears enabled sometimes on rejoin(?)
        if (mulliganDisabled)
            gamePage.playerBanner.disableMulligan();
        else 
            gamePage.playerBanner.enableMulligan();

        //Clear up any starting animations (match making, high card results, etc...)
        highCardResultsHaveAnimated = true; //if rejoining, the high card results won't animate again
        stopMatchMakingAnimation();

        //Set the player hand
        for (let i = 0; i < gameState.userHand.length; i++) {
            let handCard = gameState.userHand[i];

            if (handCard === null)
                continue; //no card in that slot

            let card = gamePage.playerBanner.createCard(handCard);
            gamePage.playerBanner.placeCard(card);
        }

        //Set the player field
        for (let i = 0; i < gameState.userField.length; i++) {
            let fieldCard = gameState.userField[i];

            if (fieldCard === null)
                continue; //no card in that slot

            //create the matching Card object to place
            let cardToPlace = Card(fieldCard.cardId, gamePage.mainScene, 'cards', fieldCard.artName, null, fieldCard.cardMetaData, null);
            cardToPlace.cardMetaData.uniqueId = fieldCard.cardMetaData.uniqueId;
            cardToPlace.setParentContainerId(gamePage.playField.fieldId);
            cardToPlace.setCallback(gamePageFunctions.onFieldPlayerCardClick.bind(null, cardToPlace));

            //place the card in the appropriate lane
            let laneNumber = i + 1;
            gamePage.playField.placePlayerCard(cardToPlace, laneNumber);
        }

        //Set the enemy field
        for (let i = 0; i < gameState.enemyField.length; i++) {
            let fieldCard = gameState.enemyField[i];

            if (fieldCard === null)
                continue; //no card in that slot

            //create the matching Card object to place
            let cardToPlace = Card(fieldCard.cardId, gamePage.mainScene, 'cards', fieldCard.artName, null, fieldCard.cardMetaData, null);
            cardToPlace.cardMetaData.uniqueId = fieldCard.cardMetaData.uniqueId;
            cardToPlace.setParentContainerId(gamePage.playField.fieldId);
            cardToPlace.setCallback(gamePageFunctions.onFieldEnemyCardClick.bind(null, cardToPlace));

            //place the card in the appropriate lane
            let laneNumber = i + 1;
            gamePage.playField.placeEnemyCard(cardToPlace, laneNumber);
        }

        //Was it your turn or the enemy turn?
        yourTurn = gameState.yourTurn;
        gamePageFunctions.startTimerInSeconds(gameState.timeRemaining);

        gamePage.initScreen && gamePage.initScreen.destroy();
        gamePageFunctions.audioManager.playMusic(MAIN_BG_MUSIC); //Gameplay mat is officially visible, so start the bg music
        gamePage.buttonManager.redrawButtons();
    }

    async function asyncDisconnectEventHandler() {
        gameOver = true;
        gamePageFunctions.disconnectGame();
    }

    /**
        REQUESTS
    */

    async function asyncRegisterPlayer(userId, deckId){
        serverUtil.attemptRegisterPlayer(userId, deckId);
    }
    async function asyncCancelMatchMaking(userId){
        serverUtil.attemptCancelMatchMaking(userId);
    }
    async function asyncMulligan(){
        serverUtil.attemptMulligan();
    }
    async function asyncDiscard(card){
        serverUtil.attemptDiscard(card.cardMetaData.uniqueId);
    }
    async function asyncUseItem(card){
        serverUtil.attemptUseItem(card.cardId, card.cardMetaData.uniqueId);
    }
    async function asyncAttack(card, laneNumber){
        serverUtil.attemptAttack(card.cardId, card.cardMetaData.uniqueId, laneNumber);
    }
    async function asyncSummon(card, laneNumber){
        serverUtil.attemptSummon(card.cardId, card.cardMetaData.uniqueId, laneNumber);
    }
    async function asyncEndTurn() {
        serverUtil.attemptEndTurn();
    }
    async function asyncForfeit() {
        serverUtil.attemptForfeit();
    }


    /** 
        RESPONSES
    */

    async function asyncRegistrationResponseHandler(response){
        if (response.status === 0) { //General successful register status
            gamePageFunctions.matchMakingInProgress(true);
            console.log("Player registered! userId = " + response.userId);
        } else if (response.status === 1) { //General failure to register status
            gamePageFunctions.matchMakingInProgress(false);
            console.error(response.message);
        } else if (response.status === 2) { //Rejoining active game status
            gamePageFunctions.matchMakingInProgress(false);
            console.log(response.message); 
        }  else if (response.status === 3) { //Rejoining match making search queue status
            gamePageFunctions.matchMakingInProgress(true);
            console.log(response.message);
        } else if (response.status === 4 || response.status === 5) { //these two statuses mean something is wrong with the selected deck
            gamePageFunctions.matchMakingInProgress(false);
            console.log(response.message);
        } else {
            console.error("Registration response received, but the status was weird. status = " + response.status + ", userId = " + response.userId);
        }
    }

    async function cancelMatchMakingResponseHandler(response){
        gamePageFunctions.matchMakingInProgress(false);

        if (response.status === 0) {
            console.log("Match making cancelled");
            
            //Reset page state so that a new game can connect
            gameOver = true;
            gamePageFunctions.disconnectGame();
        } else if (response.status === 1) {
            console.log("Match already started");
        } else if (response.status === 2) {
            console.log("Attempted to cancel, but player wasn't in the queue (whut?)");

            //Reset page state so that a new game can connect
            gameOver = true;
            gamePageFunctions.disconnectGame();
        } else {
            console.error("Cancel match making response received, but the status was weird. status = " + response.status + ", userId = " + response.userId);
        }
    }

    async function mulliganResponseHandler(response, gameState){
        if (!response || !response.actions){
            console.error("Server is cuckoo for cocoa puffs (mulligan)");
            return;
        }

        if (response.status !== 0){
            console.error("MULLIGAN REJECTED: " + response.failureReason);
            return
        }

        //Look through everything the server allowed us to do, start with all the card removals, then do the new cards
        for (let i = 0; i < response.actions.length; i++) {
            const action = response.actions[i];

            if (action.name === "REMOVE_CARD_FROM_HAND"){
                gamePage.playerBanner.removeCardWithId(action.value);
            }
        }

        for (let i = 0; i < response.actions.length; i++) {
            const action = response.actions[i];

            if (action.name === "ADD_CARD_TO_HAND"){
                var card = gamePage.playerBanner.createCard(action.cardObject);
                gamePage.playerBanner.placeCard(card);
            }
        }

        //Tell player banner that a mulligan occurred, it should hide the mulligan button permanently
        gamePage.playerBanner.disableMulligan();
        mulliganDisabled = true;
        console.log("SUCCESSFUL MULLIGAN");

        //Draw Cards Remaining has potentially changed, update the local deck representation
        gamePageFunctions.onRemainingDrawCardsUpdated(gameState.drawCardsRemaining);

        //Cleanup
        gamePage.detailViewSlot.destroyObject();
        gamePage.buttonManager.redrawButtons();
    }

    async function discardResponseHandler(response, gameState){
        if (!response || !response.actions){
            console.error("Server is cuckoo for cocoa puffs (discard)");
            return;
        }

        if (response.status !== 0){
            console.error("DISCARD REJECTED: " + response.failureReason);
            return
        }

        //Seems like the discard action was successful, so no longer allow the player to discard this turn
        discardAvailable = false;

        //Look through everything the server allowed us to do, start with all the card removals, then do the new cards
        for (let i = 0; i < response.actions.length; i++) {
            const action = response.actions[i];

            if (action.name === "REMOVE_CARD_FROM_HAND"){
                gamePage.playerBanner.removeCardWithId(action.value);
                var cardInDetailView = gamePage.detailViewSlot.getObject();
                console.log("DISCARDED FROM HAND: " + cardInDetailView.cardId + "(id=" + action.value + ")");
            }
        }

        for (let i = 0; i < response.actions.length; i++) {
            const action = response.actions[i];

            if (action.name === "ADD_CARD_TO_HAND"){
                var card = gamePage.playerBanner.createCard(action.cardObject);
                gamePage.playerBanner.placeCard(card);
                console.log("...REPLACED IT WITH: " + card.cardId + "(id=" + card.cardMetaData.uniqueId + ")");
            }
        }

        //Draw Cards Remaining has potentially changed, update the local deck representation
        gamePageFunctions.onRemainingDrawCardsUpdated(gameState.drawCardsRemaining);

        //Cleanup
        gamePage.detailViewSlot.destroyObject();
        gamePage.buttonManager.redrawButtons();
    }

    async function useItemResponseHandler(response){
        if (!response || !response.actions){
            console.error("Server is cuckoo for cocoa puffs (use item)");
            return;
        }

        if (response.status !== 0){
            console.error("USE ITEM REJECTED: " + response.failureReason);
            return;
        }

        //Look through everything the server allowed us to do
        for (let i = 0; i < response.actions.length; i++) {
            const action = response.actions[i];
            
            switch (action.name) {
                case "YOUR_HEALTH_INCREASE":
                    handleHealAnimation(false, action.deltaHealth, () => {
                        gamePage.playerBanner.setHealth(action.newHealth);
                    });
                    break;
                case "YOUR_ENERGY_DECREASE":
                    gamePage.playerBanner.setEnergy(action.newEnergy);
                    break;
                case "REMOVE_CARD_FROM_HAND":
                    gamePage.playerBanner.removeCardWithId(action.value);
                    break;
                default:
                    console.error("Got an unexpected action from the server: ");
                    console.log(action.name);
                    break;
            }
        }

        //Cleanup
        gamePage.detailViewSlot.destroyObject();
        gamePage.buttonManager.redrawButtons();
    }

    async function attackResponseHandler(response){
        if (!response || !response.actions){
            console.error("Server is cuckoo for cocoa puffs (attack)");
            return;
        }

        if (response.status !== 0){
            console.error("ATTACK REJECTED: " + response.failureReason);
            return;
        }

        var creatureAttackAppearance = null;
        //Look through everything the server allowed us to do
        for (let i = 0; i < response.actions.length; i++) {
            const action = response.actions[i];
            
            switch (action.name) {
                case "ENEMY_MONSTER_HEALTH_DECREASE":
                    if (action.died)
                    {
                        handleFieldAttackAnimation(action.laneNumber, false, action.attackAppearance, () => {
                            gamePage.playField.removeCardWithId(action.uniqueId);
                            gamePageFunctions.clearEnemyDetailViewIfHasId(action.uniqueId);
                        });
                    }
                    else
                    {
                        handleFieldAttackAnimation(action.laneNumber, false, action.attackAppearance, () => {
                            gamePage.playField.damageEnemyCard(action.laneNumber, action.uniqueId, action.value);
                        });
                    }
                    break;
                case "ENEMY_HEALTH_DECREASE":
                    handleBannerAttackAnimation(false, action.attackAppearance, () => {
                        gamePage.enemyBanner.setHealth(action.newHealth);
                    });
                    break;
                case "YOUR_ENERGY_DECREASE":
                    gamePage.playerBanner.setEnergy(action.newEnergy);
                    break;
                case "MONSTER_REMAINING_ATTACKS_DECREMENT":
                    gamePage.playField.decrementRemainingAttacksOnCard(action.laneNumber, action.uniqueId);
                    break;
                default:
                    console.error("Got an unexpected action from the server: ");
                    console.log(action.name);
                    break;
            }
            
        }

        //Cleanup
        gamePage.buttonManager.redrawButtons();
    }

    async function summonResponseHandler(response){
        if (!response || !response.actions){
            console.error("Server is cuckoo for cocoa puffs (summon)");
            return;
        }

        if (response.status !== 0){
            console.error("SUMMON REJECTED: " + response.failureReason);
            return;
        }

        //Look through everything the server allowed us to do
        for (let i = 0; i < response.actions.length; i++) {
            const action = response.actions[i];
            
            switch (action.name) {
                case "CARD_SUMMONED":
                    var cardToSummon = Card(action.cardObject.cardId, gamePage.mainScene, 'cards', action.cardObject.artName, null, action.cardObject.cardMetaData, null);
                    cardToSummon.setParentContainerId(gamePage.playField.fieldId);
                    cardToSummon.setCallback(gamePageFunctions.onFieldPlayerCardClick.bind(null, cardToSummon));

                    //Perform the actual summon/placement of the card on the field
                    handleFieldSummonAnimation(action.laneNumber, false)
                    gamePage.playField.placePlayerCard(cardToSummon, action.laneNumber)

                    break;
                case "YOUR_ENERGY_DECREASE":
                    gamePage.playerBanner.setEnergy(action.newEnergy);
                    break;
                case "REMOVE_CARD_FROM_HAND":
                    gamePage.playerBanner.removeCardWithId(action.value);
                    var cardInDetailView = gamePage.detailViewSlot.getObject();
                    console.log("DISCARDED FROM HAND: " + cardInDetailView.cardId + "(id=" + action.value + ")");
                    break;
                case "CARD_REPLACED":
                    console.log("This card was replaced server-side: " + action.replacedCard);
                    break;
                case "ENEMY_MONSTER_HEALTH_DECREASE":
                    if (action.died)
                    {
                        handleEdgarSpecialAnimation(action.laneNumber, true, () => {
                            gamePage.playField.removeCardWithId(action.uniqueId);
                            gamePageFunctions.clearEnemyDetailViewIfHasId(action.uniqueId);
                        });
                    }
                    else
                    {
                        handleFieldAttackAnimation(action.laneNumber, true, 'DEFAULT',() => {
                            gamePage.playField.damageEnemyCard(action.laneNumber, action.uniqueId, action.value);
                        });
                    }
                    break;
                default:
                    console.error("Got an unexpected action from the server: ");
                    console.log(action.name);
                    break;
            }
        }

        //Cleanup
        gamePage.detailViewSlot.destroyObject();
        gamePage.buttonManager.redrawButtons();
        gamePageFunctions.clearSummonPopover();
    }

    async function endTurnResponseHandler(response) {
        if (!response || !response.actions){
            console.error("Server is cuckoo for cocoa puffs (end turn)");
            return;
        }

        if (response.status !== 0){
            console.error("END TURN REJECTED: " + response.failureReason);
            return;
        }

        for (let k = 0; k < response.actions.length; k++) {
            const action = response.actions[k];

            if (action.name === "TURN_ENDED"){
                console.log("Request to end turn was successful.");
                !mulliganDisabled && gamePage.playerBanner.disableMulligan();
                mulliganDisabled = true;
                yourTurn = false;
            }
            else{
                console.error("GOT AN UNEXPECTED ACTION FROM ENDING TURN");
            }
        }
    }

    async function forfeitResponseHandler(response) {
        if (!response){
            console.error("Server is cuckoo for cocoa puffs (forfeit)");
            return;
        }

        if (response.status !== 0){
            console.error("FORFEIT REJECTED: " + response.failureReason);
            return;
        } else {
            gamePage.playerBanner.setForfeited();
            console.log("Forfeit request accepted");
        }
    }

    async function asyncEnemyForfeitEventHandler() {
        gamePage.enemyBanner.setForfeited();
        console.log("Enemy Forfeited");
    }

    /** 
        UTILITY / ANIMATION FUNCTIONS
    */

    function startMatchMakingAnimation(){

        gamePageFunctions.audioManager.playMusic(SEARCHING_MUSIC);

        const DELAY_BEFORE_FADE_OUT_MS = 1000;

        let { width, height } = gamePage.mainScene.sys.game.canvas;

        const bottomEdgeOfScreen = height;
        const middleOfScreenX = width/2;
        const middleOfScreenY = height/2;

        //Loop animation to pulse the "Searching..." text

        gamePage.loadingText.setText("Searching");

        matchMakingSearchingTween = gamePage.mainScene.add.tween({
            targets: gamePage.loadingText,
            ease: 'Cubic.easeOut',
            duration: 1200,
            delay: 0,
            repeat: -1,
            yoyo: true,
            scale: {
                getStart: () => 1.0,
                getEnd: () => 1.2
            },
            alpha: {
                getStart: () => 1.0,
                getEnd: () => 0.7
            }
        });

        //Keep a running loop to update the animation text as time goes by

        var queueStartTime = Date.now() / 1000;

        matchMakingAnimationUpdaterLoopHandle = setInterval(() => {
            var now = Date.now() / 1000;
            var waitTime = now - queueStartTime; //time since start in seconds

            if (waitTime > 960){ //16 minutes passed, at this point stop doing stuff
                clearInterval(matchMakingAnimationUpdaterLoopHandle);
            } else {
                updateMatchMakingAnimationText(waitTime);
            }

        }, 2000);
    }

    function updateMatchMakingAnimationText(waitTime){

        //Just in case the text isn't visible (e.g. the game canvas was destroyed but some loop is still trying to update it)
        if (!gamePage.loadingText.visible){
            return;
        }

        if (waitTime < 30){
            return; //don't change the color of the text
        } else if (waitTime < 60){
            gamePage.loadingText.setText("Searching.");
            gamePage.loadingText.setCharacterTint(0, -1, true, 0xffd000);
        } else if (waitTime < 120){
            gamePage.loadingText.setText("Searching..");
            gamePage.loadingText.setCharacterTint(0, -1, true, 0xff662f);
        } else if (waitTime < 240){
            gamePage.loadingText.setText("Searching...");
            gamePage.loadingText.setCharacterTint(0, -1, true, 0xff0000);
        } else {
            gamePage.loadingText.setText("Searching.....");
            gamePage.loadingText.setCharacterTint(0, -1, true, 0xff0000);
        }

    }

    function stopMatchMakingAnimation(){

        let { width, height } = gamePage.mainScene.sys.game.canvas;
        let portraitScaling = 1.0;

        if(width < height){
            portraitScaling = 0.4;
        }

        matchMakingSearchingTween.stop(); //disable the yoyo'ing animation
        clearInterval(matchMakingAnimationUpdaterLoopHandle);

        //Just in case the text isn't visible (e.g. the game canvas was destroyed but some loop is still trying to update it)
        if (!gamePage.loadingText.visible){
            return;
        }

        gamePage.loadingText.setText("Match Found");
        gamePage.loadingText.setCharacterTint(0, -1, true, 0xffffff);

        //Fade out the loading text to clean up
        gamePage.mainScene.add.tween({
            targets: gamePage.loadingText,
            ease: 'Cubic.easeOut',
            duration: 500,
            delay: 0,
            scale: {
                getStart: () => 1.2 * portraitScaling,
                getEnd: () => 1.8 * portraitScaling
            },
            alpha: {
                getStart: () => 0.7,
                getEnd: () => 0.0
            },
            onComplete: () => {
                gamePage.loadingText.destroy();
            }
        });
    }
    
    function handleHighCardDrawNotice(yourCardArtName, enemyCardArtName, isPlayerWinner){

        const DELAY_BEFORE_FADE_OUT_MS = 1000;

        let { width, height } = gamePage.mainScene.sys.game.canvas;

        const bottomEdgeOfScreen = height;
        const middleOfScreenX = width/2;
        const middleOfScreenY = height/2;


        var startPosition = { };
        var endPosition = { };
        var portraitScaling = 1; //if it is in landscape mode leave it as 1, else scale as needed

        startPosition.y = bottomEdgeOfScreen+300;   //below screen
        endPosition.y = middleOfScreenY;            //end in the middle of the screen

        if(width < height){
            portraitScaling = 0.45;
        }

        var artName = 'drawing-cards-notice';
        var indicatorArt = gamePage.mainScene.add.image(middleOfScreenX , startPosition.y, artName)
            .setDepth(Layers.ABOVE_ALL + 5)
            .setOrigin(0.5)
            .setScale(portraitScaling)
            .removeInteractive();
    
        gamePage.mainScene.add.tween({
            targets: indicatorArt,
            ease: 'Sine.easeIn',
            duration: 1000,
            delay: 0,
            y: {
                getStart: () => startPosition.y,
                getEnd: () => endPosition.y
            },
            scale: {
                getStart: () => 0.4 * portraitScaling,
                getEnd: () => 0.8 * portraitScaling
            },
            alpha: {
                getStart: () => 0.2,
                getEnd: () => 0.9
            },
            onComplete: () => {
                setTimeout(() => {
                    //Once the appearance animation is done, fade it out and clean up
                    gamePage.mainScene.add.tween({
                        targets: indicatorArt,
                        ease: 'Sine.easeOut',
                        duration: 300,
                        delay: 0,
                        scale: {
                            getStart: () => 0.8 * portraitScaling,
                            getEnd: () => 2.0 * portraitScaling
                        },
                        alpha: {
                            getStart: () => 0.9,
                            getEnd: () => 0.0
                        },
                        onComplete: () => {
                            setTimeout(() => {
                                indicatorArt.destroy();
                                //animate the results to kick off the game
                                showHighCardResultsPopover(yourCardArtName, enemyCardArtName, isPlayerWinner);
                            }, 10);
                        }
                    });
                }, DELAY_BEFORE_FADE_OUT_MS);
            }
        });

    }

    function showHighCardResultsPopover(yourCardArtName, enemyCardArtName, isPlayerWinner){

        let HIGH_CARD_ANIMATION_DURATION = 5000;
        let { width, height } = gamePage.mainScene.sys.game.canvas;
        var winnerLabel;

        //calculate highcard placement positions
        var yourHighCardPosition = { x: width * 0.35 , y: height * 0.6}
        var enemyHighCardPosition = { x: width * 0.65 , y: height * 0.6}
        var portraitScaling = 1.0; //if we are in landscape mode, leave this as 1

        //if we are in portrait mode, adjust the positions for mobile
        if(width < height){
            yourHighCardPosition = { x: width * 0.5 , y: height * 0.75}
            enemyHighCardPosition = { x: width * 0.5 , y: height * 0.35}
            portraitScaling = 0.4;
        }

        //add both cards to popover screen 
        var yourHighCard = gamePage.mainScene.add.image(yourHighCardPosition.x, yourHighCardPosition.y, 'cards', yourCardArtName).setOrigin(0.5).setScale(0.7).setDepth(Layers.ABOVE_ALL + 2).setInteractive({ useHandCursor: false });
        var enemyHighCard = gamePage.mainScene.add.image(enemyHighCardPosition.x, enemyHighCardPosition.y, 'cards', enemyCardArtName).setOrigin(0.5).setScale(0.7).setDepth(Layers.ABOVE_ALL + 2).setInteractive({ useHandCursor: false });
        
        var runeSwirlPosition = {};

        var runeSwirl;

        if(isPlayerWinner){
            winnerLabel = gamePage.mainScene.add.image(width * 0.5 , -100, 'you-drew-high-card').setOrigin(0.5).setDepth(Layers.ABOVE_ALL + 3).setScale(portraitScaling);
            runeSwirlPosition.x = yourHighCardPosition.x;
            runeSwirlPosition.y = yourHighCardPosition.y;
        }
        else{
            winnerLabel = gamePage.mainScene.add.image(width * 0.5 , -100, 'enemy-drew-high-card').setOrigin(0.5).setDepth(Layers.ABOVE_ALL + 3).setScale(portraitScaling);
            runeSwirlPosition.x = enemyHighCardPosition.x;
            runeSwirlPosition.y = enemyHighCardPosition.y;
        }

        gamePage.mainScene.add.tween({
            targets: yourHighCard,
            ease: 'Sine.easeIn',
            duration: 500,
            delay: 0,
            scale: {
                getStart: () => 0.2,
                getEnd: () => 0.7
            },
            alpha: {
                getStart: () => 0,
                getEnd: () => 1
            }
        });

        gamePage.mainScene.add.tween({
            targets: enemyHighCard,
            ease: 'Sine.easeIn',
            duration: 500,
            delay: 0,
            scale: {
                getStart: () => 0.2,
                getEnd: () => 0.7
            },
            alpha: {
                getStart: () => 0,
                getEnd: () => 1
            }
        });

        runeSwirl = gamePage.mainScene.add.video(runeSwirlPosition.x, runeSwirlPosition.y, 'high-card-winner-runes').setOrigin(0.5).setScale(0.7).setDepth(Layers.ABOVE_ALL + 10);
        runeSwirl.play(true);
        gamePage.mainScene.add.tween({
            targets: runeSwirl,
            ease: 'Sine.easeIn',
            duration: 500,
            delay: 0,
            scale: {
                getStart: () => 0.2,
                getEnd: () => 0.7
            },
            alpha: {
                getStart: () => 0,
                getEnd: () => 1
            },
            onComplete: ()=>{
                setTimeout(()=>{
                    gamePage.mainScene.add.tween({
                        targets: winnerLabel,
                        ease: 'Sine.easeIn',
                        duration: 500,
                        delay: 0,
                         y: {
                            getStart: () => 0,
                            getEnd: () => 150 * portraitScaling
                        },
                        scale: {
                            getStart: () => 0.3 * portraitScaling,
                            getEnd: () => 1 * portraitScaling
                        },
                        alpha: {
                            getStart: () => 0,
                            getEnd: () => 1
                        }
                    }, 3000);
                })
            }
        });


        //wait for the timer before removing screen elements      
        setTimeout(() => {
            gamePage.mainScene.add.tween({
                targets: [runeSwirl, yourHighCard, enemyHighCard, winnerLabel, gamePage.initScreen ],
                ease: 'Sine.easeOut',
                duration: 800,
                delay: 0,
                alpha: {
                    getStart: () => 1,
                    getEnd: () => 0
                },
                onComplete: ()=>{
                    yourHighCard && yourHighCard.destroy();
                    enemyHighCard && enemyHighCard.destroy();
                    winnerLabel && winnerLabel.destroy();
                    runeSwirl && runeSwirl.destroy();
                    highCardResultsHaveAnimated = true;
                    gamePage.initScreen && gamePage.initScreen.destroy();
                    gamePageFunctions.audioManager.playMusic(MAIN_BG_MUSIC); //Gameplay mat is officially visible, so start the bg music
                }
            });
        }, HIGH_CARD_ANIMATION_DURATION);
        
        return;
    }

    function handleEdgarSpecialAnimation(slotNumber, isEnemyTurn, onAnimationCompletionCallback){

        const FADE_OUT_DURATION_MS = 1000;

        var attackedSlot = null;
        var detailSlot = null;
        //if it is the enemies turn, the attack animation will be applied to your field slot
        if(isEnemyTurn)
        {   
            //get enemy field lane slot
            attackedSlot =  gamePage.playField.getEnemyLaneByNumber(slotNumber);
            detailSlot = gamePage.enemyDetailViewSlot;
        }
        else
        {
            //get player field lane slot
            attackedSlot =  gamePage.playField.getPlayerLaneByNumber(slotNumber);
            detailSlot = gamePage.detailViewSlot;
        }

        //Put together all the image objects we want to fade out, from the attacked slot to the detail view
        var edgarImageObjects = attackedSlot.getObject().getUnderlyingImageObjects();
        if (detailSlot && detailSlot.getObject())
            edgarImageObjects = edgarImageObjects.concat(detailSlot.getObject().getUnderlyingImageObjects());

        //Now fade those all out
        gamePage.mainScene.add.tween({
            targets: edgarImageObjects,
            ease: 'Sine.easeInOut',
            duration: FADE_OUT_DURATION_MS,
            delay: 0,
            alpha: {
                getStart: () => 1,
                getEnd: () => 0
            },
            onComplete: () => {
                onAnimationCompletionCallback && onAnimationCompletionCallback();
            }
        });

    }

    /*
     *  slotNumber: Slot number being attacked
     *  isEnemyTurn: (true/false) true if player is receiver of damate, false otherwise
     *  onAnimationCompletionCallback: callback method triggered upon animation completion
    */
    function handleFieldAttackAnimation(slotNumber, isEnemyTurn, attackAppearance, onAnimationCompletionCallback){
        
        var attackedSlot = null;
        var attackingCardMetaData = null;
        var attackScaler = 0.55;

        //if it is the enemies turn, the attack animation will be applied to your field slot
        if(isEnemyTurn)
        {
            //get enemy field lane slot
            attackedSlot =  gamePage.playField.getPlayerLaneByNumber(slotNumber);
        }
        else
        {
            //get player field lane slot
            attackedSlot =  gamePage.playField.getEnemyLaneByNumber(slotNumber);
        }

        if(attackedSlot !== null && !attackedSlot.isEmpty())
        {
            switch (attackAppearance){
                case 'DEFAULT':
                    attackScaler = 2 * gamePage.playField.cardScale;
                case 'FROST':
                    attackScaler = 2 * gamePage.playField.cardScale;
                case 'FIRE':
                    attackScaler = 2 * gamePage.playField.cardScale;
                case 'DECAY':
                    attackScaler = 2 * gamePage.playField.cardScale;
                case 'POISON':
                    attackScaler = 2 * gamePage.playField.cardScale;
                case 'ACID':
                    attackScaler = 2 * gamePage.playField.cardScale;
                case 'LIGHT':
                    attackScaler = 2 * gamePage.playField.cardScale;
                case 'DARK':
                    attackScaler = 2 * gamePage.playField.cardScale;
                case 'LIGHTNING':
                    attackScaler = 2 * gamePage.playField.cardScale;
                default:
                    attackScaler = 2 * gamePage.playField.cardScale;
            }
            //add attack animation's sprite to scene
            var fieldAttackAnim = gamePage.mainScene.add.sprite(
                attackedSlot.position.x, 
                attackedSlot.position.y, 
                AttackAppearances.getEnum(attackAppearance)).setDepth(Layers.ACTION_ANIMATION).setScale(attackScaler);

            //when the animation completes, destroy the sprite
            fieldAttackAnim.once('animationcomplete', () => {
                onAnimationCompletionCallback && onAnimationCompletionCallback();
                fieldAttackAnim.destroy();
            });  

            //play the animation
            fieldAttackAnim.anims.play(AttackAppearances.getEnum(attackAppearance));
        }
    }

    /*
     *  isEnemyTurn: (true/false) true if enemy performed the action, false otherwise
     *  onAnimationCompletionCallback: callback method triggered upon animation completion
    */
    function handleBannerAttackAnimation(isEnemyTurn, attackAppearance, onAnimationCompletionCallback){
        
        var hpDamagePosition = null;
        var attackScaler = 0.45;

        //if it is the enemies turn, the attack animation will be applied to your health frame in banner
        if(isEnemyTurn)
        {   
            //get player banner health frame position
            hpDamagePosition = gamePage.playerBanner.getHealthFramePosition();
        }
        else
        {
            //get enemy banner health frame position
            hpDamagePosition = gamePage.enemyBanner.getHealthFramePosition();
        }

        if(hpDamagePosition !== null)
        {
            switch (attackAppearance){
                case 'DEFAULT':
                    attackScaler = gamePage.playField.cardScale;
                case 'FROST':
                    attackScaler = gamePage.playField.cardScale;
                case 'FIRE':
                    attackScaler = gamePage.playField.cardScale;
                case 'DECAY':
                    attackScaler = gamePage.playField.cardScale;
                case 'POISON':
                    attackScaler = gamePage.playField.cardScale;
                case 'ACID':
                    attackScaler = gamePage.playField.cardScale;
                case 'LIGHT':
                    attackScaler = gamePage.playField.cardScale;
                case 'DARK':
                    attackScaler = gamePage.playField.cardScale;
                case 'LIGHTNING':
                    attackScaler = gamePage.playField.cardScale;
                default:
                    attackScaler = gamePage.playField.cardScale;
            }
            //add attack animation's sprite to scene
            var bannerAttackAnim = gamePage.mainScene.add.sprite(
                hpDamagePosition.x, 
                hpDamagePosition.y, 
                AttackAppearances.getEnum(attackAppearance)).setOrigin(0.5).setScale(attackScaler).setDepth(Layers.ACTION_ANIMATION);

            //when the animation completes, destroy the sprite
            bannerAttackAnim.once('animationcomplete', () => {
                onAnimationCompletionCallback && onAnimationCompletionCallback();
                bannerAttackAnim.destroy();
            });
             
            //perform the animation
            bannerAttackAnim.anims.play(AttackAppearances.getEnum(attackAppearance));
        }
    }

    function handleTurnTransitionAnimation(isEnemyTurn, onAnimationCompletionCallback){

        const EASE_IN_DURATION_MS = 400;
        const DELAY_BEFORE_FADE_OUT_MS = 1200;
        const FADE_OUT_DURATION_MS = 400;

        let { width, height } = gamePage.mainScene.sys.game.canvas;

        const topEdgeOfScreen = 0;
        const bottomEdgeOfScreen = height;
        const middleOfScreenX = width/2;
        const middleOfScreenY = height/2;

        var startPosition = { };
        var endPosition = { };

        if(isEnemyTurn) {
            startPosition.y = topEdgeOfScreen-300;  //above screen
            endPosition.y = middleOfScreenY;        //end in the middle of the screen
        }
        else {
            startPosition.y = bottomEdgeOfScreen+300;   //below screen
            endPosition.y = middleOfScreenY;            //end in the middle of the screen
        }

        var artName = isEnemyTurn ? 'enemy-turn-indicator' : 'your-turn-indicator';
        var indicatorArt = gamePage.mainScene.add.image(middleOfScreenX, startPosition.y, artName)
            .setDepth(Layers.ACTION_ANIMATION+5)
            .setOrigin(0.5)
            .setScale(1.0)
            .removeInteractive();
    
        gamePage.mainScene.add.tween({
            targets: indicatorArt,
            ease: 'Sine.easeIn',
            duration: EASE_IN_DURATION_MS,
            delay: 0,
            y: {
                getStart: () => startPosition.y,
                getEnd: () => endPosition.y
            },
            scale: {
                getStart: () => 0.4,
                getEnd: () => 0.8
            },
            alpha: {
                getStart: () => 0.2,
                getEnd: () => 0.9
            },
            onComplete: () => {
                setTimeout(() => {
                    //Once the appearance animation is done, fade it out and clean up
                    gamePage.mainScene.add.tween({
                        targets: indicatorArt,
                        ease: 'Sine.easeOut',
                        duration: FADE_OUT_DURATION_MS,
                        delay: 0,
                        scale: {
                            getStart: () => 0.8,
                            getEnd: () => 3.0
                        },
                        alpha: {
                            getStart: () => 0.9,
                            getEnd: () => 0.0
                        },
                        onComplete: () => {
                            setTimeout(() => {
                                onAnimationCompletionCallback && onAnimationCompletionCallback();
                                indicatorArt.destroy();
                            }, 10);
                        }
                    });
                }, DELAY_BEFORE_FADE_OUT_MS);
            }
        });

    }

    function handleHealAnimation(isEnemyTurn, healValue, onAnimationCompletionCallback){

        const POST_ANIMATION_DELAY_MS = 400;

        var startPosition = { };
        var endPosition = { };

        if(isEnemyTurn) {
            var enemyHealthPosition = gamePage.enemyBanner.getHealthFramePosition();

            startPosition.x = enemyHealthPosition.x;
            endPosition.x = enemyHealthPosition.x + 10;

            startPosition.y = enemyHealthPosition.y - 20;
            endPosition.y = enemyHealthPosition.y - 30;
        }
        else {
            var playerHealthPosition = gamePage.playerBanner.getHealthFramePosition();

            startPosition.x = playerHealthPosition.x;
            endPosition.x = playerHealthPosition.x + 10;
            
            startPosition.y = playerHealthPosition.y - 20;
            endPosition.y = playerHealthPosition.y - 30;
        }

        var healIcon = gamePage.mainScene.add.image(startPosition.x, startPosition.y, 'heal-values', 'heal' + healValue + '.png')
            .setDepth(Layers.ACTION_ANIMATION)
            .setOrigin(0.5)
            .setScale(1.0)
            .removeInteractive();
    
        gamePage.mainScene.add.tween({
            targets: healIcon,
            ease: 'Sine.easeInOut',
            duration: 800,
            delay: 0,
            x: {
                getStart: () => startPosition.x,
                getEnd: () => endPosition.x
            },
            y: {
                getStart: () => startPosition.y,
                getEnd: () => endPosition.y
            },
            scale: {
                getStart: () => 0.4,
                getEnd: () => 0.7
            },
            alpha: {
                getStart: () => 0.2,
                getEnd: () => 0.9
            },
            onComplete: () => {
                setTimeout(() => {
                    healIcon.destroy();
                    onAnimationCompletionCallback && onAnimationCompletionCallback();
                }, POST_ANIMATION_DELAY_MS);
            }
        });

    }

    /*
     *  slotNumber: Slot number being attacked
     *  isEnemyTurn: (true/false) true if player is receiver of damate, false otherwise
     *  onAnimationCompletionCallback: callback method triggered upon animation completion
    */
    function handleFieldSummonAnimation(slotNumber, isEnemyTurn, onAnimationCompletionCallback){

        const SUMMON_KEY = 'summon-atlas';
        
        var summonedSlot = null;
        //if it is the enemies turn, the attack animation will be applied to your field slot
        if(isEnemyTurn)
        {   
            //get enemy field lane slot
            summonedSlot =  gamePage.playField.getEnemyLaneByNumber(slotNumber)
        }
        else
        {
            //get player field lane slot
            summonedSlot =  gamePage.playField.getPlayerLaneByNumber(slotNumber)        
        }

        if(summonedSlot !== null)
        {
            //add attack animation's sprite to scene
            var fieldSummonAnim = gamePage.mainScene.add.sprite(
                summonedSlot.position.x, 
                summonedSlot.position.y, 
                SUMMON_KEY).setDepth(Layers.ACTION_ANIMATION).setScale(2.5 * gamePage.playField.cardScale);

            //when the animation completes, destroy the sprite
            fieldSummonAnim.once('animationcomplete', () => {
                onAnimationCompletionCallback && onAnimationCompletionCallback();
                fieldSummonAnim.destroy();
            });  

            //play the animation
            fieldSummonAnim.anims.play(SUMMON_KEY);
        }
    }

    return {
        isGameOver: isGameOver,
        isItMyTurn: isItMyTurn,
        isDiscardAvailableThisTurn: isDiscardAvailableThisTurn,
        startMatchMakingAnimation: startMatchMakingAnimation,
        asyncRegisterPlayer: asyncRegisterPlayer,
        asyncCancelMatchMaking: asyncCancelMatchMaking,
        asyncMulligan: asyncMulligan,
        asyncDiscard: asyncDiscard,
        asyncUseItem: asyncUseItem,
        asyncAttack: asyncAttack,
        asyncSummon: asyncSummon,
        asyncEndTurn: asyncEndTurn,
        asyncForfeit: asyncForfeit,
    };

}

export default GameManager;
