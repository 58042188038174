import React from 'react'
import { useHistory } from "react-router-dom";
import { Grid, Typography, makeStyles } from '@material-ui/core';
import WatchedElement from '../components/WatchedElement';
import { useStore } from '../helpers/GlobalStore';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import ArtLoader from "../assets/CardArtProvider"
import { Star } from '../assets/images/star';
import StarField from '../components/star-field/StarField';
import BackgroundManager from '../components/BackgroundImageManager';
import { useWindowDimensions } from '../helpers/windowDimentionProvider';
import styled, {keyframes} from 'styled-components';
import { Headline } from '../components/Headline';
import { Timeline } from './timeline/StyledTimeline';
import SocialMediaFooter from '../components/SocialMediaFooter';


const { getArt } = ArtLoader();

const nebulaGlow = keyframes`

    from { 
        filter: hue-rotate(0deg);
    }

    to { 
        filter: hue-rotate(-60deg);
    }
`

const Section1 = styled.div`
alignItems: center; 
verticalAlign: middle; 
justifyContent: center; 
height: inherit;
margin-top:0px;
&:before{

    height: 100%;
    width: 100vw;
    content:"";
    background-image: url(${getArt('Bg1')}); 
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
    position: absolute;

    filter: brightness(.45) contrast(1.1);
    padding-bottom: 20%;
}
`

const Section2 = styled.div`
    alignItems: center; 
    verticalAlign: middle; 
    justifyContent: center; 
    height: inherit;
    margin-top:0px;
    &:before{

        height: 100%;
        width: 100vw;
        content:"";
        background-image: url(${getArt('Bg2')}); 
        background-size: cover; 
        background-repeat: no-repeat;
        background-position: center center;
        z-index: -1;
        position: absolute;
        margin-top: -15%;

        filter: brightness(.75) contrast(1.1);
        padding-bottom: 10%;
    }
`

const StyledHome = styled.div`
        width: 100vw;
        content: "";
        background-image: url('how-bg-webp.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: -1;
        justify-content: center;
        align-items:center;
        animation-name: ${nebulaGlow};
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        will-change: auto;
        
    
`

  const InfoCard = styled.div`

    width: 320px;
    color: white;
    text-align: left;
    font-size: 14pt;
    justify-content: center;
    align-items: center;
    height: 20vh;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    will-change: auto;
    filter: drop-shadow(0 0 5px black);
    filter: brightness(.7);
    transform: scale(1);
    transition: all .5s ease;
    &::before{
        border-radius: 16px;
        width: 320px;
        height: 20vh;
        content: "";
        background-image: ${props => props.imageUrl? `url(${props.imageUrl})`:'none'};
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: -1;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
      
    }
    &:hover{
        filter: brightness(1.1);
        transform: scale(1.1);
    }

  `
const getCardScale = (width) => {
    
    if(width > 1920){
        return 1;
    }
    else if(width > 1200){
        return 1;
    }
    else if(width > 800){
        return 1;
    }
    else if(width > 600){
        return 1;
    }
    else if(width > 300){
        return 0.8;
    }
    else{
        return 1;
    }
}
  

  const image1 = getArt('HowHomeBg')
  const battleToWin = getArt('BattleToWin')
  const image3 =
    "https://brightcove04pmdo-a.akamaihd.net/5104226627001/5104226627001_5297440765001_5280261645001-vs.jpg?pubId=5104226627001&videoId=5280261645001";
  const image4 =
    "https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/empire-state-building-black-and-white-square-format-john-farnan.jpg";
  


const ParaBanner = () => {

    const background = {
        children: (

            <StyledHome/>
        )
        ,
        translateY: ['0vh', '0vh', [.56,-0.01,0,2]],
        opacity: [.6, 0, [.56,0,0,1.4]],
       
        scale: [1, 2, [.56,0,0,.55]],
        shouldAlwaysCompleteAnimation: true
      };
    
    const headline = {
        translateY: ['-20vh', '-20vh', [.56,-0.01,0,2]],
        opacity: [1, 0, [.56,-0.01,0,1.3]],
        
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
        <div className="inset center">
            <Headline fontSize='8vw'>Hounds of War</Headline>
        </div>
        )
    };
    const subtitle = {
        translateY: ['2vh', '-20vh', [.56,-0.01,0,2]],
        opacity: [1, 0, [.56,-0.01,0,1.3]],
        
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
        <div className="inset center">
            <Headline fontSize='4vw'>Play to Earn</Headline>
        </div>
        )
    };

 
    const starsy = {
        translateY: ['0vh', '0vh', [.56,-0.01,0,2]],
        opacity: [1, 0, [.56,-0.01,0,1.2]],
        scale: [1, 5, [.7,-0.01,0,1.4]],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
        <div className="inset center">
            <StarField/>
        </div>
        )
    };
        
    return (
        <ParallaxBanner
        layers={[ background, starsy, headline, subtitle]}
        style={{width:'100vw', height:'140vh'}}
        />
    );
};




const HowHomePage = () => {
    const {width, height} = useWindowDimensions()

    return(
        <div style={{width:'100vw'}}>
            <ParaBanner/>
 

            <div style={{height:'300px', width:'100%'}}  />
            <Section1>
            <Grid container  style={{paddingTop:'10%'}} xs={12} sm={12} md={12} lg={12} xl={12} alignItems='center' alignContent='center' justifyContent='center'>
                <Grid
                    alignItems='center' justifyContent='center'         
                    style={{width:'320px', margin:'auto'}}
                >
                     <Headline > Build Squads</Headline>
                    <InfoCard imageUrl='build-decks.webp'/>
             
                    <p style={{  textAlign:'justify',  flexDirection:'row', fontSize:'14pt', color:'white'}}>
                        Build powerful squads to defeat your enemies
                    </p>
                </Grid>
          
                <Grid
                    alignItems='center' justifyContent='center'         
                    style={{width:'320px', margin:'auto'}}
                >
                    <Headline> Play to Earn</Headline>
                    <InfoCard imageUrl='win-to-earn.webp' />
    
                    <p style={{  textAlign:'justify',  flexDirection:'row', color:'white', fontSize:'14pt'}}>
                            Play matches using HoW tokens to win more How.
                    </p>
                </Grid>
        
                <Grid 
                    alignItems='center' justifyContent='center'                       
                    style={{width:'320px', margin:'auto'}}
                >
                     <Headline> Compete</Headline>
                    <InfoCard imageUrl='compete.webp'/>
              
                    <p style={{  textAlign:'justify',  flexDirection:'row', color:'white', fontSize:'14pt'}}>
                            Compete in regular tournaments for big HoW winnings.
                            Earn exclusive tournament NFTs
                    </p>
                </Grid>
 
                <div style={{height:'300px', width:'100%'}}  />
            </Grid>
             
            </Section1>

            <Section2>
                <Headline> Timeline</Headline>
                <Grid centered justifyContent='center' alignItems='center' style={{width:'100%', margin: 'auto', padding:'auto', marginTop:'10%'}}>

                    <Timeline/>
                </Grid>
            </Section2>
  
            <Grid centered justifyContent='center' alignItems='center' style={{width:'100%', margin: 'auto', padding:'auto', marginTop:'20%'}}>
                <SocialMediaFooter/>
            </Grid>
            
        </div>
    )     
}
export default HowHomePage;
