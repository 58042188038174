import MetamaskIcon from './icons/MetamaskIcon';
import MathWalletIcon from './icons/MathWalletIcon';
import TokenPocketIcon from './icons/TokenPocketIcon';
import TrustWalletIcon from './icons/TrustWalletIcon';
import WalletConnectIcon from './icons/WalletConnectIcon';
import SafePalWalletIcon from './icons/SafePalWalletIcon';

const WalletConfig = [{
        title: 'Metamask',
        icon: <MetamaskIcon/>,
        connectorType: 0,
    },
    {
        title: 'TrustWallet',
        icon: <TrustWalletIcon/>,
        connectorType: 0,
    },
    {
        title: 'MathWallet',
        icon: <MathWalletIcon/>,
        connectorType: 0,
    },
    {
        title: 'TokenPocket',
        icon: <TokenPocketIcon/>,
        connectorType: 0,
    },
    {
        title: 'SafePal Wallet',
        icon: <SafePalWalletIcon/>,
        connectorType: 0,
    },
    // {
    //     title: 'WalletConnect',
    //     icon: <WalletConnectIcon/>,
    //     connectorType: 1,
    // }
]

export default WalletConfig