import React, {useEffect, useState, useReducer} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Drawer, AppBar, Toolbar, List, IconButton, ListItem, ListItemIcon, ListItemText, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import ArtLoader from "../assets/CardArtProvider"
import { Menu, ChevronLeft, ChevronRight, Book, LibraryAdd, Receipt, Collections, LibraryBooks, Store, Home, SportsEsports, AccountCircle, SupervisorAccount } from "@material-ui/icons";
import { useStore } from "../helpers/GlobalStore";
import useWallet from "../helpers/useWallet";
import useIsWindowFocused from '../helpers/tabActivityHook'
import { useWindowDimensions } from "../helpers/windowDimentionProvider";
import { Headline } from "./Headline";
import { useAuthReducer } from "../helpers/AuthReducer";

const drawerWidth = 240;

const { getArt } = ArtLoader();



const ConnectButton = styled(Button)`

    color: black;
    font-family: "Courier New"!important;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    background: linear-gradient(#d05000 20%, #ffff00 100%);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    opacity: .75;
    text-align: center;
    margin: auto;
    width: 80px;
    transition: all linear .2s;
    
    height: 50px;
    &:hover{
        transform: scale(1.1);
        border: 1px solid white;
        opacity: 1;
        background: linear-gradient(#d05000 0%, #ffff00 90%, #ffffff 100%);
        border-left: none;
        border-right: none;
    }
    &:disabled{
        filter: grayscale(1);
    }
`

const StyledNavLink = styled(ListItem)`

    text-decoration: none;
    cursor: pointer;


    &:hover{
        font-weight: 900;
        opacity: 1;
        background: linear-gradient(rgba(208, 80, 0, .5) 0%, rgba(255, 255, 0, .6) 90%, rgba(255, 255, 255, .7) 100%);
    }
    &.active{ 
        background-color: #202020!important;
        color: red;
        -webkit-text-fill-color: transparent;
        background: -webkit-linear-gradient(#ff0000 0%, #eeee00 100%);
        -webkit-background-clip: text;

        &:hover{
            cursor: default;
        }
    }
`

const NavBar = () => {
    
    const styles = useStyles();
    const history = useHistory();
    const { width, height, scrollX, scrollY } = useWindowDimensions();
    const { userRoles,
        } = useStore();

    const windowIsActive = useIsWindowFocused();
    const {authState} = useAuthReducer();
	 	 
    const theme = useTheme();

    const { login, getCurrentChainId, logout, enableWalletListeners, disableWalletListeners } = useWallet();
    const [reloadPageRequested, setReloadPageRequested] = useState(false);

    const disconnectText = 'Sign Out?';

    useEffect(()=>{
        if(authState && authState.userAuthorized === false){
            if(  ( history.location.pathname === '/squads' ) || ( history.location.pathname === '/profile' ) ){
                history.push("/")
            }
        }
    },[authState])
    

    const [navbarState, cbtDispatch] = useReducer( navbarReducer, 
        {
            connectButtonText: 'Connect',
            open: false,
            appbarHovered: false
        }
    );
   
    function navbarReducer(navbarState, action) {
      
        switch (action.type) {
            case 'clearNavState': {
                return {
                    ...navbarState,
                    connectButtonText: 'Connect',
                    open: false,
                    appbarHovered: false
                };
            }
            case 'updateButtonText': {
                return {
                    ...navbarState,
                    connectButtonText: action.payload.displayText,
                }
            }
            case 'openNav': {
                return {
                    ...navbarState,
                    open: true
                        
                }
            }
            case 'closeNav': {
                return {
                    ...navbarState,
                    open: false
                }
            }
            case 'navbarHover': {
                return {
                    ...navbarState,
                    appbarHovered: true
                        
                }
            }
            case 'navbarUnhover': {
                return {
                    ...navbarState,
                    appbarHovered: false
                }
            }
            
        }
    }

    const handleDrawerOpen = () => {
      cbtDispatch({type: 'openNav'})
    };
  
    const handleDrawerClose = () => {
        cbtDispatch({type: 'closeNav'})
    };

    useEffect(()=>{
        if(reloadPageRequested === true){
            setReloadPageRequested(false)
            
            window.location.reload() 
        }
    },[reloadPageRequested])

    function truncateUsername(){

        const truncatedUsername = authState.userName && authState.userName.length < 14? authState.userName: authState.userName.substring(0,10).concat('...')
        return(truncatedUsername)
        
    }

    useEffect(()=>{
        if(authState && authState.userAddress && authState.truncatedAddress){
            cbtDispatch({ 
                type: 'updateButtonText', 
                payload: {
                    displayText: authState.userName? `${truncateUsername()} ${authState.truncatedAddress}`: authState.truncatedAddress 
                }})
        }
            
    },[authState])

    useEffect(()=>{


    },[scrollY])

    const getNavOpacity = () =>{
        let opacity = 1;
        if(scrollY === 0){
            opacity = 1;
        }
        else if( scrollY > 0 && navbarState && navbarState.appbarHovered){
            opacity = 1;
        }
        else{
            opacity = 0.2;
        }
        return opacity;
    }

    const handleNavClick = (page) => {

        if(page === "home")
            history.push("/")

        else if(page === "hounds")
            history.push("/hounds")

        else if(page === "squads"){
            history.push("/squads")
        }
        // else if(page === "store")
        //     history.push("/store")

        else if(page === "game")
            history.push("/game")

        else if(page === "admin")
            history.push("/admin")

        else if(page === "profile")
            history.push("/profile")

        else if(page === "howto")
            history.push("/howto")

        else history.push("/")

        cbtDispatch({type:'closeNav'})
    }

    return (
        <AppBar
        position = "fixed"
        className={styles.appBar}
        elevation={0}
        id="Main Appbar"
        onMouseOver={(e)=>navbarState && navbarState.appbarHovered? {}: cbtDispatch({ type: 'navbarHover' })}
        onMouseOut={(e)=>navbarState && navbarState.appbarHovered? cbtDispatch({ type: 'navbarUnhover' }): {}}
        style={{opacity: getNavOpacity(), transition:'all 1s ease'}}
        >
            <Toolbar id="Toolbar">
                <Grid container alignItems = "center" className = {styles.controlsContainer} id="Toolbar Container">
                    <Grid item xs = {2}>

                        <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={`${styles.menuButton} ${navbarState && navbarState.open && styles.hide}`}
                        >
                            <Menu />
                        </IconButton>
                    </Grid>

                    <Grid item  xs={8} style={{justifyContent:'center', alignItems:'center', margin:'auto', padding:'auto'}}>

                            <h1 className='smallHeadline'> 
                                Hounds of War
                            </h1 >

                    </Grid>

                    <Grid item  xs={2} style={{justifyContent:'center', alignItems:'center', margin:'auto'}}>
                        <ConnectButton  
                            onClick = {window.location.pathname === '/game'? ()=>{} :() => handleNavClick("game")}
                            disabled={window.location.pathname === '/game'? true: false}> 
                                Play
                        </ConnectButton>
                    </Grid>
                    

                </Grid>

            </Toolbar>
            
            <Drawer
            className={styles.drawer}
            variant="temporary"
            anchor="left"
            open={navbarState? navbarState.open: false}
            onClose={() => cbtDispatch({type:'closeNav'})}
            classes={{
            paper: styles.drawerPaper,
            }}
            >
                <div className={styles.drawerHeader}>
                    <IconButton 
                    onClick={handleDrawerClose} 
                    className = {styles.colorWhite}
                    >
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>

                <List>
                    <StyledNavLink
                        onClick = {window.location.pathname === '/'? ()=>{} :() => handleNavClick("")}
                        className={window.location.pathname === '/'? 'active': ''}
                    >

                        <ListItemIcon >
                            <Home style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Home"/>

                    </StyledNavLink>

                    {/* <StyledNavLink
                        onClick = {window.location.pathname === '/store'? ()=>{} :() => handleNavClick("store")}
                        className={window.location.pathname === '/store'? 'active': ''}
                    >
                        <ListItemIcon>
                            <Store style = {{fill: "white"}}/>
                        </ListItemIcon>

                        <ListItemText primary = "Store"/>
                        
                    </StyledNavLink> */}

                    <StyledNavLink
                        onClick = {window.location.pathname === '/hounds'? ()=>{} :() => handleNavClick("hounds")}
                        className={window.location.pathname === '/hounds'? 'active': ''}
                    >
                        <ListItemIcon>
                            <Collections style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "My Hounds"/>
                        
                    </StyledNavLink>

                    {(userRoles !== undefined && userRoles !== null && userRoles !== "") &&
                    <StyledNavLink
                        onClick = {window.location.pathname === '/squads'? ()=>{} :() => handleNavClick("squads")}
                        className={window.location.pathname === '/squads'? 'active': ''}
                    >
                        <ListItemIcon>
                            <LibraryAdd style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "My Squads"/>
                        
                    </StyledNavLink>
                    }

                    <StyledNavLink
                        onClick = {window.location.pathname === '/game'? ()=>{} :() => handleNavClick("game")}
                        className={window.location.pathname === '/game'? 'active': ''}
                    >
                        <ListItemIcon>
                            <SportsEsports style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Game"/>
                        
                    </StyledNavLink>
                    <StyledNavLink
                        onClick = {window.location.pathname === '/howto'? ()=>{} :() => handleNavClick("howto")}
                        className={window.location.pathname === '/howto'? 'active': ''}
                    >
                        <ListItemIcon>
                            <LibraryBooks style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "HoW to Play"/>
                        
                    </StyledNavLink>

                    {userRoles !== undefined && userRoles !== '' &&
                    <StyledNavLink
                        onClick = {window.location.pathname === '/profile'? ()=>{} :() => handleNavClick("profile")}
                        className={window.location.pathname === '/profile'? 'active': ''}
                    >
                        <ListItemIcon>
                            <AccountCircle style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Profile"/>
                        
                    </StyledNavLink>
                    }

                </List>

            </Drawer>
        </AppBar>
    )
}

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            backgroundColor: "#000",
            height: 65,
            filter: 'drop-shadow(0 .4rem .5rem rgba(200, 125, 0, .5) )'
        },
        
        
        menuButton: {
            marginRight: theme.spacing(2)
        },

        hide: {
            display: 'none',
        },

        drawer: {
            width: drawerWidth,
            flexShrink: 0
        },

        drawerPaper: {
            width: drawerWidth,
            backgroundColor: "#000",
            color: "white"
        },

        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },



        controlsContainer: {
            maxWidth: "100vw"
        },

        creditsIcon: {
            height: 40,
            width: 40,
            borderRadius: 50
        },

        fillWhite: {
            fill: "white"
        },

        colorWhite: {
            color: "white"
        }
    }),
);


export default NavBar;
