//This component combines duplicate behavior from the Store and Inventory Packs (whats inside feature) page into one shared component.
//Returns a grid container housing all the elements that should be displayed in the popover.

import React, { useState, useEffect, useReducer } from "react";
import { makeStyles, Grid, Typography, Zoom, CircularProgress, Dialog, Popover, Backdrop, Card, Divider, Button, TextField, Modal } from '@material-ui/core';
import { useContractReducer } from "../../helpers/ContractReducer";
import styled, {keyframes} from 'styled-components'
import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import { useStore } from "../../helpers/GlobalStore";
import CONFIG from "../../config/config";
import { useHowApiClient } from "../../helpers/HowApiClient";
import { Headline } from "../Headline";


const ClaimButton = styled(Button)`

    color: black;
    background: linear-gradient(#d05000, #ffff00);
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    opacity: .75;
    text-align: center;
    margin: auto;
    margin-bottom: 10px;
    
    transition: all linear .2s;

    height: 60px;
    &:hover{
        transform: scale(1.1);
        opacity: 1;
    }
    &:disabled{
        filter: grayscale(1);
    }

`

const MAX_ALLOWED_MINTS_PER_BLOCK = 50;

const claimWinningsModalRef = React.createRef()

const isNullUndefinedOrEmpty = (obj) => {
    return( obj === undefined || obj === null || obj === ''  )
} 



const getFloatPrecision = (value) => {

    const valueStr = value.toString().split(".")
    let precision = 0;

    if(valueStr.length === 1){
        precision = 1
    }
    else{
        precision = valueStr[1].length
    }

    return precision
}

const transactionStates ={
    'waitingForPurchaseRequest': 0,
    'interactingWithBlockchain': 1,
    'mintingStarted': 2,
    'mintingComplete': 3,
    'initiatedClaimAsHow': 4,
    'initiatedClaimAsChow': 5,
    'sendingRequestToApi': 6,
    'transactionComplete': 8,
    'transactionFailed': 10, 
}

const ClaimWinningsPopover = Demodal.create(({dialogueText}) => {
    const styles = useStyles();
    const claimWinningsModal = useModal()
    const {winningsManager} = useContractReducer()
    const {currentChainId,
        howOwned, setHowOwned,
        chowOwned, setChowOwned,
        earnedChow, setEarnedChow, 
    } = useStore()

    const {mockConvertWinningsToHow, mockUpdateUserWinnings} = useHowApiClient()
    
    useEffect(() => {
        if(isNullUndefinedOrEmpty(winningsManager) || isNullUndefinedOrEmpty(winningsManager.winningsManager)){
           claimWinningsModal.close()
        }
        else{

        }
    },[winningsManager])



    const resolve = value => () => {
      claimWinningsModal.resolve(value)
      claimWinningsModal.close()
    }

    const [claimPopoverState, dispatchClaimPopoverUpdate] = 
        useReducer(
            winningsReducer, {
                isProcessing: false,
                showDrops: false,
                purchaseSuccess: false,
                transactionMessage: undefined,
                canExitModal: true,
                currentTransactionPhase: 'waitingForPurchaseRequest',
                claimableWinnings: earnedChow,
                amountToClaim: 0,
            }
    );

   
    function winningsReducer(claimPopoverState, action) {
        switch (action.type) {
            case 'changeClaimQuantity': {
                //  dispatch({type:'clearField'})
                  return {
                        ...claimPopoverState,
                        amountToClaim: action.payload
                  };
              }
            case 'transactionInitiated': {
              //  dispatch({type:'clearField'})
                return {
                    ...claimPopoverState,
                    isProcessing: true,
                    canExitModal: false,
                    transactionMessage: `Confirming transaction  on ${CONFIG.SUPPORTED_CHAINS.get(currentChainId+'').name}`,
                    currentTransactionPhase: 'interactingWithBlockchain'
                };
            }
            case 'claimAsHowInitiated': {
                return {
                    ...claimPopoverState,
                    isProcessing: true,
                    canExitModal: false,
                    transactionMessage: `Confirming transaction  on ${CONFIG.SUPPORTED_CHAINS.get(currentChainId+'').name}`,
                    currentTransactionPhase: 'interactingWithBlockchain'
                };
            }
            case 'claimAsChowInitiated': {
                return {
                    ...claimPopoverState,
                    isProcessing: true,
                    canExitModal: false,
                    transactionMessage: `Claiming tokens as cHow`,
                    currentTransactionPhase: 'sendingRequestToApi'
                };
            }
            case 'transactionComplete': {
                console.log(action)
                return {
                    ...claimPopoverState,
                    isProcessing: false,
                    canExitModal: true,
                    purchaseSuccess: true,
                    currentTransactionPhase: 'transactionComplete',
                }
            }
            case 'transactionFailed': {
                return {
                    ...claimPopoverState,
                    isProcessing: false,
                    canExitModal: true,
                    transactionMessage: action.payload.reason,
                    currentTransactionPhase: 'transactionFailed',
                }
            }

            case 'clearState': {
                return {
                    ...claimPopoverState,
                    canExitModal: true,
                    transactionMessage: '',
                    purchaseSuccess: false,
                    isProcessing: false,
                    currentTransactionPhase: 'waitingForPurchaseRequest',
                }
            }
        }
    }
    
    

    const disableClaimButton = () => {
        if(isNaN(claimPopoverState.claimableWinnings) || (Number(claimPopoverState.claimableWinnings) < 1)){
            return true;
        }

        if ( (parseInt(claimPopoverState.amountToClaim) <= 0 ) ){
            return true;
        }

        if ( (claimPopoverState.amountToClaim > claimPopoverState.claimableWinnings) || (claimPopoverState.amountToClaim == '') ){
            return true;
        }
        
        return false;
    }


  

    const handleClaimClick = async ({claimAs}) => {

        console.log(`handClaim called: ${claimAs}`)
        try{
           
            //User requested to convert to $HoW
            if( claimAs && claimAs === "how" ){
                const Web3 = require('web3');
                let web3 = new Web3(CONFIG.SUPPORTED_CHAINS.get(currentChainId+"").node);
               
                const precision = getFloatPrecision(claimPopoverState.amountToClaim)                
                const floatClaimQuantity = parseFloat(claimPopoverState.amountToClaim).toFixed(precision + 2)
               
                let claimAmountAsWei = web3.utils.toWei(floatClaimQuantity);
                let convertWinningsRequest;
                //convertWinningsRequest = await winningsManager.winningsManager.purchaseCardPack({value: claimAmountAsWei})  
                convertWinningsRequest = await  mockConvertWinningsToHow({value: claimAmountAsWei})
                console.log(convertWinningsRequest)
                console.log('step 4')
                dispatchClaimPopoverUpdate({type:'converToHowInitiated', payload:{reason:'In Progress'}})
                var someVal = await convertWinningsRequest.resolve()
                console.log(someVal)
                
                dispatchClaimPopoverUpdate({type:'winningsConversionStarted'})
                console.log('convertResult')
                if(convertWinningsRequest.status === 1){
                    console.log('status came back 1')
                    const transferResult = convertWinningsRequest.events.find((evt) => evt.event === 'tokenTransfer');
                    const transferAmount = transferResult.args['transferAmount'];
                    console.log(transferAmount)
                    
                    dispatchClaimPopoverUpdate({type:'howClaimedOnChain'})
                    const claimWinningsRequest = {
                        amountClaimed: web3.utils.fromWei(transferAmount, 'ether'),
                        claimedAs: 'how'
                    }
                    dispatchClaimPopoverUpdate({type:'updatingUserData'})
                    var response = await mockUpdateUserWinnings({ claimWinningsRequest: claimWinningsRequest })
                    console.log(response)
                    if( response.status && (response.status === 200 || response.status === 204 )){
                        //Success case
                        dispatchClaimPopoverUpdate({ type:'transactionComplete', payload: response.data.amountClaimed })
                        setEarnedChow( earnedChow - response.data.amountClaimed )
                        setHowOwned( response.data.amountClaimed )
                    }
                    else {
                        //Failure case
                        dispatchClaimPopoverUpdate({type:'transactionFailed', payload: {reason: 'Something failed in our server. Please go to the profile page and sync your wallet.'}})   
                    //    settransactionMessage("Purchase Failed!  Something went wrong in our server.  Please try again.")
                    //    setPurchaseSuccess(false)
                    }

    
                }
                else{
               //     settransactionMessage("Purchase Failed!")
               //     setCanExitModal(true)
               //     setPurchaseSuccess(false)
                    dispatchClaimPopoverUpdate({type:'transactionFailed', payload: {reason: 'Transaction failed within the blockchain.'}})
                            
                    console.log("Transaction failed within the blockchain.")
                }
            }
            
            //User requested to claim as cHoW
            else {
                // dispatchClaimPopoverUpdate({type:'transactionInitiated', payload:{reason:'In Progress'}})

                const claimWinningsRequest = {
                    amountClaimed: parseInt(claimPopoverState.amountToClaim),
                    claimedAs: 'chow'
                }

                //dispatchClaimPopoverUpdate({type:'sendingRequestToApi'})


                const res = Promise.resolve(mockUpdateUserWinnings({ claimWinningsRequest: claimWinningsRequest, callbackFn: dispatchClaimPopoverUpdate  }))

                // if(!isNullUndefinedOrEmpty( response )){
                //     console.log('welp')
                //     dispatchClaimPopoverUpdate({type:'transactionComplete', payload: response.data })
                // }
                // //Generation Failed.  Nothing given back.
                // else {
                //     dispatchClaimPopoverUpdate({type:'transactionFailed', payload: {reason: 'Something failed in our server.  Please go to the profile page and sync your wallet.'}})
                // }
            }
        }
        catch(err){
            let message;
            if (err && err.error && err.error.message){
                message = err.error.message
            } else if (err && err.message){
                message = err.message
            } else if (err && err.data && err.data.message) {
                message = err.data.message
            } else {
                message = "An unknowable error occured"
            }
    
            message = message.replace("execution reverted: ", "")
    

            dispatchClaimPopoverUpdate({type:'clearState', payload: {reason:""}})
        }
    }
    
    
    const showLootRewards = () => {

        return(
            <Grid container style={{justifyContent:'center', alignItems:'center', textAlign:'center'}}>
                Congrats You Got Stuff
            </Grid>
        )
    }
    

    const claimWinningsScreen = () =>{

        return(
            <Grid item style={{ height: 'auto', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Grid container spacing = {4} style={{justifyContent:'center', alignItems:'center'}}>

                    <Grid item xs={12} style={{color:'white', fontWeight:'bold', fontSize:'5vh', marginBottom:'10px', textAlign:'center'}}> 
                        <Headline fontSize='40px'>
                            {dialogueText} 
                        </Headline> 
                    </Grid>

                    <Grid item xs={12} style={{ height: 'auto', display:'flex', justifyContent:'center', alignItems:'center', fontWeight: 'bold'}}>
                  
                            Earned HoW: {earnedChow}
                     
                    </Grid>

                    <Grid item style={{ height: 'auto', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <TextField 
                            id="standard-textarea"
                            label = "Claim Amount"
                            variant="outlined"
                            type="number"
                            className = {styles.numFieldProp}
                                InputProps = {{className: styles.numFieldProp}}
                                InputLabelProps = {{className: styles.numFieldLabel}}
                            value = {claimPopoverState.amountToClaim}
                            error={  ( claimPopoverState.amountToClaim > earnedChow ) || ( claimPopoverState.amountToClaim < 0 )}
                            helperText = {( parseInt(claimPopoverState.amountToClaim) > parseInt(earnedChow)) ? 
                                            "You cant claim more than you have won!" : 
                                            parseInt(claimPopoverState.amountToClaim) < 0? "Only quantities greater than 0 can be claimed!":""
                            }
                            onChange = {(e) => dispatchClaimPopoverUpdate({type: 'changeClaimQuantity', payload: e.target.value})}
                        />
                    </Grid>
                    <Grid 
                        container
                        style={{
                            
                            color: 'white', 
                            justifyContent:'center', 
                            alignItems:'center', 
                            width:'100%',
                            padding:'30px',
                            margin: 'auto'
                        }}
                        classes = {{paper: "" }}
                        spacing={4}
                    >

                            <ClaimButton
                                disabled={disableClaimButton()}
                                onClick={()=>handleClaimClick({ claimAs: 'chow'})}
                            >
                                Claim as cHoW
                            </ClaimButton>
                    


                            <ClaimButton
                                disabled={disableClaimButton()}
                                onClick={()=>handleClaimClick({ claimAs: 'how'})}
                            >
                                Claim As $HoW
                            </ClaimButton>

                        {/* <Grid item xs={12}>
                            <p> Note: Claiming as $HoW has a low Sbiba layer 2 gas fee.</p>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    

    const handleModalClose = () => {
        dispatchClaimPopoverUpdate({type:'clearState'})
        claimWinningsModal.close()
    }
   

    return (
        <Backdrop onClose={claimPopoverState && claimPopoverState.canExitModal?()=>handleModalClose():()=>{}} {...muiDialog(claimWinningsModal)} style={{backgroundColor:'rgba(0,0,0,.8)', zIndex:'700'}}>
      
            <Popover 
                {...muiDialog(claimWinningsModal)}
                ref={claimWinningsModalRef}
                onClose={claimPopoverState && claimPopoverState.canExitModal?()=>handleModalClose():()=>{}}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                        },
                }}

                style={{
                    width:'auto', 
                    //TODO
                    justifyContent:'center', 
                    alignItems:'center', 
                    color: 'white', 
                    alignItems:'center', 
                    margin:'auto',

                }}
                anchorReference="anchorPosition"
                anchorPosition={{top:window.innerHeight/2, left:window.innerWidth/2}}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >

                <Grid 
                    container 
                    style={{
                        background: 'rgba(0,0,0,0.95)', 
                        color: 'white', 
                        alignItems:'center', 
                   
                        borderRadius:'20px',
                        margin:'auto',
                        padding:'20px',
                        height:'60vh',
                        width:window.innerWidth<1100?'75vw':'auto',
                        justifyContent:'center',
                        alignItems:'center',
                        overflowY:'hidden',
                        overflowX:'hidden'
                    }}
                >            
                     {claimPopoverState && transactionStates[claimPopoverState.currentTransactionPhase] === transactionStates['waitingForPurchaseRequest'] &&
                        claimWinningsScreen()
                     }
                     {claimPopoverState && transactionStates[claimPopoverState.currentTransactionPhase] > transactionStates['waitingForPurchaseRequest'] && transactionStates[claimPopoverState.currentTransactionPhase] < transactionStates['transactionComplete'] &&
                        <Grid container style={{fontSize:'24pt', justifyContent: 'center', alignItems: 'center' }}>
                            {claimPopoverState.transactionMessage}
                        </Grid>
                     }
                     {claimPopoverState && transactionStates[claimPopoverState.currentTransactionPhase] === transactionStates['transactionFailed'] &&
                        <Grid container style={{fontSize:'24pt', justifyContent: 'center', alignItems: 'center' }}>
                            {claimPopoverState.transactionMessage}
                        </Grid>
                     }
                     {claimPopoverState && transactionStates[claimPopoverState.currentTransactionPhase] === transactionStates['transactionComplete'] &&
                        <Grid container style={{fontSize:'24pt', justifyContent: 'center', alignItems: 'center' }}>
                            Finished claiming Chow!
                        </Grid>
                     }
                </Grid>
            </Popover>
        </Backdrop>
    )
    



  })


  const useStyles = makeStyles((theme) => ({


    rewardsContainer: {
        overflowX: "hidden",
        overflowY: "hidden",
        height:'auto',
        width: 'auto',
        padding:'25px',
        background: '#080c0d',
        boxShadow: "none",
        textAlign:"center",
        justifyContent: "center",
        color:'white'
    },

    popContainer: {
        overflowX: "hidden",
        overflowY: "hidden",
        maxWidth: 800,
        width: 'auto',
        height: "auto",
        padding:'10px',
        margin:'0px',
        backgroundColor:'#181c24',
        color:'white',
    },

    slotsContainer: {
        display: "flex",
        overflowY: "hidden",
        overflowX: "hidden",
        width: 'auto',
        height: "auto",
        padding:'10px',
        alignItems: "center",
        justifyContent: "center"
    },

    loaderContainer: {
        pointerEvents: "none",
        backgroundColor: "#080c0d",
        width: "auto",
        height: "auto",
        margin: 20
    },
    
    rewardItem: {
        width: 250,
        height: "auto",
        margin: 20
    },

    numFieldProp: {
        height: '36px',
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    numFieldLabel:{
        color: "white"
    },

}))



export default ClaimWinningsPopover