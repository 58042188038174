import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import './App.css';
import { Grid } from '@material-ui/core';
import React, {useEffect, useState} from "react";
import NavBar from './components/NavBar.js';
import InventoryPage from './pages/InventoryPage.js'
import HowHomePage from './pages/HomePageRedesign';
import GamePage from './pages/GamePage.js'
import StorePage from './pages/StorePage.js'
import DecksPage from './pages/DecksPage.js'
import ProfilePage from './pages/ProfilePage';
import GuidePage from './pages/GuidePage'
import CreditsPage from './pages/CreditsPage';
import { ComingSoon } from './pages/ComingSoon';
import { useStore } from './helpers/GlobalStore';
import { useHowApiClient } from './helpers/HowApiClient';
import { Demodal } from 'demodal'


const App = () => {

  const {downForMaintenanceAsync} = useHowApiClient()

  const {isGameLoaded, gameLoading} = useStore()
  const [ isDownForMaintenence, setIsDownForMaintenence ] = useState(true)

useEffect(() => {
  getDownForMaintenanceAsync()
}, []);


async function getDownForMaintenanceAsync() {
  const isDownForMaintenance = await downForMaintenanceAsync();
  setIsDownForMaintenence(isDownForMaintenance);
}

  return (
    <Grid id="AppMainGrid" container style={{alignItems:'center', justifyContent:'center', overflowX:'clip'}}>

      <Router>
        
        <>
          {isGameLoaded === false && gameLoading === false &&
            <Grid item id="AppNavGrid" style={{ height: 65 }}>
              <NavBar/>
            </Grid>
          }
        </>
        <Demodal.Container />
        <Grid item id="AppRouteSwitch" style={{ width:'100vw' }}>
          <Switch>
            <Route path="/hounds" component={InventoryPage}/>
            <Route path="/squads" component={isDownForMaintenence?ComingSoon:DecksPage} />
            <Route path="/game" component={GamePage} />
            {/* <Route path="/store" component={ComingSoon} /> */}
            <Route path="/profile" component={ProfilePage} />
            <Route path="/howto" component={ComingSoon} />
            <Route exact path="/" component={HowHomePage} />
          </Switch>
        </Grid>
      </Router>
    </Grid>
  )
}

export default App;
