import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core';
import BackgroundManager from '../components/BackgroundImageManager'
import ArtLoader from "../assets/CardArtProvider"
import { useWindowDimensions } from '../helpers/windowDimentionProvider';

const GuidePage = () => {

    const styles = useStyles()
    const { getArt } = ArtLoader();
    const { width } = useWindowDimensions();

    return (
        <div style={{width:'100vw', marginBottom:'100px'}}>
            <BackgroundManager sourceImage={getArt('GuidePageBG')}  blur='4px' opacity='0.4' />

            <Grid justifyContent='center' container style={{maxWidth:'70vw', padding:'auto', margin:'auto', textAlign:'center', paddingTop:'100px'}}>
                <Grid item xs = {12} style={{marginBottom:'15px'}}>
                    <Typography variant = "h4" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        HoW To Play
                    </Typography>
                </Grid>

                <Grid  item xs={12} sm={12} md={12} lg={12} style={{
                                    backgroundColor:'rgba(20,20,20,0.5)',display:'flex', flexDirection:'column', height:'100%' }}>
                    <img src={width >= 600? 'gameboardDescriptions.png':'gameboard-description-mobile-left.png'} alt='Gameboard Layout'/>
                    {width < 600? 
                      <img src={'gameboard-description-mobile-right.png'} alt='Gameboard Layout'/>
                    :
                    <></>
                    }
                </Grid>
                
                <Grid container spacing={3} alignItems="center" style={{
                                maxWidth:'1600px', marginLeft:'auto', marginRight:'auto', textAlign:'center', justifyContent: "center"}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto', marginTop:'30px' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        1. Turn Timer: Shows the time remaining for the current turn
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                       
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        2. Enemy Username: The opponent's username
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        3. Enemy Health: The enemy's remaining health
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        4. Enemy Card Focus Area: Highlights the last enemy Hound/item used 
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        5. Enemy Lanes: The enemy player's lanes
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        6. Summon Button: Summon a Hound to occupy user selected lane
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        7. Discard Button: Discard the card highlighted in the focus area, sending it to the bottom of your deck 
                        (Only cards in your hand can be  discarded) 
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        8. End Turn Button: Ends your current turn
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        9. Forfeit Button: Forfeit the game, surrendering the win to your enemy
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        10. Player Card Focus Area: Highlights the player's selected card
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        11. Reshuffle Button: Reshuffle entire hand and draw 5 new cards 
                        (Action can only be performed on player's first turn)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        12. Player Hand: The cards drawn for the player 
                        (The hand will replenish at the start of your turn until you run out of cards)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        13. Player Energy Pool: The ammount of energy accumulated 
                        (An increasing ammount of energy is recovered at the top of each turn)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        14. Player Health: The player's remaining health 
                        (It is the player's loss when there is no health remaining)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} 
                    style={{
                        backgroundColor:'rgba(20,20,20,0.5)',marginTop:'5px', display:'flex', flexDirection:'column', height:'auto' }}>
                        <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textLeft}`}>
                        15. Player Deck: The player's deck
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({

    textWhite: {
        color: "white",
        width: "auto"
    },

    textCentered: {
        textAlign: "center"
    },
    textLeft: {
        textAlign: "left"
    },

}));

export default GuidePage;
